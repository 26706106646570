//import Cep from "cep-promise";
import React from 'react';
import Email from '../Email';

export default function ValConsensoParcial({ resp = {}, notify = false, print = false, viewHeader = false }) {

    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
            {viewHeader && <div className="w-100 mb-20">
                <p className="mb-0"><b>De:</b> {notify?.disparo}</p>
                <p className="mb-0"><b>Para:</b> {notify?.canalOPS}</p>
                <p className="mb-0"><b>Assunto:</b> VALIDAÇÃO DE CONSENSO PARCIAL</p>
                <p className="mb-0"><b>Data:</b> {notify?.data_disparo}</p>
                <hr />
            </div>}
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">VALIDAÇÃO DE CONSENSO PARCIAL</p>
            </div>
            <div className="w-100">
                <div className="text-right mb-10">{notify.data_format ? notify.data_format : resp.data_disparo}</div>
            </div>
            <p className="mb-10">
                <br />
            </p>

            <p>Informamos que, após encaminhamento da Comunicação de Abertura de Junta Médica ou Odontológica, o profissional assistente, Dr.(a) <b>{resp.profissional_nome}</b>, CRM (ou CRO): <b>{resp.profissional_crmcro} - {resp.profissional_uf}</b> nos retornou com a seguinte proposta:</p>

            <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">PROCEDIMENTOS/EXAMES</p>
            </div>
            {resp.procedimentos ?
                <div className="w-100 grid">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '40%' }}>Item Divergente</th>
                                <th style={{ width: '30%' }}>Análise Profissional Auditor</th>
                                <th style={{ width: '30%' }}>Proposta Profissional Assistente</th>
                            </tr>
                        </tbody>
                        {resp.procedimentos.map(procedimento => {
                            return <tbody key={procedimento.id}>
                                {procedimento.quantidade_solicitada !== procedimento.quantidade_autorizada ?
                                    <tr>
                                        <td>{procedimento.descricao}</td>
                                        <td>{procedimento.quantidade_solicitada}</td>
                                        <td>{procedimento.quantidade_autorizada}</td>
                                    </tr> : ''}
                            </tbody>
                        })}
                    </table></div> : ''}
            {resp.materiais.length ? <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">MATERIAIS/MEDICAMENTOS</p>
            </div> : ''}
            {resp.materiais.length ?
                <div className="w-100 grid">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '40%' }}>Item Divergente</th>
                                <th style={{ width: '30%' }}>Análise Profissional Auditor</th>
                                <th style={{ width: '30%' }}>Proposta Profissional Assistente</th>
                            </tr>
                        </tbody>
                        {resp.materiais.map(material => {
                            return <tbody key={material.id}>
                                {material.quantidade_solicitada !== material.quantidade_autorizada ?
                                    <tr>
                                        <td>{material.descricao}</td>
                                        <td>{material.quantidade_solicitada}</td>
                                        <td>{material.quantidade_autorizada}</td>
                                    </tr> : ''}
                            </tbody>
                        })}
                    </table></div> : ''}
            <br />

            <p>Segue as observações feitas pelo profissional assistente:</p>
            <p className="bold">"{resp.desempatador_responsavel_laudo}"</p>
            <br />

            <p>Sendo assim, solicitamos a concordância da {resp.operadora_nome} com o proposto para finalizarmos o processo como Consenso.
                Caso a {resp.operadora_nome} discorde, daremos prosseguimento com a Junta Médica ou Odontológica.</p>

            <p>Havendo a possibilidade de uma contraproposta ao profissional assistente, favor nos enviar orientações.</p>

            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>Equipe säästö</p>
            </div>
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro envio de comunicação via {notify?.canal} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            <br />
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação via {notify?.canal} - {notify?.data_leitura} - IP: {notify?.ip} / {notify?.navegador} / {notify?.sistema_operacional} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}
