import React from "react";
const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, strokeWidth, pct, size, className = '' }) => {
  const d = size - strokeWidth;
  const r = d/2;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle className={className}
      r={r}
      cx={size/2}
      cy={size/2}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={strokeWidth}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    />
  );
};

const Text = ({ percentage, text = {} }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={"1.5em"} className={text?.className}
    >
      {text?.label || `${percentage.toFixed(0)}%`}
    </text>
  );
};

export default function Pie({ percentage, colour, background, strokeWidth, size, className, text }) {
  const pct = cleanPercentage(percentage);
  return (
    <svg width={size} height={size} className={className} style={{'minWidth': `${size}px`}}>
      <g transform={`rotate(-90 ${size/2} ${size/2})`}>
        <Circle colour={background} strokeWidth={strokeWidth} size={size}/>
        <Circle className="percentage" colour={colour} strokeWidth={strokeWidth} pct={pct} size={size} />
      </g>
      <Text percentage={pct} text={text} />
    </svg>
  );
}
