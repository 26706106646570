import React, { useState } from "react";
import config from "../../../config/config.js";
import business from "../../../services/business.js";
import InputForm from "../../Form/InputForm.js";
import Loading from "../../Loading.js";
import tools from "../../../lib/tools.js";
import { useAlert } from "react-alert";

export default function Parecer({ form, setForm }) {


  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const [parecer, setParecer] = useState({
    texto: form?.texto_parecer
  });
  const [historico, setHistorico] = useState({
    texto: form?.texto_historico_beneficiario
  });

  const enviarParecer = async () => {

    // disable send
    if (!form) {
      return false;
    }

    setLoading(true);

    let data = {
      id_processo: form?.id,
      id_desempatador: form?.responsaveis.id_desempatador,
      id_enfermeira: form?.responsaveis.id_enfermeira,
      texto: parecer.texto,
      historico: historico.texto,
      aprovacao: 0
    };

    if (!form || !data.texto) {
      alert.error('Você precisa informar suas considerações.');
      setLoading(false);
      return false;
    }

    (form.id_parecer) ? await business.addParecer(data, form.id_parecer) : await business.addParecer(data);

    setForm('texto_parecer', parecer?.texto || '');
    setForm('texto_historico_beneficiario', historico?.texto || '');

    setLoading(false);
    alert.success('As informações foram salvas');
  }

  const setModel = (name, value) => {
    setParecer({ ...parecer, ...{ texto: value } });
  }

  const setModel2 = (name, value) => {
    setHistorico({ ...historico, ...{ texto: value } });
  }

  return (
    <div className="overflow-y scrollfit h-100 aba-parecer">
      {[12, 42].includes(parseInt(form.id_processo_etapa_status)) ?
        <div className="ml-20 mb-20">
          {(form?.id_processo_tipo === '3') &&
            <button className="btn btn-primary f700-16 jc-sb" onClick={(e) => {
              e.preventDefault();
              tools.openUrlExternal(`/${form.id}/consenso`)
            }} disabled={form?.id_processo_etapa !== '4' && form?.id_processo_etapa !== '10' && form?.id_processo_etapa !== '11'}>
              Consenso Técnico <i className="icon icon-print rotate-180 i-20 ml-10" />
            </button>
          }
        </div> :
        <div>
          <h1 className="f600-18 ml-30 mt-20 mb-40">Parecer</h1>
          {loading ? (<div className="mh-30 grid col-3 w-100"><Loading /></div>) : (
            <div className="mh-30">
              {(form?.id_processo_tipo === '1') &&
                <button className="btn btn-primary f700-16 jc-sb" onClick={(e) => {
                  e.preventDefault();
                  tools.openUrlExternal(`/${form.id}/parecer-segunda-opiniao`)
                }} disabled={form?.id_processo_etapa !== '4' && form?.id_processo_etapa !== '10'}>
                  Parecer conclusivo <i className="icon icon-print rotate-180 i-20 ml-10" />
                </button>
              }
              {(form?.id_processo_tipo === '3') &&
                <button className="btn btn-primary f700-16 jc-sb" onClick={(e) => {
                  e.preventDefault();
                  tools.openUrlExternal(`/${form.id}/parecer`)
                }} disabled={form?.id_processo_etapa !== '4' && form?.id_processo_etapa !== '10' && form?.id_processo_etapa !== '11' && form?.id_processo_etapa !== '316' && form?.id_processo_etapa !== '317'}>
                  Parecer conclusivo <i className="icon icon-print rotate-180 i-20 ml-10" />
                </button>
              }

              {form.id_processo_tipo === '1' && <div>
                {(config.user.tipo === '2' || config.user.tipo === '3' || config.user.tipo === '4' || config.user.tipo === '8' || (config.user.tipo === '5' && form.id_processo_etapa !== '10') || (form.id_processo_etapa !== '3' && form.responsaveis.id_desempatador === config.user.id)) &&
                  <InputForm className="mt-50 mb-40" label="Histórico do beneficiário:" type="textarea" height="200px" value={historico?.texto} disabled />}

                {((form.id_processo_etapa === '3' && form.responsaveis.id_desempatador === config.user.id) || (form.id_processo_etapa === '10' && config.user.tipo === '5') || (config.user.tipo === '1')) &&
                  < InputForm className="mt-50 mb-40" label="Histórico do beneficiário:" name="historico" placeholder="Descrever a história clínica do(a) beneficiário(a), descrevendo o sexo, a idade e a conclusão dos exames encaminhados." type="textarea" height="200px" model={setModel2} value={historico?.texto} />}
              </div>}

              {(config.user.tipo === '2' || config.user.tipo === '3' || config.user.tipo === '4' || config.user.tipo === '8' || (config.user.tipo === '5' && form.id_processo_etapa !== '10') || (form.id_processo_etapa !== '3' && form.responsaveis.id_desempatador === config.user.id)) &&
                <InputForm className="mt-50 mb-40 vertical-resize tiebreaker-required" label={`${form.id_processo_tipo === '1' ? 'Conclusão:' : 'Parecer do desempatador:'}`} type="textarea" height="450px" value={parecer?.texto} disabled />}

              {((form.id_processo_etapa === '3' && form.responsaveis.id_desempatador === config.user.id) || (form.id_processo_etapa === '10' && config.user.tipo === '5') || (config.user.tipo === '1')) &&
                < InputForm className="mt-50 mb-40 vertical-resize tiebreaker-required" label={`${form.id_processo_tipo === '1' ? 'Conclusão:' : 'Parecer do desempatador:'}`} name="parecer" placeholder={`${form.id_processo_tipo === '1' ? 'Descrever a conclusão da sua análise e quando necessário indicar outros tipos de tratamento, seja cirúrgico ou conservador. Citar as referências utilizadas em seu parecer.' : 'Descrever a história clínica do(a) beneficiário(a), descrevendo o sexo, a idade e a conclusão dos exames encaminhados. Caso não tenha citado as referências utilizadas nas justificativas acima, citar neste campo.'}`} type="textarea" height="300px" model={setModel} value={parecer?.texto} />}
              <p>{(form.processo_conclusao) && <span>
                RESULTADO: {form.processo_conclusao}
              </span>}</p>
              <p>{form.responsaveis.nome_desempatador ? 'Dr(a). ' + form.responsaveis.nome_desempatador : ''}</p>
              <div className="flex gap-25 mt-40">
                {parecer.aprovacao === '1' &&
                  <div className="status flex ai-fc gap-20 positivo active">
                    <div className="progress-status">
                      <div className="pie-cicle">
                        <i className="icon icon-check i-20" />
                      </div>
                    </div>
                    <div className="flex fd-fc f400-16">
                      Confirmado
                      <span className="f400-12">O parecer foi confirmado, avançar no processo.</span>
                    </div>
                  </div>}

                {parecer.aprovacao === '2' &&
                  <div className="status flex ai-fc gap-20 negativo active">
                    <div className="progress-status">
                      <div className="pie-cicle">
                        <i className="icon icon-cross-small i-25" />
                      </div>
                    </div>
                    <div className="flex fd-fc f400-16">
                      Não aceito
                      <span className="f400-12">O parecer foi devolvido para ajustes.</span>
                    </div>
                  </div>}

              </div>

              {/*  <InputForm className="mt-50 mb-40" label="Comentários" type="textarea" height="200px" /> */}
              <div className="flex jc-fe">
                {(config.temPermissao('processo:parecer:cadastrar') && ((form.responsaveis.id_desempatador === config.user.id && form.id_processo_etapa === '3') || (form.responsaveis.id_enfermeira === config.user.id && form.id_processo_etapa === '10') || (config.user.tipo === '1' && form.id_processo_etapa !== '4'))) &&
                  <button className="btn btn-primary f700-16" onClick={() => enviarParecer()}>
                    Salvar
                  </button>}
              </div>
            </div>)}
        </div>}
    </div>
  );
}
