//import Cep from "cep-promise";
import React from 'react';
import Email from '../Email';

export default function RetJunta({ resp = {}, notify = false, canal, print = false }) {

    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
           <div className="flex">
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {resp.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {resp.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {resp.operadora_telefone}</p>
                </div>
                <img className="image-ops" src={resp?.operadora_imagem} alt='Operadora'></img>
            </div>
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">RETIFICAÇÃO DE JUNTA MÉDICA OU ODONTOLÓGICA</p>
            </div>
            <div className="w-100">
                <div className="text-right mb-10">{notify.data_format ? notify.data_format : resp.data_disparo}</div>
            </div>
            <br />
            <p className="mb-10">
                Dando prosseguimento à solicitação de procedimentos, exames e/ou materiais, medicamentos para o(a) beneficiário (a) <b>{resp.beneficiario_nome}</b>, CPF <b>{resp.beneficiario_cpf}</b> em {resp.data_atendimento}, guia <b>{resp.beneficiario_guia}</b> informamos a(s) seguinte(s) retificações no (informar qual notificação será retificada):
            </p>

            <p><b>Onde se lê:</b></p>
            <p>[informação errada]</p>
            <br />
            <p><b>Leia-se:</b></p>
            <p>[informação retificada]</p>
            <br />
            <p>Salientamos que esta retificação não compromete os comunicados da Junta Médica ou Odontológica enviados anteriormente.</p>
            <p>Em caso de dúvidas, entre em contato com seu o profissional assistente ou, se preferir, retorne este e-mail ou as envie pelo WhatsApp (48) 99201-4736.</p>
            <br />
            <div className="w-100 text-justify bold pb-50">
                <p>Dr. (a) {resp.auditor_nome}, CRM (ou CRO): {resp.auditor_crmcro} - {resp.auditor_uf}.</p>
                <p>Dados para contato: {resp.operadora_telefone}</p>
            </div>
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de envio de comunicação via {notify?.canal} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação via {notify?.canal} - {notify?.data_leitura} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}
