import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import '../../../assets/styles/components/Processo/Historico.scss'
import business from "../../../services/business";
import Loading from "../../Loading";
import moment from 'moment-timezone';

moment.tz.setDefault("America/Sao_Paulo");

export default function Historico({ id, render }) {

  const [notificacao, setNotificacao] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getSearch() {
      setLoading(true);
      let response = await business.getNotificacao({
        tipo: [2],
        processo: id
      });
      setNotificacao(response)
      setLoading(false);
    };
    getSearch();
  }, [id, render])

  return (

    <div className="junta-historico">

      <h1 className="f600-18 mb-20">Histórico</h1>
      {(loading && notificacao.length) ? (<Loading />) : (
        <div className="overflow-y scrollfit h-100 flex fd-fc">
          {notificacao ? (notificacao || []).map((notificacao) => {
            return <div key={notificacao.id} className="flex ai-fs item gap-20">
              <div className="circle" />
              <div className="flex fd-fc fb-14 pb-25">
                <h4 className="f600-16 blue-text m-0">{notificacao.desc_processo_etapa}</h4>
                <p className="f400-12 mv-5">{notificacao.desc_acao}</p>
                <span className="f400-12 mv-5">{notificacao.nome_usuario}</span>
                <p className="f400-12 mv-5">{moment(notificacao.data_criacao, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm')} hrs</p>
              </div>

            </div>
          }) : 'Sem histórico.'}
        </div>
      )}
    </div>
  );
}