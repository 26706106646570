import React, { useEffect } from 'react';
import '../assets/styles/components/Modal.scss';
import tools from '../lib/tools';
import { useState } from 'react';

export default function Modal({ handleClose, show, back = true, modalClass, style, children }) {

  const [renderId, setRenderId] = useState(tools.uuidv4());

  const close = async (e) => {
    toogleOverflow();
    handleClose(e);
  }

  const toogleOverflow = (show) => {
    const body = document.body;
    if (show) {
      body.classList.add('overflow-hidden');
    } else {
      body.classList.remove('overflow-hidden');
    }
  }

  useEffect(() => {
    setRenderId(tools.uuidv4());
    toogleOverflow(show);
  }, [show])

  return (
    <div id={`modal-${renderId}`} className={`modal ${(show) ? "display-block" : "display-none"}`}>
      <section className={`modal-main ${modalClass}`} style={style}>
        {back && <button className="close pointer" onClick={close}>
          <i className="icon icon-cross-small i-25 watermelon" />
        </button>}
        {children}
      </section>
    </div>
  );
}
