import React, { useCallback, useState, useEffect, useRef } from "react";
import { useAlert } from "react-alert";
import config from "../../../config/config.js";
import tools from "../../../lib/tools.js";
import { getAPIClient } from "../../../services/axios.js";
import business from "../../../services/business.js";
import Dropzone from "../../Form/Dropzone.js";
import InputForm from "../../Form/InputForm.js";
import Loading from "../../Loading.js";
import moment from "moment/moment.js";

const api = getAPIClient();

export default function Anexos({ className = 'mt-60', carregarAnexos = true, setModel, options = {}, form = {}, permitirEdicao = false }) {

  const alert = useAlert();

  const [anexos, setAnexos] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const idProcesso = useRef(form.id);

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map((file) => {
      anexos.unshift({
        id: null,
        tipo: 1,
        desc_tipo: '',
        identificador: null,
        nome: file.name,
        descricao: '',
        extensao: file.type?.split('/')[1],
        adicionado: false,
        upload: true,
        deleted: false,
        data_cadastro: tools.getDatetime(),
        data_atualizacao: tools.getDatetime(),
        url: null,
        file: file
      });
      setAnexos(anexos)
      setModel('anexos', [...anexos]);
      return file;
    });
  }, [setModel, anexos]);

  const uploadFile = (i, anexo) => {
    const formData = new FormData();
    formData.append('file', anexo.file);
    formData.append('id_processo', form.id);
    formData.append('id_tipo', anexo.tipo);
    formData.append('descricao', anexo.descricao);
    setUploadStatus(true);
    anexos[i].upload = true;
    setAnexos([...anexos]);
    api.post('processo/' + form.id + '/anexo', formData, {
      headers: {
        'Authorization': `Bearer ${api.getToken()}`
      }
    }).then((response) => {
      if (response.data?.errors && response.data?.errors.length) {
        response.data?.errors.forEach(e => alert.error(e));
        setUploadStatus(false);
      } else {
        anexos[i].id = response.data?.response?.id;
        anexos[i].identificador = response.data?.response?.hash;
        anexos[i].url = response.data?.response?.url;
        anexos[i].adicionado = response.data?.response?.status;
        anexos[i].upload = false;
        delete anexos[i].file;
        form.anexos = anexos;
        setAnexos([...anexos]);
        setModel('anexos', [...anexos], true);
      }
      setUploadStatus(false);
    }).catch((error) => {
      alert.error(error?.message || 'Ocorreu um erro ao enviar o arquivo');
      setUploadStatus(false);
    });
  }

  const viewFile = async (url) => {
    fetch(url, {
      headers: new Headers({
        'Authorization': `Bearer ${api.getToken()}`
      })
    }).then((response) => response.blob())
      .then((blob) => {
        var _url = window.URL.createObjectURL(blob);
        window.open(_url, "_blank").focus();
      }).catch((err) => {
        // on error
      });
  }

  const adicionar = async (i, anexo) => {
    if (uploadStatus) {
      return false;
    }
    uploadFile(i, anexo);
  }

  const remover = async (e, i) => {
    e.preventDefault();
    if (deleteStatus) {
      return false;
    }
    if (!window.confirm('Deseja realmente deletar o arquivo ' + anexos[i].nome + '?')) {
      return false;
    }
    setDeleteStatus(true);
    anexos[i].deleted = true;
    if (anexos[i]?.id) {
      let data = await business.removerAnexo(form.id, anexos[i]);
      if (data?.errors && data?.errors.length) {
        data?.errors.forEach(e => alert.error(e));
        anexos[i].deleted = false;
        form.anexos = anexos;
        setAnexos(anexos);
      } else if (data?.response) {
        anexos.splice(i, 1);
        form.anexos = anexos;
        setAnexos(anexos);
        setModel('anexos', [...anexos], true);
      }
    } else {
      anexos.splice(i, 1);
      form.anexos = anexos;
      setAnexos(anexos);
      setModel('anexos', [...anexos], true);
    }
    setDeleteStatus(false);
  }

  useEffect(() => {
    async function fetchData() {
      if (!carregarAnexos) {
        return false;
      }
      if (!anexos || !anexos.length) {
        let response = await business.getAnexos(idProcesso.current);
        if (response && response.length) {
          setAnexos(response);
          setModel('anexos', [...response]);
        }
      }
    }
    fetchData();
    // eslint-disable-next-line 
  }, [carregarAnexos]);

  return (
    <div className={className}>
      <h2 className="f600-18 mb-30 flex jc-sb ai-fc">Anexos ({anexos.length})</h2>
      <div>
        {(permitirEdicao || form.id_processo_etapa_status === '3' || form.id_processo_etapa_status === '4' || (config.user.tipo === '6' && form.id_processo_etapa_status === '6')) && <Dropzone onDrop={onDrop} description={"Extensões: PDF, PNG, JPG, MP3 e OGG"} accept=".jpeg,.jpg,.png,.pdf,.mp3,.ogg" />}
        <div className="mt-60">
          {(anexos || []).map((anexo, i) => {
            return <div key={i} className="anexos flex ai-fc mb-30 gap-60">
              <div className="form-container grid col-12">
                <div className="span-8" onClick={() => {
                  viewFile(anexo.url);
                }}>
                  <span className="pointer link"><i className="icon icon-add-document i-35 mb-10" /> {anexo.nome}</span>
                  {anexo.usuario_nome && <p className="fn-12 pt-5">Adicionado por {anexo.usuario_nome} em: {moment(anexo.data_cadastro, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm')} hrs</p>}
                </div>
                <div className="span-4">
                  <InputForm name="tipo" type="select" label="Tipo do documento" exibicao={anexo?.adicionado} value={anexo.tipo} options={options.tiposAnexo}
                    model={(name, value) => {
                      anexos[i][name] = parseInt(value);
                      setAnexos([...anexos]);
                    }} />
                </div>
                <div className="span-4">
                  {<InputForm label="Descrição" exibicao={anexo?.adicionado} type="text" value={anexo.descricao}
                    model={(name, value) => {
                      anexos[i].descricao = value;
                      setAnexos([...anexos]);
                    }}
                    name={'anexos.' + anexo.id + '.descricao'} />}
                </div>
                {<div className="span-8">
                  {(((permitirEdicao && form.id_processo_etapa === '12') || config.user.tipo === '1') || (!anexo.id)) && <span className="ml-25 mt-35 pointer float-right" onClick={(e) => remover(e, i)}>
                    {(anexo.deleted) ? <Loading /> : <i className="icon icon-trash watermelon i-25" />}
                  </span>}
                  {(anexo.upload > 0) &&
                    <button className="btn btn-primary f700-16 mt-20 float-right" disabled={uploadStatus} onClick={() => adicionar(i, anexo)}>
                      {(anexo.upload && uploadStatus) ? (<Loading />) : 'Adicionar'}
                    </button>}
                </div>}
              </div>{console.log(anexo)}
            </div>
          })}
        </div>
      </div>
    </div>
  );
}
