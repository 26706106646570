import React, { useEffect, useState } from "react";
import '../assets/styles/components/Search.scss'
import Pie from "./Pie.js";

export default function Stages({ item = {}, stage, onClick, options, form }) {

  const [classe, setClasse] = useState('');

  useEffect(() => {
    async function loadStage() {
      let cls;
      if (item.completed) {
        cls = 'complete';
      } else if (stage === item.id) {
        cls = 'current';
      } else {
        cls = 'next';
      }
      setClasse(cls);
    };
    loadStage();
  }, [item, stage, classe, options, form]);

  return (
    <div className={`stages flex ai-fc ${classe} gap-20`} onClick={onClick}>
      <Pie percentage={60} colour="#5B8FF9" background="#E4E4E4" strokeWidth={5} size={36} text={{ label: item.id, className: 'f800-18' }} />
      {item.label}
    </div>
  );
}

