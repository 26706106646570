import React, { useRef, useState, useEffect } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import Layout from "../../components/Layout.js";
import "../../assets/styles/pages/Relatorio/PowerBI.scss";
import config from '../../config/config.js';
import business from '../../services/business.js';

export default function PowerBI() {

    document.title = 'säästo';
    const [report, setReport] = useState();
    const reportRef = useRef(null);
    const embedUrl = 'https://app.powerbi.com/reportEmbed?reportId=dc045da6-06c9-4048-bf2e-12e6b7e97ea8';
    const user = config.get();
    const [reportConfig, setReportConfig] = useState({
        type: "report",
        embedUrl: embedUrl,
        tokenType: models.TokenType.Embed,
        accessToken: null,
        settings: undefined,
        filters: undefined
    });

    const eventHandlersMap = new Map([
        [
            "loaded",
            function () {
                console.log("Report has loaded");
            }
        ],
        [
            "rendered",
            function () {
                console.log("Report has rendered");
            }
        ],
        [
            "error",
            function (event) {
                if (event) {
                    console.error(event.detail);
                }
            }
        ]
    ]);

    // eslint-disable-next-line
    const applyFilter = () => {

        if (report && report.getFilters) {

            const filter = {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: "operadora",
                    column: "id"
                },
                operator: "In",
                values: [user.id_operadora]
            };

            report.getFilters()
                .then(filters => {
                    filters.push(filter);
                    return report.setFilters(filters);
                })
                .then(() => {
                    console.log("Filtro aplicado com sucesso.");
                })
                .catch(error => {
                    console.error("Erro ao aplicar filtro: " + error);
                });
        }
    }

    useEffect(() => {
        (async () => {
            if (!user?.id_operadora) {
                return;
            }
            let res = await business.getEmbedTokenPowerBI(user.id_operadora);

            if (res?.response?.access_token) {
                setReportConfig({
                    type: "report",
                    embedUrl: embedUrl,
                    tokenType: models.TokenType.Embed,
                    accessToken: res?.response?.access_token,
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        }
                    },
                    filters: (parseInt(user?.tipo) === 1) ? undefined : [
                        {
                            $schema: "http://powerbi.com/product/schema#basic",
                            target: {
                                table: "operadora",
                                column: "id"
                            },
                            operator: "In",
                            values: [parseInt(user.id_operadora)]
                        }
                    ]
                });
            }
        })();
    }, [user])

    return (<Layout className="page power-by">
        <div className="iframe-wrapper">
            <PowerBIEmbed
                embedConfig={reportConfig}
                eventHandlers={eventHandlersMap}
                cssClassName={"report-style-class"}
                getEmbeddedComponent={(embedObject) => {
                    console.log(
                        `Embedded object of type "${embedObject.embedtype}" received`
                    );
                    setReport(embedObject);
                    reportRef.current = embedObject;
                }}
            />
        </div>
    </Layout>);
}