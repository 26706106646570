import React, { useEffect, useMemo } from 'react';
import Modal from '../Modal';
import InputForm from '../Form/InputForm';
import { useCallback } from 'react';
import { useState } from 'react';
import tools from '../../lib/tools';
import business from '../../services/business';
import { useAlert } from 'react-alert';

export default function Modalpassword({ hideModal, show }) {

    const [form, setForm] = useState({});
    const [validacao, setValidacao] = useState([]);
    const [sentStatus, setSentStatus] = useState(false);

    const alert = useAlert();

    const rules = useMemo(() => ({
        senha: {
            required: true,
            minLength: 6
        },
        nova_senha: {
            required: true,
            minLength: 8,
            securePassword: true
        },
        confirma_nova_senha: {
            required: true,
            minLength: 8,
            equalsTo: 'nova_senha'
        }
    }), []);

    const handleClose = (e) => {
        setForm({});
        const body = document.body;
        body.classList.remove('overflow-hidden');
        hideModal(e);
    }

    const salvar = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return false;
        }

        setSentStatus(true);

        const res = await business.alterarSenha(form.senha, form.nova_senha);

        if (res?.errors?.length) {
            (res.errors).forEach(e => alert.error(e));
        } else {
            handleClose(e);
            alert.success('A senha foi alterada com sucesso!');
        }

        setSentStatus(false);
    }

    const validate = useCallback(() => {

        const obrigatorios = [];

        document.querySelectorAll('.modal-alterar-senha [name][required]').forEach((e) => {
            if (e.name && e.style.display !== 'none') obrigatorios.push(e.name);
        });

        let validacao = {};
        let validated = true;

        obrigatorios.forEach(campo => {

            if ((typeof form[campo] === 'undefined') || tools.isEmptyNullOrUndefined(form[campo])) {
                validacao[campo] = 'Campo Obrigatório';
                validated = false;
            } else if (typeof rules[campo] !== 'undefined') {

                if (rules[campo]?.minLength && rules[campo].minLength > form[campo].length) {
                    validacao[campo] = `A senha deve conter ao menos ${rules[campo]?.minLength} caracteres`;
                    validated = false;
                } else if (rules[campo]?.securePassword) {
                    let validPass = tools.validarSenha(form[campo]);
                    validacao[campo] = validPass.texto;
                    validated = validPass?.valido || false;
                } else if (rules[campo]?.equalsTo && form[campo] !== form[rules[campo].equalsTo]) {
                    validacao[campo] = `As senhas não são iguais`;
                    validated = false;
                }

            }
        });

        setValidacao(validacao);

        return validated;
    }, [form, rules]);

    const setModel = (name, value) => {
        setForm((prevState) => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        validate();
    }, [validate]);

    return (
        <Modal show={show} handleClose={handleClose} style={{ maxWidth: '500px', maxHeight: '600px' }} id="modal-alterar-senha" modalClass="modal-cad-usuario modal-alterar-senha">
            <div className="flex">
                <div className="form w-100 mb-50">
                    <h1 className="title mb-45">Alterar senha</h1>
                    <div className={`form-container grid col-12`}>

                        <div className="span-12 flex fd-fc gap-15 ai-fe">
                            <InputForm label="Senha Atual" type="password" placeholder="Senha atual" autoComplete={false}
                                value={form?.senha} error={validacao.senha} name="senha" model={setModel} required={true} />
                        </div>
                        <div className="span-12 flex fd-fc gap-15 ai-fe">
                            <InputForm label="Nova senha" type="password" placeholder="Nova senha" autoComplete={false}
                                value={form?.nova_senha} error={validacao.nova_senha} name="nova_senha" model={setModel} required={true} />
                        </div>
                        <div className="span-12 flex fd-fc gap-15 ai-fe">
                            <InputForm label="Confirmar nova senha" type="password" placeholder="Repetir nova senha" autoComplete={false}
                                value={form?.confirma_nova_senha} error={validacao.confirma_nova_senha} name="confirma_nova_senha" model={setModel} required={true} />
                        </div>
                        <div className="span-12 mt-20 flex">
                            <div className="col-8"></div>
                            <div className="col-4 grid">
                                <button className="btn btn-primary f700-16 span-12" onClick={salvar} disabled={sentStatus}>
                                    {(sentStatus) ? 'Salvando..' : 'Salvar'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Modal>
    );
}
