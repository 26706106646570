import React, {useState} from 'react';
import '../../assets/styles/components/ToggleSwitch.scss'
import {useAlert} from "react-alert";
import business from "../../services/business.js";
import InputForm from "../Form/InputForm.js";

export default function RecuperarSenha({isLogin}) {
  const alert = useAlert();
  const [form, setForm] = useState({});
  const [validation, setValidation] = useState({});
  const setModel = (name, value) => {
    setForm({ ...form, ...{ [name]: value }});
  }

  const recuperarSenha = async (e) => {
    setValidation({})
    e.preventDefault();
    let validated = true;
    let required = ['usuario'], validacao = {};
    required.forEach(r => {
      if ([null, undefined, ''].includes(form[r])) {
        validacao[r] = 'Campo Obrigátorio';
        validated = false;
      }
    })
    setValidation(validacao);

    if (validated) {
      const {response, errors} = await business.recover(form);
      if (response.status) {
        alert.success('Uma nova senha foi enviada ao seu e-mail.')
        isLogin(true);
      } else {
        ([...(errors || []), ...["Ocorreu um erro ao tentar recuperar senha"]]).forEach(e => alert.error(e));
      }
    }
  }
  return (
    <div className="form-login">
      <h1 className="f800-40 mb-15">Esqueci a Senha</h1>
      <p className="f400-16">Insira seu e-mail no campo abaixo.</p>
      <div className="form-container grid col-1 w-100 mt-75">
        <InputForm label="E-mail" className="span-1" placeholder="Seu email"
                   value={form.usuario} error={validation.usuario} name="usuario" model={setModel} />
      </div>
      <div className="flex ai-fc jc-sb mt-100 gap-15 btn-login">
        <span className="f700-18 pointer" onClick={() => isLogin(true)}>Voltar</span>
        <button className="btn btn-primary f700-16 jc-sb"
                onClick={recuperarSenha} style={{width: '250px'}}>
          Recuperar Senha <i className="icon icon-arrow-left rotate-180 i-20" />
        </button>
      </div>
    </div>
  );
};

