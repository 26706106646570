import tools from "../lib/tools";
import packageJson from '../../package.json';

const config = {
  name: 'säästö',
  release: packageJson.version,
  user: null,
  openConfiguracoes: false,
  permissoes: [],
  config: [],
  onChange: () => { },
  load: function () {
    let data = localStorage.getItem('config');
    this.config = (data) ? JSON.parse(data) : [];
  },
  temPermissao: function (flag) {
    return (this.permissoes || []).includes(flag);
  },
  listarPermissoes: function () {
    return this.permissoes || [];
  },
  configuracao: function (flag) {
    return (typeof this.config[flag] !== 'undefined') ? this.config[flag] : '';
  },
  salvarConfiguracao(form) {
    this.config = { ...this.config, ...form };
    localStorage.setItem('config', JSON.stringify(this.config));
    if (typeof this.onChange === 'function') this.onChange();
    return true;
  },
  dataAtual: function () {
    this.load();
    return (this.configuracao('simulador:data_atual') && this.configuracao('simulador:data_atual:status') === 'on') ? this.configuracao('simulador:data_atual') : tools.getDate();
  },
  horaAtual: function () {
    this.load();
    return (this.configuracao('simulador:hora_atual') && this.configuracao('simulador:data_atual:status') === 'on') ? this.configuracao('simulador:hora_atual') : tools.getTime();
  },
  dataHoraAtual() {
    this.load();
    return [this.dataAtual(), this.horaAtual() + ':00'].join(' ');
  },
  alteraDataAtual: function (data) {
    this.config['simulador:data_atual'] = new Date(data).toISOString().split('T')[0];
    localStorage.setItem('config', JSON.stringify(this.config));
    return true;
  },
  isAdmin: function () {
    return parseInt(this.user?.tipo) === 1;
  },
  isFinance: function () {
    return parseInt(this.user?.tipo) === 7;
  },
  isSaastoUser: function () {
    return parseInt(this.user?.tipo) === 1 || parseInt(this.user?.tipo) === 4 || parseInt(this.user?.tipo) === 5 || parseInt(this.user?.tipo) === 7 || parseInt(this.user?.tipo) === 8;
  },
  get: function () {
    return this.user;
  }
}

export default config;