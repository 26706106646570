import React, { useEffect, useState } from 'react';
import '../assets/styles/components/MenuLateral.scss'
import logo from "../assets/images/logo.png";
import logoSmall from "../assets/images/logo-small.png";
import config from "../config/config.js";
import { useNavigate } from "react-router-dom";
import Perfil from "./Header/Perfil.js";
import layout from '../lib/layout';
import tools from '../lib/tools';

export default function MenuLateral({ open, openMenu }) {
  const navigate = useNavigate();
  layout.setMenuOpen(open);

  useEffect(() => {
    scrollTela();
  }, [])

  window.addEventListener('resize', (e) => {
    scrollTela();
  });

  const scrollTela = () => {
    let body = document.body;
    if (body.offsetWidth <= 1024) {
      document.body.style.overflow = layout.menuOpen ? "hidden" : "auto";
    } else {
      document.body.style.overflow = "auto";
    }
  }

  let menu = layout.menu;
  const [selected, setSelected] = useState(layout.selected);
  const [openConfiguracoes, setOpenConfiguracoes] = useState(layout.openConfiguracoes);
  const [openRelatorios, setOpenRelatorios] = useState(layout.openRelatorios);

  const isActive = (item) => {
    let className = '';
    let sub = (openConfiguracoes && item.id === '/configuracoes') || (openRelatorios && item.id === '/relatorios');
    if (selected === item.id || sub) {
      className += 'is-active';
    }
    return className;
  }

  const openItem = (e, item, sub = false) => {
    e.preventDefault();
    setOpenConfiguracoes(item.id === '/configuracoes' || sub)
    setOpenRelatorios(item.id === '/relatorios' || sub)
    setSelected(item.id)
    if (item.id !== '/configuracoes' && item.id !== '/relatorios') {
      openMenu(false);
      layout.setMenuOpen(false);
      navigate(item.id);
    }
  }

  let user = config.user;

  return (
    <div className={`menu-lateral overflow-auto scrollfit ${open ? 'open' : 'close'}`}>
      <div className="container-logo">
        <img src={logo} className="logo-img pointer" alt="logo" onClick={() => tools.openURL("/")} />
        <i className={`icon icon-cross-small pointer white i-35`} onClick={() => openMenu(false)} />
      </div>
      <img src={logoSmall} className="logo-img-small pointer" alt="logo" onClick={() => tools.openURL("/")} />

      <Perfil user={user} />

      <nav>
        <ul>
          {menu.map((item) => {

            if (item?.permission && (!config.temPermissao(item.permission) && item.exceptionForUserId !== parseInt(user?.id))) {
              return '';
            }

            return <li className={`${isActive(item)}`} key={item.id} title={item.label}>
              <span className="pointer" onClick={(e) => openItem(e, item)}>
                <i className={`icon ${item.icon} white i-20`} />
                <label className="pointer">
                  {item.label}
                </label>
              </span>
              {((item.id === '/configuracoes' && openConfiguracoes) || (item.id === '/relatorios' && openRelatorios)) ? <ul className={'sub'}>
                {item.sub.map((sub) => {
                  if (sub?.permission && (!config.temPermissao(sub.permission) && sub.exceptionForUserId !== parseInt(user?.id))) {
                    return '';
                  }
                  return <li className={`${sub.id} ${isActive(sub)} f600-16`} key={sub.id} title={sub.label} style={{ height: '60px' }}>
                    <div />
                    <span className="pointer" onClick={(e) => openItem(e, sub, true)}>
                      <i className={`icon ${sub.icon} white i-20`} style={{ cursor: 'pointer' }} />
                      <label className="pointer">
                        {sub.label}
                      </label>
                    </span>
                  </li>
                })}
              </ul> : null}
            </li>
          })}
        </ul>
      </nav>

      <div className="w-100 text-center mt-25" title="Versão do sistema" id="version">
        <span className="f400-12 color-white">v{([1, 4, 5, 7, 8].includes(parseInt(config.user.tipo))) ? <a href="https://phpenterprise.atlassian.net/wiki/external/NzlhM2M0MzhkOTNiNDUzZGFjZjVkZGE3NmIyOWJkZGU" rel="noreferrer" target="_blank">{config.release}</a> : config.release}</span>
      </div>
    </div>
  );
};

