import React, {useState} from 'react';
import '../assets/styles/pages/Login.scss'
import { Navigate } from 'react-router-dom';

import logo from "../assets/images/logo-texto.png";
import LoginComponent from "../components/Acesso/Login.js";
import RecuperarSenha from "../components/Acesso/RecuperarSenha.js";
import { isAuthenticated } from "../services/auth";

export const MAX_AGE = 60 * 60 * 8 // 8 hours

export default function Login() {
  document.title = 'säästo';
  const [isLogin, setIsLogin] = useState(true);

  if (isAuthenticated()) {
    return <Navigate to={'/'} replace />;
  }

  return (<div className="login container">
    <div className="login-backgroud" />
    <div className="login-form">
      <div>
        <img src={logo} className="logo-img mb-80" alt="logo"/>
        <h1 className="sub-titulo f800-40 white-text">
          Saúde econômica <br /> para operadoras
        </h1>
      </div>
      {isLogin ? <LoginComponent isLogin={setIsLogin} /> : <RecuperarSenha isLogin={setIsLogin}/>}
    </div>
    </div>);
}

