import React, { useRef, useState } from "react";
import '../../assets/styles/components/Search.scss'
import Loading from "../Loading";

export default function Search({ className = '', onChange, searchOnTyping = false, loading = false, placeholder = '', defaultValue = '' }) {

  const inputRef = useRef(null);

  const [value, setValue] = useState(defaultValue);

  const setSearchValue = (e) => {
    let currentValue = (e && e?.target && e?.target?.value) ? e.target.value : "";
    setValue(currentValue);
    if (typeof onChange === 'function' && searchOnTyping) {
      onChange(currentValue);
    }
  }

  return (
    <div className={`search f300-14 relative ${className}`}>
      <input className="f400-16"
        laceholder="Buscar"
        ref={inputRef} value={value || ''}
        onChange={setSearchValue}
        placeholder={placeholder}
        onKeyUp={(e) => {
          let currentValue = (e && e?.target && e?.target?.value) ? e.target.value : "";
          if (e.key === 'Enter') {
            onChange(currentValue);
          }
        }} />
      <button className="f700-16 desktop" id="search-button" name="search-button" disabled={loading} onClick={(e) => {
        e.preventDefault();
        let currentValue = (inputRef && inputRef?.current && inputRef?.current?.value) ? inputRef.current.value : "";
        if (typeof onChange === 'function') {
          onChange(currentValue);
        }
      }}> {((loading) ? <Loading /> : ' Buscar ')} </button>
    </div>
  );
}

