//import Cep from "cep-promise";
import React from 'react';
import Email from '../Email';
export default function ParTecnicoConclusivo({ resp = {}, notify = false, print = false, viewHeader = false }) {
    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
            {viewHeader && <div className="w-100 mb-20">
                <p className="mb-0"><b>De:</b> {notify?.disparo}</p>
                <p className="mb-0"><b>Para:</b> {notify?.canalOPS}</p>
                <p className="mb-0"><b>Assunto:</b> PARECER TÉCNICO CONCLUSIVO</p>
                <p className="mb-0"><b>Data:</b> {notify?.data_disparo}</p>
                <hr />
            </div>}
            <div className="flex">
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {resp.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {resp.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {resp.operadora_telefone}</p>
                </div>
                <img className="image-ops" src={resp?.operadora_imagem} alt='Operadora'></img>
            </div>
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">PARECER TÉCNICO CONCLUSIVO</p>
            </div>
            <div className="w-100">
                <div className="text-right mb-10">{resp.data_parecer}</div>
            </div>
            <p>Informo que, após análise em caráter de desempate de divergências na solicitação para autorização de procedimentos, exames, materiais e/ou medicamentos recebida em {resp.data_atendimento}, para o(a) beneficiário(a) <b>{resp.beneficiario_nome}</b>, guia <b>{resp.beneficiario_guia}</b>, ID <b>{resp.protocolo}</b>, eu, Dr.(a) <b>{resp.desempatador_responsavel_nome}</b>, CRM (ou CRO): <b>{resp.desempatador_responsavel_crmcro} - {resp.desempatador_responsavel_uf}</b> escolhido(a) como desempatador(a) do caso, com base nos documentos e exames clínicos apresentados decido que:</p>
            <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">ANÁLISE DO PROFISSIONAL DESEMPATADOR | PROCEDIMENTOS/EXAMES</p>
            </div>
            {resp.procedimentos ?
                <div className="w-100 grid">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '15%' }}>Código</th>
                                <th style={{ width: '45%' }}>Descrição</th>
                                <th style={{ width: '10%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '10%' }}>Quantidade Pós Análise</th>
                                <th style={{ width: '20%' }}>Resultado Final</th>
                            </tr>
                        </tbody>
                        {resp.procedimentos.map(procedimento => {
                            return <tbody key={procedimento.id}>
                                <tr>
                                    <td>{procedimento.codigo}</td>
                                    <td>{procedimento.descricao}</td>
                                    <td>{procedimento.quantidade_solicitada}</td>
                                    <td>{procedimento.quantidade_pos_analise === 0 ? procedimento.quantidade_autorizada : procedimento.quantidade_pos_analise}</td>
                                    <td>{procedimento.quantidade_solicitada === procedimento.quantidade_autorizada && 'De acordo com a solicitação do profissional assistente'}{procedimento.quantidade_solicitada !== procedimento.quantidade_autorizada && (procedimento.quantidade_pos_analise === procedimento.quantidade_solicitada ? 'De acordo com a solicitação do profissional assistente' : (procedimento.quantidade_pos_analise === procedimento.quantidade_autorizada) ? 'De acordo com a auditoria da operadora' : 'Concordo parcialmente com a solicitação do profissional assistente')}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{procedimento.quantidade_solicitada === procedimento.quantidade_autorizada ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : procedimento.justificativa_pos_analise}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}
            {resp.materiais.length ? <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">ANÁLISE DA OPERADORA | MATERIAIS/MEDICAMENTOS</p>
            </div> : ''}
            {resp.materiais.length ?
                <div className="w-100 grid">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '60%' }}>Descrição</th>
                                <th style={{ width: '10%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '10%' }}>Quantidade Pós Análise</th>
                                <th style={{ width: '20%' }}>Resultado Final</th>
                            </tr>
                        </tbody>
                        {resp.materiais.map(material => {
                            return <tbody key={material.id}>
                                <tr>
                                    <td>{material.descricao}</td>
                                    <td>{material.quantidade_solicitada}</td>
                                    <td>{material.quantidade_pos_analise === 0 ? material.quantidade_autorizada : material.quantidade_pos_analise}</td>
                                    <td>{material.quantidade_solicitada === material.quantidade_autorizada && 'De acordo com a solicitação do profissional assistente'}{material.quantidade_solicitada !== material.quantidade_autorizada && (material.quantidade_pos_analise === material.quantidade_solicitada ? 'De acordo com a solicitação do profissional assistente' : (material.quantidade_pos_analise === material.quantidade_autorizada) ? 'De acordo com a auditoria da operadora' : 'Concordo parcialmente com a solicitação do profissional assistente')}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{material.quantidade_solicitada === material.quantidade_autorizada ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : material.justificativa_pos_analise}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}
            <br />
            <p><b>Considerações:</b></p>
            <p className="bold">"{resp.desempatador_responsavel_laudo || 'Não há.'}"</p>
            <br />
            {resp.conclusao && (<p><b>Conclusão: {resp.conclusao}</b></p>)}
            <br />
            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>Dr. (a) {resp.desempatador_responsavel_nome}, CRM (ou CRO): {resp.desempatador_responsavel_crmcro} - {resp.desempatador_responsavel_uf}.</p>
            </div>
            <br />
            <div className="fn-12 text-center p-10 acqua-mark" style={{ border: '1px solid black' }}>
                <span style={{ opacity: '1', position: 'relative' }}>
                    Assinado eletronicamente por:<br />
                    {resp.desempatador_responsavel_nome}<br />
                    CRM (ou CRO): {resp.desempatador_responsavel_crmcro}<br />
                    Data: {resp.data_parecer_min}
                </span>
            </div>
            <br />
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de envio de comunicação via {notify?.canal} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            <br />
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação via {notify?.canal} - {notify?.data_leitura} - IP: {notify?.ip} / {notify?.navegador} / {notify?.sistema_operacional} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}