import React from 'react';
import { useState } from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import Loading from './Loading';

export default function MenuDots({ menuItens = [], menuPositionTop = false, row = {} }) {
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(menuItens.length);
  const onclick = buttonProps.onClick;
  const [loader, setLoader] = useState(false);
  buttonProps.onClick = (e) => {
    e.stopPropagation();
    onclick(e);
  }
  return (
    <div className="relative">
      <div className={`flex jc-fc ai-fc btn-dots  ${isOpen && 'open'}`} {...buttonProps} >
        {((loader) ? <Loading /> : <i className="icon icon-menu-dots blue i-25" />)}
      </div>
      <div onClick={(e) => e.stopPropagation()} className={`dropdown-submenu ${menuPositionTop ? 'position-top' : ''} ${isOpen ? 'visible' : ''} flex fd-fc`} role='menu'>
        {menuItens.map((item, index) => {
          return <a key={index} className={`relative ${item.className} ${(item?.disabled) ? 'disabled' : ''} ${(item?.noDisplay) ? 'display-none' : ''}`} {...itemProps[index]} href={item.uri} onClick={async (e) => {
            if (item?.disabled) {
              return false;
            }
            if (item?.loader) {
              setLoader(true);
            }
            setIsOpen(false);
            await item.onClick(e, row);
            setLoader(false);
          }}>
            {item.icon && <i className={`icon ${item.icon} mr-10`} />}
            <div className="flex fd-fc">
              <span className="f400-14">{item.label}</span>
            </div>
          </a>
        })}
      </div>
    </div>
  );
};

