import React, { useEffect, useState } from "react";
import Layout from "../components/Layout.js";
import '../assets/styles/pages/Ajuda/FAQ.scss'
import config from "../config/config.js";
import InputForm from "../components/Form/InputForm.js";
import business from "../services/business.js";
import Loading from "../components/Loading.js";
import { useAlert } from "react-alert";

export default function Suporte() {

    const alert = useAlert();
    document.title = 'säästo';
    const [options, setOptions] = useState({ tipos: [], modulos: [] });
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({});

    const setModel = (name, value) => {
        setForm({ ...form, ...{ [name]: value } });
    }

    const enviarMensagem = async () => {

        // disable send
        if (!form) {
            return false;
        }

        setLoading(true);

        let data = {
            id_usuario: config.user.id,
            id_suporte_tipo: form.suporte_tipo,
            id_suporte_modulo: form.suporte_modulo,
            mensagem: form.mensagem
        };

        if (!form.suporte_tipo) {
            alert.error('Você precisa selecionar todos os campos.');
            setLoading(false);
            return false;
        }

        if (!form.mensagem) {
            alert.error('Você precisa informar uma descrição detalhada.');
            setLoading(false);
            return false;
        }

        await business.addSuporte(data);

        setLoading(false);
        alert.success('Sua mensagem foi enviada, entraremos em contato com você.');

        setForm(false);
    }

    useEffect(() => {
        async function getOptions() {

            const tipos = await business.getSuporteTipos();
            const modulos = await business.getSuporteModulos();

            setOptions({ tipos: tipos, modulos: modulos });
        }
        getOptions();
    }, [])

    return (
        <Layout className="page">
            <section>
                <div className="faq-main flex">
                    <div className="form w-100">
                        <h1 className="title mb-30">Suporte</h1>
                        <div className="group-input f400-14 span-12 mb-40">
                            <p>Você está com <strong>dúvidas</strong>, precisa <strong>relatar um problema</strong> ou <strong>sugerir uma melhoria</strong>? <br />Nós do time de suporte da plataforma säästö podemos ajudar! Para isso, selecione os campos abaixo e descreva a situação de maneira detalhada.</p>
                        </div>
                        <div className="form-container grid col-12">
                            <div className="group-input f400-18 span-4">
                                <InputForm label="Seu e-mail de contato" className="span-6" value={config.user.email} name="nome" disabled />
                            </div>

                            <div className="group-input f400-18 span-4">
                                <InputForm label="Tipo de suporte" type="select" name="suporte_tipo" options={options.tipos} model={setModel} value={form.suporte_tipo} required />
                            </div>

                            <div className="group-input f400-18 span-4">
                                <InputForm label="Área do sistema" type="select" name="suporte_modulo" options={options.modulos} model={setModel} value={form.suporte_modulo} required />
                            </div>

                            <div className="group-input f400-18 span-12">
                                < InputForm label="Descrição detalhada" type="textarea" name="mensagem" height="200px" model={setModel} value={form.mensagem} placeholder="Fique a vontade para descrever de forma detalhada o problema, dúvida ou sugestão." required />
                            </div>
                            <div className="group-input f400-18 span-12">
                                <button className="btn btn-primary f700-16 float-right" disabled={false} onClick={() => enviarMensagem()}>
                                    {(loading) ? (<Loading />) : 'Enviar mensagem'}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                <br />

            </section>
        </Layout >);
}

