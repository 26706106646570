import React, { useEffect, useState } from 'react';
import ReactPaginate from "react-paginate";
import Layout from "../../components/Layout.js";
import '../../assets/styles/pages/Usuarios.scss'
import 'pure-css-loader/src/loader-default.sass';
import business from "../../services/business.js";
import layout from '../../lib/layout.js';
import config from '../../config/config.js';
import tools from '../../lib/tools.js';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Loading from '../../components/Loading.js';

export default function TotalEconomizado() {

    let user = config.user;
    document.title = 'säästo';

    layout.openMenu('/usuarios', true);
    const [pager, setPager] = useState({
        page: 1,
        total_pages: 0,
        total_rows: 0,
    });

    const [loaderPdf, setLoaderPdf] = useState(false);
    const [loaderCsv, setLoaderCsv] = useState(false);
    const [processos, setProcessos] = useState([]);
    const [filtros] = useState({
        id_operadora: (!config.isAdmin() && !user.tipo === '7') ? user?.id_operadora : '',
        desc_operadora: (!config.isAdmin() && !user.tipo === '7') ? user?.desc_operadora : ''
    });

    const handlePageClick = (e) => {
        getSearch(e.selected + 1);
    };

    const dataExport = async () => {
        return await business.exportRelatorio({
            id_operadora: (user.tipo === '2' || user.tipo === '3') ? user.id_operadora : ((filtros?.id_operadora) ? filtros.id_operadora : ''),
            id_desempatador: user.tipo === '6' ? user.id : '',
            id_processo_etapa: filtros?.id_processo_etapa || '',
            id_processo_etapa_status: filtros?.id_processo_etapa_status || '',
        });
    }

    const getSearch = async (page = null, loading = true) => {
        setLoading(loading);
        let processos = await business.getAllRelatorios((page === null) ? pager.page : page, 10, {
            id_operadora: (user.tipo === '2' || user.tipo === '3') ? user.id_operadora : ((filtros?.id_operadora) ? filtros.id_operadora : ''),
            id_desempatador: user.tipo === '6' ? user.id : '',
            id_processo_etapa: filtros?.id_processo_etapa || '',
            id_processo_etapa_status: filtros?.id_processo_etapa_status || '',
        });

        setProcessos(processos?.response || [])
        if (processos?.response && pager.page && processos?.pager?.paginas < pager.page) {
            pager.page = 1;
            return getSearch();
        }
        pager.page = processos?.pager?.pagina;
        setPager({
            page: processos?.pager?.pagina,
            total_pages: processos?.pager?.paginas,
            total_rows: processos?.pager?.total
        });
        setLoading(false);
        setSearchLoading(false);
    }

    // eslint-disable-next-line 
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            await getSearch();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const exportToPdf = async () => {
        setLoaderPdf(true);
        let data = (await dataExport())?.response || [];

        const valoresBodyPdf = data.map(elemento => {
            const valores = Object.values(elemento);

            if (valores[1]) {
                valores[1] = tools.formatDate(valores[1]);
            }

            if (valores[2]) {
                valores[2] = valores[2].length ? tools.formatDate(valores[2]) : '-';
            }

            return valores;

        });
        const doc = new jsPDF({ orientation: 'l' });
        autoTable(doc, { html: '#my-table' })
        const header = [['Protocolo'], ['Data Início'], ['Data Conclusão'], ['Tipo'], ['Especialidade'], ['Operadora'], ['Plano'], ['Beneficiário'], ['Beneficiário CPF'], ['Nº guia'], ['Profissional assistente'], ['Profissional CRM/CRO'], ['Desempatador'], ['Assistente'], ['Enfermeira'], ['Etapa'], ['Status'], ['Conclusão'], ['Valor Inicial Procedimentos'], ['Valor Inicial Materiais'], ['Valor Inicial Total'], ['Valor Final Procedimentos'], ['Valor Final Materiais'], ['Valor Final Total'], ['Economizado em procedimentos'], ['Economizado em materiais'], ['Total Economizado']]
        const tableStartY = 10;
        autoTable(doc, {
            head: [header],
            body: valoresBodyPdf,
            startY: tableStartY,
            styles: { cellPadding: 2, fontSize: 6 },
            columnStyles: { 0: { halign: 'center' } },
            margin: { top: 10 },
        });
        doc.save('total-economizado.pdf');
        setLoaderPdf(false);
    };

    const exportToCsv = async () => {
        setLoaderCsv(true);
        let filename = 'total-economizado.csv';
        let data = (await dataExport())?.response || [];

        const options = { style: 'decimal', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const numberFormatter = new Intl.NumberFormat('pt-BR', options);

        const processValue = (value) => {
            if (value === null) {
                return '';
            } else if (value instanceof Date) {
                return value.toLocaleString();
            } else if (typeof value === 'number') {
                return numberFormatter.format(value);
            } else if (typeof value === 'string') {
                if (value.startsWith('+')) {
                    return `'${value}`;
                } else {
                    return `"${value.replace(/"/g, '""')}"`;
                }
            }
            return value;
        };

        const csvRows = [Object.keys(data[0]).map(processValue)]; // Cabeçalho
        data.forEach((objeto) => {
            csvRows.push(Object.values(objeto).map(processValue));
        });

        const csvFile = csvRows.map((row) => row.join(';')).join('\n');
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvFile], { type: 'text/csv;charset=utf-8;' });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        setLoaderCsv(false);
    };

    return (
        <Layout className="view-listagem">
            <p className="page-title">Relatórios</p>
            <h2 className="title flex jc-sb ai-fe black-text mb-40 gap-10">
                Gerencial
            </h2>
            <div className="flex jc-sb ai-fc mb-60">
                <div className="container-search-filter flex ai-fc jc-sb gap-25">
                </div>
                <div className="flex ai-fc gap-30 desktop">
                    <button className="btn btn-primary f700-16" onClick={exportToPdf} disabled={loaderPdf || loaderCsv}>
                        {loaderPdf ? <Loading /> : <i className="icon icon-print i-25" />} <span className="pl-5">Imprimir</span>
                    </button>
                    <button className="btn btn-primary f700-16" onClick={exportToCsv} disabled={loaderCsv || loaderPdf}>
                        {loaderCsv ? <Loading /> : <i className="icon icon-document i-25" />}<span className="pl-5">Exportar</span>
                    </button>
                </div>
            </div>
            {loading ? <div className="loader loader-default is-active" /> : (<div style={{ overflowX: 'scroll' }}>
                <div className="table-mobile">
                    {processos.map((p, i) => {
                        return <div className="item pointer" key={`mobile-${i}`}>
                            <div>
                                <div className="fn-16 linha flex jc-sb">
                                    Protocolo <span>{p.id_protocolo}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Data de início <span>{tools.formatDate(p.inicio_processo) || '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Data de conclusão <span>{p.data_conclusao ? tools.formatDate(p.data_conclusao) : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Tipo de processo <span>{p.desc_processo_tipo}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Especialidade <span>{p.desc_especialidade}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Operadora <span>{p.desc_operadora}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Plano <span>{p.plano ? p.plano : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Beneficiário <span>{p.nome_beneficiario}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    CPF <span>{p.cpf_beneficiario}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Nº guia <span>{p.guia}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Profissional assistente <span>Dr.(a) {p.profissional_assistente}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    CRM/CRO <span>{p.profissional_assistente_crm}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Desempatador <span>{p.nome_desempatador}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Assistente <span>{p.assistente_saasto}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Enfermeira <span>{p.enfermeiro_saasto}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Etapa <span>{p.desc_processo_etapa}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Status <span>{p.desc_processo_etapa_status}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Conclusão <span>{p.processo_conclusao}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Valor inicial procedimentos <span>{p.total_procedimentos_inicial ? tools.formatCurrency(p.total_procedimentos_inicial) : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Valor inicial materiais <span>{p.total_materiais_inicial ? tools.formatCurrency(p.total_materiais_inicial) : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    <strong>Valor inicial total</strong> <span> <strong>{p.total_inicial ? tools.formatCurrency(p.total_inicial) : '-'}</strong></span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Valor final procedimentos <span>{p.total_procedimentos_final ? tools.formatCurrency(p.total_procedimentos_final) : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Valor final materiais <span>{p.total_materiais_final ? tools.formatCurrency(p.total_materiais_final) : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    <strong>Valor final total</strong> <span><strong>{p.total_final ? tools.formatCurrency(p.total_final) : '-'}</strong></span>
                                </div>
                                <div className="fn-16 linha flex jc-sb" style={{ color: '#24BE34' }}>
                                    <strong>Economizado em procedimentos</strong> <span style={{ color: '#24BE34' }}>{p.total_economizado_procedimentos ? tools.formatCurrency(p.total_economizado_procedimentos) : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb" style={{ color: '#24BE34' }}>
                                    <strong>Economizado em materiais</strong> <span style={{ color: '#24BE34' }}>{p.total_economizado_materiais ? tools.formatCurrency(p.total_economizado_materiais) : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb" style={{ color: '#24BE34' }}>
                                    <strong>Total economizado</strong> <span style={{ color: '#24BE34' }}><strong>{p.total_economizado ? tools.formatCurrency(p.total_economizado) : '-'}</strong></span>
                                </div>
                            </div>
                            <div className="w-100 flex jc-fc">
                                -
                            </div>
                        </div>
                    })}
                    {(!processos || !processos?.length) && <div className="item pointer" key={`mobile-0`}>
                        Nenhum resultado encontrado.
                    </div>}
                </div>
                <table className="w-100 table-desktop" style={{ minWidth: '2900px' }}>
                    <thead className="f300-16">
                        <tr>
                            <th className="text-left bold">Protocolo</th>
                            <th className="text-center bold">Data de início</th>
                            <th className="text-center bold">Data de conclusão</th>
                            <th className="text-left bold">Tipo de processo</th>
                            <th className="text-left bold">Especialidade</th>
                            <th className="text-left bold">Operadora</th>
                            <th className="text-left bold">Plano</th>
                            <th className="text-left bold">Beneficiário</th>
                            <th className="text-left bold">CPF</th>
                            <th className="text-left bold">Nº guia</th>
                            <th className="text-left bold">Profissional assistente</th>
                            <th className="text-left bold">CRM/CRO</th>
                            <th className="text-left bold">Desempatador</th>
                            <th className="text-left bold">Assistente</th>
                            <th className="text-left bold">Enfermeira</th>
                            <th className="text-left bold">Etapa</th>
                            <th className="text-left bold">Status</th>
                            <th className="text-left bold">Conclusão</th>
                            <th className="text-center bold">Valor inicial procedimentos</th>
                            <th className="text-center bold">Valor inicial materiais</th>
                            <th className="text-center bold">Valor inicial total</th>
                            <th className="text-center bold">Valor final procedimentos</th>
                            <th className="text-center bold">Valor final materiais</th>
                            <th className="text-center bold">Valor final total</th>
                            <th className="text-center bold">Economizado em procedimentos</th>
                            <th className="text-center bold">Economizado em materiais</th>
                            <th className="text-center bold">Total economizado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {processos.map((p, i) => {
                            return <tr key={`desktop-${i}`} className="hover-highlight">
                                <td className="fn-16 text-left">{p.id_protocolo}</td>
                                <td className="fn-16 text-center">{tools.formatDate(p.inicio_processo) || '-'}</td>
                                <td className="fn-16 text-center">{p.data_conclusao ? tools.formatDate(p.data_conclusao) : '-'}</td>
                                <td className="fn-16 ">{p.desc_processo_tipo}</td>
                                <td className="fn-16 ">{p.desc_especialidade}</td>
                                <td className="fn-16 ">{p.desc_operadora}</td>
                                <td className="fn-16 ">{p.plano ? p.plano : '-'}</td>
                                <td className="fn-16 ">{p.nome_beneficiario}</td>
                                <td className="fn-16 ">{p.cpf_beneficiario}</td>
                                <td className="fn-16 ">{p.guia}</td>
                                <td className="fn-16 ">Dr.(a) {p.profissional_assistente}</td>
                                <td className="fn-16 ">{p.profissional_assistente_crm}</td>
                                <td className="fn-16 ">{p.nome_desempatador}</td>
                                <td className="fn-16 ">{p.assistente_saasto}</td>
                                <td className="fn-16 ">{p.enfermeiro_saasto}</td>
                                <td className="fn-16 ">{p.desc_processo_etapa}</td>
                                <td className="fn-16 ">{p.desc_processo_etapa_status}</td>
                                <td className="fn-16" style={{ maxWidth: '200px' }}>{p.processo_conclusao}</td>
                                <td className="fn-16 text-center">{p.total_procedimentos_inicial ? tools.formatCurrency(p.total_procedimentos_inicial) : '-'}</td>
                                <td className="fn-16 text-center">{p.total_materiais_inicial ? tools.formatCurrency(p.total_materiais_inicial) : '-'}</td>
                                <td className="fn-16 text-center"><strong>{p.total_inicial ? tools.formatCurrency(p.total_inicial) : '-'}</strong></td>
                                <td className="fn-16 text-center">{p.total_procedimentos_final ? tools.formatCurrency(p.total_procedimentos_final) : '-'}</td>
                                <td className="fn-16 text-center">{p.total_materiais_final ? tools.formatCurrency(p.total_materiais_final) : '-'}</td>
                                <td className="fn-16 text-center"><strong>{p.total_final ? tools.formatCurrency(p.total_final) : '-'}</strong></td>
                                <td className="fn-16 text-center" style={{ minWidth: '120px', color: '#24BE34' }}>
                                    {p.total_economizado_procedimentos ? tools.formatCurrency(p.total_economizado_procedimentos) : '-'}
                                </td>
                                <td className="fn-16 text-center" style={{ minWidth: '120px', color: '#24BE34' }}>
                                    {p.total_economizado_materiais ? tools.formatCurrency(p.total_economizado_materiais) : '-'}
                                </td>
                                <td className="fn-16 text-center" style={{ minWidth: '120px', color: '#24BE34' }}>
                                    <strong>{p.total_economizado ? tools.formatCurrency(p.total_economizado) : '-'}</strong>
                                </td>
                            </tr>
                        })}
                        {(!processos || !processos?.length) && <tr>
                            <td colSpan={7}>Nenhum resultado encontrado.</td>
                        </tr>}
                    </tbody>
                </table>
                <div className="component pagination">
                    <div className="pagination-container mt-30">
                        {pager.total_pages < 2 ? '' : <ReactPaginate
                            previousLabel="«"
                            nextLabel="»"
                            breakLabel="..."
                            breakClassName="break-me"
                            page={pager.page - 1 ?? 0}
                            forcePage={pager.page - 1 ?? 0}
                            pageCount={pager.total_pages}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            subContainerClassName="pages pagination"
                            activeClassName="active" />}
                    </div>
                </div>
            </div>)
            }

        </Layout >
    );
}

