import React from "react";
import '../assets/styles/pages/404.scss'
export default function ErrorPage() {

    return (
        <section>
            <div className="error-main">
                <h1 className="fb-48">OPS! :(</h1>
                <br />
                <p>O conteúdo que você procurou não foi encontrado ou não está mais disponível.</p><br /><br />
                <button className="btn btn-primary f700-16 w-50"><a href='/'>Voltar à página inicial</a></button>
            </div>
            <div className="error-background"></div>
        </section>
    );
}

