import axios from "axios";
import moment from "moment-timezone";

moment.tz.setDefault("America/Sao_Paulo");

/* eslint-disable */
const tools = {
  isEmptyNullOrUndefined: (value) => {
    return (
      !value ||
      value === null ||
      value === undefined ||
      value === 0 ||
      value === "" ||
      value === "null" ||
      (typeof value === "object" && !value?.length) ||
      (typeof value === "object" && !Object.keys(value).length)
    );
  },
  clone: (value) => {
    return JSON.parse(JSON.stringify(value));
  },
  uuidv4: () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },
  openURL: (url) => {
    window.location.href = url;
  },
  openURLTab: (path) => {
    const rootUrl = window.location.origin;
    const fullUrl = `${rootUrl}${path}`;
    window.open(fullUrl, '_blank');
  },
  openUrlExternal(url) {
    window.open(url, "_blank").focus();
  },
  getOffset: (el) => {
    let _x = 0;
    let _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  },
  leftPad: (value, totalWidth, paddingChar = "0") => {
    let length = totalWidth - value.toString().length + 1;
    return Array(length).join(paddingChar) + value;
  },
  porcentagem: (total, atual) => {
    return (atual * 100) / total;
  },
  diasEntreDatas: (data1, data2) => {
    const date1 = new Date(data1);
    const date2 = new Date(data2);
    const diferenca = Math.abs(date2 - date1);
    const dias = Math.ceil(diferenca / (1000 * 60 * 60 * 24));
    return dias;
  },
  diasUteisEntreDatas: async (data1, data2) => {
    //const date = moment(data1);
    const date1 = moment(data1).startOf("day");
    const date2 = moment(data2).startOf("day");
    let diasUteis = 0;
    let currentDate = date1;
    while (currentDate.isSameOrBefore(date2)) {
      const diaSemana = currentDate.isoWeekday();
      const isFinalSemana = diaSemana === 6 || diaSemana === 7;
      const isFeriado = await tools.isHoliday(currentDate);
      //const hora = date.hour();
      if (
        !isFinalSemana &&
        !isFeriado /* && !(diaSemana === 5 && hora >= 16) */
      ) {
        diasUteis++;
      }
      currentDate.add(1, "day");
    }
    return diasUteis;
  },
  getDatetime: () => {
    return moment().format("YYYY-MM-DD HH:mm");
  },
  getDate: () => {
    return moment().format("YYYY-MM-DD");
  },
  getTime: () => {
    return moment().format("HH:mm");
  },
  formatDateSQL: function (date) {
    return moment(date, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD");
  },
  formatDate: function (date) {
    return moment(date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY");
  },
  formatDateHour: function (date) {
    return moment(date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
  },
  prepareDate: function (date) {
    if (!date) {
      return "";
    }

    return date.includes(":")
      ? moment(date, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD")
      : moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
  },
  addWorkingDays: async (date, days) => {
    let currDate = moment(date.includes(":") ? date : date + " 00:00").tz(
      "America/Sao_Paulo"
    );
    let lockDate = false;
    for (var i = 0; i <= days; i++) {
      // start day like current hour
      if (
        i === 0 &&
        [1, 2, 3, 4, 5].includes(currDate.day()) &&
        /* (currDate.hour() >= 16 && currDate.minute() >= 31) || currDate.hour() >= 17 */ currDate.hour() >=
        23 &&
        !lockDate
      ) {
        lockDate = true;
        days++;
      } else if (currDate.day() === 6) {
        // saturday
        days++;
      } else if (currDate.day() === 0) {
        // sunday
        days++;
      } else if (await tools.isHoliday(currDate)) {
        // holiday
        days++;
      }

      if (i === days - 1) {
        break;
      }

      // next day
      if (i !== days) {
        currDate.add(1, "day");
      }
    }
    return currDate.format("YYYY-MM-DD");
  },

  addWorkingMinutes: async (date, minutes) => {
    let currDate = moment(date).tz("America/Sao_Paulo");
    let lockDate = false;
    let addedMinutes = 0;

    while (addedMinutes < minutes) {
      // start day like current hour
      if (
        [1, 2, 3, 4, 5].includes(currDate.day()) &&
        ((currDate.hour() >= 16 && currDate.minute() >= 31) ||
          currDate.hour() >= 17) &&
        !lockDate
      ) {
        lockDate = true;
        currDate.hour(8).minute(0); // start next working day at 8:00 AM
      }

      if (currDate.day() === 6) {
        // Saturday
        currDate.day(currDate.day() + 2); // Move to Monday
        currDate.hour(8).minute(0); // Start at 8:00 AM
      } else if (currDate.day() === 0) {
        // Sunday
        currDate.day(currDate.day() + 1); // Move to Monday
        currDate.hour(8).minute(0); // Start at 8:00 AM
      } else if (await tools.isHoliday(currDate)) {
        // Holiday
        currDate.add(1, "day").hour(8).minute(0); // Move to next day and start at 8:00 AM
      } else {
        const remainingMinutes = 60 - currDate.minute();
        if (addedMinutes + remainingMinutes >= minutes) {
          currDate.add(minutes - addedMinutes, "minutes");
          addedMinutes = minutes;
        } else {
          currDate.add(remainingMinutes, "minutes");
          addedMinutes += remainingMinutes;
        }
      }
    }

    return currDate.format("YYYY-MM-DD HH:mm");
  },
  getDayOfWeek: (date) => {
    return moment(date).format("dddd");
  },
  isHoliday: async (date) => {
    if (!date?._isValid) return false;
    const year = date.year();
    const keyStorage = `holidays_${year}`;
    let holidays_cached = localStorage.getItem(keyStorage);
    holidays_cached = holidays_cached ? JSON.parse(holidays_cached) : false;
    if (!holidays_cached) {
      const url = `https://saasto.s3.sa-east-1.amazonaws.com/service/holidays/${year}.json`;
      const { data: holidays } = await axios.get(url);
      const holiday = holidays.find((h) => moment(h.date).isSame(date, "day"));
      localStorage.setItem(keyStorage, JSON.stringify(holidays));
      return holiday ? true : false;
    } else {
      const holiday = holidays_cached.find((h) =>
        moment(h.date).isSame(date, "day")
      );
      return holiday ? true : false;
    }
  },
  validarTamanho: (texto, tamanho) => {
    let validar = texto && texto.length >= tamanho;
    return {
      valido: validar,
      texto: !validar ? "Digite ao menos " + tamanho + " caractéres" : "",
    };
  },
  validarData(data) {
    const [year, month, day] = data.split('-');
    const inputDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (inputDate > currentDate) {
      return false;
    } else {
      return true;
    }
  },
  validarEmail: function (email) {
    return email
      ? email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      : false;
  },
  validarTelefone: function (telefone) {
    telefone = telefone.replace(/\D/g, "");
    if (telefone.length === 10 || telefone.length === 11) {
      if (
        /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/.test(
          telefone
        )
      ) {
        return true;
      }
    }
    return false;
  },
  validarCNPJ: (cnpj) => {
    if (typeof cnpj !== "string") {
      return { valido: false, texto: "Digite um CNPJ" };
    }
    cnpj = cnpj.replace(/[^\d]/g, "");
    if (cnpj.length !== 14) {
      return { valido: false, texto: "Digite todos os números do CNPJ" };
    }
    var isRepeated = /^(\d)\1+$/.test(cnpj);
    if (isRepeated) {
      return { valido: false, texto: "Confira os números do CNPJ" };
    }
    var length = cnpj.length - 2;
    var numbers = cnpj.substring(0, length);
    var digits = cnpj.substring(length);
    var sum = 0;
    var pos = length - 7;
    for (var i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    var firstCheckDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    sum = 0;
    length = length + 1;
    numbers = cnpj.substring(0, length);
    pos = length - 7;
    for (var i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    var secondCheckDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    let valid =
      firstCheckDigit === parseInt(digits.charAt(0)) &&
      secondCheckDigit === parseInt(digits.charAt(1));
    return { valido: valid, texto: !valid ? "Digite um CNPJ válido" : "" };
  },
  validarCPF: (cpf) => {
    if (typeof cpf !== "string") {
      return { valido: false, texto: "Digite um CPF" };
    }
    cpf = cpf.replace(/[^\d]/g, "");
    if (cpf.length !== 11) {
      return { valido: false, texto: "Digite todos os números do CPF" };
    }
    var isRepeated = /^(\d)\1+$/.test(cpf);
    if (isRepeated) {
      return { valido: false, texto: "Confira os números do CPF" };
    }
    var sum = 0;
    for (var i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    var firstCheckDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    sum = 0;
    for (var i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    sum += firstCheckDigit * 2;
    var secondCheckDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    let valid =
      firstCheckDigit === parseInt(cpf.charAt(9)) &&
      secondCheckDigit === parseInt(cpf.charAt(10));
    return { valido: valid, texto: !valid ? "Digite um CPF válido" : "" };
  },
  validarSenha: (senha, tamanho) => {
    senha = senha.trim();
    if (senha.length < tamanho) {
      return {
        valido: false,
        texto: "Digite ao menos " + tamanho + " caractéres",
      };
    }
    if (!/[A-Z]/.test(senha)) {
      return {
        valido: false,
        texto: "A senha deve conter uma letra maiúscula",
      };
    }
    if (!/[\W_]/.test(senha)) {
      return {
        valido: false,
        texto: "A senha deve conter um caractére especial",
      };
    }
    return { valido: true, texto: "" };
  },
  timeout: (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  },
  calcularIdade: (dataNascimento) => {
    // Transforma a data de nascimento em objeto Date
    const dataNasc = new Date(dataNascimento);

    // Calcula a diferença em milissegundos entre a data atual e a data de nascimento
    const diferenca = Date.now() - dataNasc.getTime();

    // Converte a diferença em anos
    const idade = new Date(diferenca).getUTCFullYear() - 1970;

    return idade;
  },
  prepareValue: (n) => {
    return new String(n).includes(",")
      ? parseFloat(
        parseFloat(new String(n).replace(".", "").replace(",", ".")).toFixed(
          2
        )
      )
      : parseFloat(parseFloat(n).toFixed(2));
  },
  formatCurrency: (n) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(n);
  },
};

export default tools;
