//import Cep from "cep-promise";
import React from 'react';
import Email from '../Email';

export default function ComDecisaoJuntaBNF({ resp = {}, notify = false, print = false, viewHeader = false }) {

    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
            {viewHeader && <div className="w-100 mb-20">
                <p className="mb-0"><b>De:</b> {notify?.disparo}</p>
                <p className="mb-0"><b>Para:</b> {notify?.canalBNF}</p>
                <p className="mb-0"><b>Assunto:</b> COMUNICADO DE DECISÃO DE JUNTA MÉDICA OU ODONTOLÓGICA</p>
                <p className="mb-0"><b>Data:</b> {notify?.data_disparo}</p>
                <hr />
            </div>}
            <div className="flex">
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {resp.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {resp.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {resp.operadora_telefone}</p>
                </div>
                <img className="image-ops" src={resp?.operadora_imagem} alt='Operadora'></img>
            </div>
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">COMUNICADO DE DECISÃO DE JUNTA MÉDICA OU ODONTOLÓGICA</p>
            </div>
            <div className="w-100">
                <div className="text-right mb-10">{notify.data_format ? notify.data_format : resp.data_disparo}</div>
            </div>
            <p className="mb-10">
                Olá, <b>{resp.beneficiario_nome}</b>, CPF <b>{resp.beneficiario_cpf}</b>.
            </p>
            <p>Informamos que, após análise em caráter de desempate de divergências na solicitação para autorização de procedimentos, exames, materiais e/ou medicamentos recebida em {resp.data_atendimento}, guia <b>{resp.beneficiario_guia}</b>, ID <b>{resp.protocolo}</b>,
                o(a) Dr.(a) <b>{resp.desempatador_responsavel_nome}</b>, CRM (ou CRO): <b>{resp.desempatador_responsavel_crmcro}</b> - <b>{resp.desempatador_responsavel_uf}</b> escolhido(a) como desempatador(a) do caso, com base nos documentos e exames clínicos apresentados decidiu que:</p>

            <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">ANÁLISE DO PROFISSIONAL DESEMPATADOR | PROCEDIMENTOS</p>
            </div>
            {resp.procedimentos ?
                <div className="w-100 grid mail-visible-desktop">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '15%' }}>Código</th>
                                <th style={{ width: '45%' }}>Descrição</th>
                                <th style={{ width: '10%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '10%' }}>Quantidade Pós Análise</th>
                                <th style={{ width: '20%' }}>Resultado Final</th>
                            </tr>
                        </tbody>
                        {resp.procedimentos.map(procedimento => {
                            return <tbody key={procedimento.id}>
                                <tr>
                                    <td>{procedimento.codigo}</td>
                                    <td>{procedimento.descricao}</td>
                                    <td>{procedimento.quantidade_solicitada}</td>
                                    <td>{procedimento.quantidade_solicitada === procedimento.quantidade_autorizada ? procedimento.quantidade_autorizada : procedimento.quantidade_pos_analise}</td>
                                    {procedimento.quantidade_solicitada === procedimento.quantidade_autorizada && <td>De acordo com a solicitação do profissional assistente</td>}
                                    {procedimento.quantidade_solicitada !== procedimento.quantidade_autorizada && <td>{procedimento.quantidade_pos_analise === procedimento.quantidade_solicitada ? 'De acordo com a solicitação do profissional assistente' : (procedimento.quantidade_pos_analise === procedimento.quantidade_autorizada) ? 'De acordo com a auditoria da operadora' : 'Concordo parcialmente com a solicitação do profissional assistente'}</td>}
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{[11, 17].includes(procedimento.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : procedimento.justificativa_pos_analise}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}

            <div className="mail-visible-mobile nao-imprimir">
                {(resp.procedimentos && !print) ? resp.procedimentos.map((procedimento, i) => {
                    i++
                    return <div key={procedimento.id}>
                        <ul className="mt-20">
                            <li className="mail-li">Item {i}:</li>
                            <li className="mail-li">Código: <b>{procedimento.codigo}</b></li>
                            <li className="mail-li">Descrição:  <b>{procedimento.descricao}</b></li>
                            <li className="mail-li">Quantidade Solicitada:  <b>{procedimento.quantidade_solicitada}</b></li>
                            <li className="mail-li">Quantidade Pós Análise:  <b>{procedimento.quantidade_solicitada === procedimento.quantidade_autorizada ? procedimento.quantidade_autorizada : procedimento.quantidade_pos_analise}</b></li>
                            <li className="mail-li">Resultado Final:  <b>{procedimento.quantidade_solicitada === procedimento.quantidade_autorizada && 'De acordo com a solicitação do profissional assistente'}{procedimento.quantidade_solicitada !== procedimento.quantidade_autorizada && (procedimento.quantidade_pos_analise === procedimento.quantidade_solicitada ? 'De acordo com a solicitação do profissional assistente' : (procedimento.quantidade_pos_analise === procedimento.quantidade_autorizada) ? 'De acordo com a auditoria da operadora' : 'Concordo parcialmente com a solicitação do profissional assistente')}</b></li>
                            <li className="mail-li">Justificativa:  <b>{[11, 17].includes(procedimento.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : procedimento.justificativa_pos_analise}</b></li>
                        </ul>
                    </div>
                }) : ''}
            </div>

            {resp.materiais.length ? <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">ANÁLISE DO PROFISSIONAL DESEMPATADOR | MATERIAIS</p>
            </div> : ''}
            {resp.materiais.length ?
                <div className="w-100 grid mail-visible-desktop">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '60%' }}>Descrição</th>
                                <th style={{ width: '10%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '10%' }}>Quantidade Pós Análise</th>
                                <th style={{ width: '20%' }}>Resultado Final</th>
                            </tr>
                        </tbody>
                        {resp.materiais.map(material => {
                            return <tbody key={material.id}>
                                <tr>
                                    <td>{material.descricao}</td>
                                    <td>{material.quantidade_solicitada}</td>
                                    <td>{material.quantidade_solicitada === material.quantidade_autorizada ? material.quantidade_autorizada : material.quantidade_pos_analise}</td>
                                    {material.quantidade_solicitada === material.quantidade_autorizada && <td>De acordo com a solicitação do profissional assistente</td>}
                                    {material.quantidade_solicitada !== material.quantidade_autorizada && <td>{material.quantidade_pos_analise === material.quantidade_solicitada ? 'De acordo com a solicitação do profissional assistente' : (material.quantidade_pos_analise === material.quantidade_autorizada) ? 'De acordo com a auditoria da operadora' : 'Concordo parcialmente com a solicitação do profissional assistente'}</td>}
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{[21, 28].includes(material.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : material.justificativa_pos_analise}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}

            <div className="mail-visible-mobile nao-imprimir">
                {(resp.materiais && !print) ? resp.materiais.map((materiais, i) => {
                    i++
                    return <div key={materiais.id}>
                        <ul className="mt-20">
                            <li className="mail-li">Item {i}:</li>
                            <li className="mail-li">Descrição:  <b>{materiais.descricao}</b></li>
                            <li className="mail-li">Quantidade Solicitada:  <b>{materiais.quantidade_solicitada}</b></li>
                            <li className="mail-li">Quantidade Pós Análise:  <b>{materiais.quantidade_solicitada === materiais.quantidade_autorizada ? materiais.quantidade_autorizada : materiais.quantidade_pos_analise}</b></li>
                            <li className="mail-li">Resultado Final:  <b>{materiais.quantidade_solicitada === materiais.quantidade_autorizada && 'De acordo com a solicitação do profissional assistente'}{materiais.quantidade_solicitada !== materiais.quantidade_autorizada && (materiais.quantidade_pos_analise === materiais.quantidade_solicitada ? 'De acordo com a solicitação do profissional assistente' : (materiais.quantidade_pos_analise === materiais.quantidade_autorizada) ? 'De acordo com a auditoria da operadora' : 'Concordo parcialmente com a solicitação do profissional assistente')}</b></li>
                            <li className="mail-li">Justificativa:  <b>{[21, 28].includes(materiais.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : materiais.justificativa_pos_analise}</b></li>
                        </ul>
                    </div>
                }) : ''}
            </div>

            <br />
            <p><b>Considerações:</b></p>
            <p className="bold">"{resp.desempatador_responsavel_laudo}"</p>
            <br />
            {resp.conclusao && (<p><b>Conclusão: {resp.conclusao}</b></p>)}
            <br />

            <p>Informamos que a {resp.operadora_nome} garantirá o cumprimento da decisão do(a) profissional desempatador(a).</p>
            <p>Se for de seu interesse, os documentos referentes ao parecer técnico conclusivo estarão disponíveis para você, sem custo, no prazo máximo de 24 (vinte e quatro) horas após a solicitação, retorne este e-mail ou fale conosco pelo WhatsApp (48) 99201-4736.</p>

            <br />

            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>{resp.auditor_nome}, CRM (ou CRO): {resp.auditor_crmcro} - {resp.auditor_uf}.</p>
                <p>Dados para contato: {resp.operadora_telefone}</p>
            </div>
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de envio de comunicação ao beneficiário via {notify?.canal}: {notify?.canalBNF} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            <br />
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação pelo beneficiário via {notify?.canal}: {notify?.canalBNF} - {notify?.data_leitura} - IP: {notify?.ip} / {notify?.navegador} / {notify?.sistema_operacional} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}
