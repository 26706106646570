import React, { useEffect, useState } from 'react';
import Email from '../Email';
import tools from '../../lib/tools';
import business from '../../services/business';
import Loading from '../Loading';

export default function ConviteDesempatador({ id, hash, resp = {}, setModal, disabled = false }) {

    const [render, setRender] = useState(false);
    const [loadAceitar, setLoadAceitar] = useState(false);
    const [loadRecusar, setLoadRecusar] = useState(false);

    const aceitar = async (e) => {
        e.preventDefault();
        if (loadAceitar) {
            return false;
        }
        setLoadAceitar(true);
        let a = await business.aceitouParticipar(id, hash);
        if (a?.response?.status) {
            resp.notificacao_envio.id_notificacao_envio_status = 4;
            obrigado();
        }
        setLoadAceitar(false);
        setRender(tools.uuidv4());
    }

    const recusar = async (e) => {
        e.preventDefault();
        if (loadRecusar) {
            return false;
        }
        setLoadRecusar(true);
        let a = await business.recusouParticipar(id, hash);
        if (a?.response?.status) {
            resp.notificacao_envio.id_notificacao_envio_status = 5;
            obrigado();
        }
        setLoadRecusar(false);
        setRender(tools.uuidv4());
    }

    const obrigado = () => {
        setModal({
            text: "Agradecemos seu interesse em atuar como desempatador neste processo. O selecionado será informado.",
            show: true,
            buttonCancel: false,
            onAccept: async (e) => {
                setModal({
                    show: false
                });
            }
        });
    }

    useEffect(() => { }, [render, resp]);

    return (
        <Email emailClass="email">
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">CONSULTA DE DISPONIBILIDADE PARA PARTICIPAÇÃO DE JUNTA MÉDICA OU ODONTOLÓGICA</p>
            </div>
            <div className="w-100">
                <div className="text-right mb-10">{resp.data_disparo}</div>
            </div>
            <p className="mb-10">
                Olá Dr(a). {resp.nome_usuario}
            </p>
            <p>Gostaríamos de convidá-lo(a) para compor a lista de opções para Profissional Desempatador no processo de Junta Médica ou Odontológica da <b>{resp.operadora_nome}</b> para o (a) beneficiário(a) com <b>{resp.beneficiario_idade}</b> anos de idade, sexo <b>{resp.beneficiario_sexo}</b> em que o(a) Dr(a). <b>{resp.profissional_nome}</b> fez as seguintes solicitações:</p>

            <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">PROCEDIMENTOS/EXAMES</p>
            </div>
            {resp.procedimentos ?
                <div className="w-100 grid mail-visible-desktop">
                    <table style={{ verticalAlign: 'middle' }}>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '15%' }}>Código</th>
                                <th style={{ width: '45%' }}>Descrição</th>
                                <th style={{ width: '10%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '10%' }}>Quantidade Autorizada</th>
                                <th style={{ width: '10%' }}>Possui divergência</th>
                                <th style={{ width: '10%' }}>Tipo de divergência</th>
                            </tr>
                        </tbody>
                        {(resp.procedimentos || []).map(procedimento => {
                            return <tbody key={procedimento.id}>
                                <tr>
                                    <td>{procedimento.codigo}</td>
                                    <td>{procedimento.descricao}</td>
                                    <td>{procedimento.quantidade_solicitada}</td>
                                    <td>{procedimento.quantidade_autorizada}</td>
                                    <td>{procedimento.quantidade_solicitada !== procedimento.quantidade_autorizada ? 'SIM' : 'NÃO'}</td>
                                    <td>{procedimento.divergencia}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{procedimento.justificativa}</td>
                                </tr>
                            </tbody>
                        })}

                    </table></div> : ''}

            <div className="mail-visible-mobile nao-imprimir">
                {resp.procedimentos ? resp.procedimentos.map((procedimento, i) => {
                    i++
                    return <div key={procedimento.id}>
                        <ul className="mt-20">
                            <li className="mail-li">Item {i}:</li>
                            <li className="mail-li">Código: <b>{procedimento.codigo}</b></li>
                            <li className="mail-li">Descrição:  <b>{procedimento.descricao}</b></li>
                            <li className="mail-li">Quantidade Solicitada:  <b>{procedimento.quantidade_solicitada}</b></li>
                            <li className="mail-li">Quantidade Autorizada:  <b>{procedimento.quantidade_autorizada}</b></li>
                            <li className="mail-li">Possui divergência:  <b>{procedimento.quantidade_solicitada !== procedimento.quantidade_autorizada ? 'SIM' : 'NÃO'}</b></li>
                            <li className="mail-li">Tipo de divergência:  <b>{procedimento.divergencia}</b></li>
                            <li className="mail-li">Justificativa:  <b>{procedimento.justificativa}</b></li>
                        </ul>
                    </div>
                }) : ''}
            </div>

            <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">MATERIAIS/MEDICAMENTOS</p>
            </div>
            {resp.materiais ?
                <div className="w-100 grid mail-visible-desktop">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '60%' }}>Descrição</th>
                                <th style={{ width: '10%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '10%' }}>Quantidade Autorizada</th>
                                <th style={{ width: '10%' }}>Possui divergência</th>
                                <th style={{ width: '10%' }}>Tipo de divergência</th>
                            </tr>
                        </tbody>
                        {(resp.materiais || []).map(material => {
                            return <tbody key={material.id}>
                                <tr>
                                    <td>{material.descricao}</td>
                                    <td>{material.quantidade_solicitada}</td>
                                    <td>{material.quantidade_autorizada}</td>
                                    <td>{material.quantidade_solicitada !== material.quantidade_autorizada ? 'SIM' : 'NÃO'}</td>
                                    <td>{material.divergencia}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{material.justificativa}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}

            <div className="mail-visible-mobile nao-imprimir">
                {resp.materiais ? resp.materiais.map((materiais, i) => {
                    i++
                    return <div key={materiais.id}>
                        <ul className="mt-20">
                            <li className="mail-li">Item {i}:</li>
                            <li className="mail-li">Descrição:  <b>{materiais.descricao}</b></li>
                            <li className="mail-li">Quantidade Solicitada:  <b>{materiais.quantidade_solicitada}</b></li>
                            <li className="mail-li">Quantidade Autorizada:  <b>{materiais.quantidade_autorizada}</b></li>
                            <li className="mail-li">Possui divergência:  <b>{materiais.quantidade_solicitada !== materiais.quantidade_autorizada ? 'SIM' : 'NÃO'}</b></li>
                            <li className="mail-li">Tipo de divergência:  <b>{materiais.divergencia}</b></li>
                            <li className="mail-li">Justificativa:  <b>{materiais.justificativa}</b></li>
                        </ul>
                    </div>
                }) : ''}
            </div>

            <br />

            <p>Aceitando este convite e sendo escolhido como desempatador, você será novamente comunicado. Sua análise deverá ser realizada em <b>até 24 horas</b> após a notificação de escolha.</p>
            <p>Em caso de aceite, por favor, responda este e-mail.</p>
            <div className="w-100 flex bg-gap mt-50">
                <button className="btn btn-primary f700-16 w-100" onClick={aceitar} disabled={((parseInt(resp?.notificacao_envio?.id_notificacao_envio_status)) > 3) || loadAceitar || disabled}>{((parseInt(resp?.notificacao_envio?.id_notificacao_envio_status)) === 4) ? <i className="icon icon-check i-15 mr-5" /> : ''}{((parseInt(resp?.notificacao_envio?.id_notificacao_envio_status)) === 4) ? 'Aceitou participar' : (loadAceitar ? <Loading /> : 'Participar')}</button>
                <button className="btn btn-primary f700-16 w-100" onClick={recusar} disabled={((parseInt(resp?.notificacao_envio?.id_notificacao_envio_status)) > 3) || loadRecusar || disabled}>{((parseInt(resp?.notificacao_envio?.id_notificacao_envio_status)) === 5) ? <i className="icon icon-cross-small i-15 mr-5" /> : ''}{((parseInt(resp?.notificacao_envio?.id_notificacao_envio_status)) === 5) ? 'Recusou participar' : (loadRecusar ? <Loading /> : 'Recusar')}</button>
            </div>
            <br />
            <br />
            <br />
            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>Equipe säästo.</p>
            </div>
        </Email>
    );
}
