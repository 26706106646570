import React, { useEffect, useState } from 'react';
import tools from '../../lib/tools';
import business from '../../services/business';
import Email from '../Email';

export default function ComAberturaJuntaPA({ id, hash, resp = {}, setModal, notify = false, disabled = false, print = false, viewHeader = false }) {

    const [render, setRender] = useState(false);
    const [loadEscolher, setLoadEscolher] = useState(false);
    const [loadAbster, setLoadAbster] = useState(false);
    const [loadconsenso, setLoadConsenso] = useState(false);
    const [idDesempatador, setIdDesempatador] = useState(resp?.id_desempatador);
    const [statusAbstencao, setStatusAbstencao] = useState(false);
    const [statusConsenso, setStatusConsenso] = useState(false);

    const escolher = async (e, item) => {
        e.preventDefault();

        let access_token = localStorage.getItem('access_token');

        if (loadEscolher || !access_token) {
            return false;
        }

        setModal({
            text: `Você confirma a escolha do desempatador ${item.nome} com CRM/CRO ${item.crm || item.cro} de ${item?.crm_uf || item?.cro_uf || 'UF'}?`,
            show: true,
            buttonCancel: true,
            onAccept: async (e) => {
                setLoadEscolher(true);
                let a = await business.escolherDesempatador(id, hash, access_token, item);
                if (a?.response?.status) {
                    obrigado();
                    setIdDesempatador(item.id)
                    resp.id_desempatador = item.id
                }
            },
            onClose: async (e) => {
                setLoadEscolher(false);
                setModal({
                    show: false
                });
                setRender(tools.uuidv4());
            }
        });
    }

    const abstencao = async (e) => {
        e.preventDefault();

        let access_token = localStorage.getItem('access_token');

        if (loadAbster || !access_token) {
            return false;
        }

        setModal({
            text: `Você confirma que se abstem da escolha do desempatador?`,
            show: true,
            buttonCancel: true,
            onAccept: async (e) => {
                setLoadAbster(true);
                let a = await business.abstencao(id, hash, access_token);
                if (a?.response?.status) {
                    obrigado();
                    setIdDesempatador(0);
                    resp.etapa = 9;
                    resp.status = 35;
                    setStatusAbstencao(true);
                }
            },
            onClose: async (e) => {
                setLoadAbster(false);
                setModal({
                    show: false
                });
                setRender(tools.uuidv4());
            }
        });
    }

    const consenso = async (e) => {
        e.preventDefault();

        let access_token = localStorage.getItem('access_token');

        if (loadconsenso || !access_token) {
            return false;
        }

        setModal({
            text: `Você confirma que deseja uma tentativa de consenso?`,
            show: true,
            buttonCancel: true,
            onAccept: async (e) => {
                setLoadConsenso(true);
                let a = await business.consenso(id, hash, access_token);
                if (a?.response?.status) {
                    obrigadoContato();
                    setIdDesempatador(0);
                    resp.etapa = 9;
                    resp.status = 36;
                    setStatusConsenso(true);
                }
            },
            onClose: async (e) => {
                setLoadConsenso(false);
                setModal({
                    show: false
                });
                setRender(tools.uuidv4());
            }
        });
    }

    const obrigado = () => {
        setModal({
            text: "Obrigado por sua resposta.",
            show: true,
            buttonCancel: false,
            onAccept: async (e) => {
                setModal({
                    show: false
                });
            }
        });
    }

    const obrigadoContato = () => {
        setModal({
            text: "Em breve entraremos em contato – säästö!",
            show: true,
            buttonCancel: false,
            onAccept: async (e) => {
                setModal({
                    show: false
                });
            }
        });
    }

    useEffect(() => {
        setStatusAbstencao(parseInt(resp?.etapa) === 9 && parseInt(resp?.status) === 35)
        setStatusConsenso(parseInt(resp?.etapa) === 9 && parseInt(resp?.status) === 36)
        setIdDesempatador(parseInt(resp?.id_desempatador))
    }, [render, resp, idDesempatador, statusAbstencao, statusConsenso]);

    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
            {viewHeader && <div className="w-100 mb-20">
                <p className="mb-0"><b>De:</b> {notify?.disparo}</p>
                <p className="mb-0"><b>Para:</b> {notify?.canalPA}</p>
                <p className="mb-0"><b>Assunto:</b> COMUNICADO DE ABERTURA DE JUNTA MÉDICA OU ODONTOLÓGICA</p>
                <p className="mb-0"><b>Data:</b> {notify?.data_disparo}</p>
                <hr />
            </div>}
            <div className="flex">
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {resp.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {resp.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {resp.operadora_telefone}</p>
                </div>
                <img className="image-ops" src={resp?.operadora_imagem} alt='Operadora'></img>
            </div>
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">COMUNICADO DE ABERTURA DE JUNTA MÉDICA OU ODONTOLÓGICA</p>
            </div>
            <div className="w-100">
                <div className="text-right mb-10">{notify.data_format ? notify.data_format : resp.data_disparo}</div>
            </div>
            <p className="mb-10">
                Olá, Dr(a). <b>{resp.profissional_nome}</b>.
            </p>

            <p>Recebemos uma solicitação para autorização de procedimentos, exames, materiais e/ou medicamentos em <b>{resp.data_atendimento}</b>, para o(a) <b>{resp.beneficiario_nome}</b>, guia <b>{resp.beneficiario_guia}</b>, ID <b>{resp.protocolo}</b>.</p>
            <p>Após análise da <b>{resp.operadora_nome}</b> , representada pelo Dr.(a) <b>{resp.auditor_nome}</b>, CRM (ou CRO): <b>{resp.auditor_crmcro}</b> - <b>{resp.auditor_uf}</b> informamos as seguintes divergências nas indicações:</p>

            <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">ANÁLISE DA OPERADORA | PROCEDIMENTOS</p>
            </div>
            {resp.procedimentos ?
                <div className="w-100 grid mail-visible-desktop">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '15%' }}>Código</th>
                                <th style={{ width: '45%' }}>Descrição</th>
                                <th style={{ width: '10%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '10%' }}>Quantidade Autorizada</th>
                                <th style={{ width: '10%' }}>Possui divergência</th>
                                <th style={{ width: '10%' }}>Tipo de divergência</th>
                            </tr>
                        </tbody>
                        {resp.procedimentos.map(procedimento => {
                            return <tbody key={procedimento.id}>
                                <tr>
                                    <td>{procedimento.codigo}</td>
                                    <td>{procedimento.descricao}</td>
                                    <td>{procedimento.quantidade_solicitada}</td>
                                    <td>{procedimento.quantidade_autorizada}</td>
                                    <td>{[11, 17].includes(procedimento.id_divergencia) ? 'NÃO' : 'SIM'}</td>
                                    <td>{procedimento.divergencia}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{[11, 17].includes(procedimento.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : procedimento.justificativa}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}


            <div className="mail-visible-mobile nao-imprimir">
                {(resp.procedimentos && !print) ? resp.procedimentos.map((procedimento, i) => {
                    i++
                    return <div key={procedimento.id}>
                        <ul className="mt-20">
                            <li className="mail-li">Item {i}:</li>
                            <li className="mail-li">Código: <b>{procedimento.codigo}</b></li>
                            <li className="mail-li">Descrição:  <b>{procedimento.descricao}</b></li>
                            <li className="mail-li">Quantidade Solicitada:  <b>{procedimento.quantidade_solicitada}</b></li>
                            <li className="mail-li">Quantidade Autorizada:  <b>{procedimento.quantidade_autorizada}</b></li>
                            <li className="mail-li">Possui divergência:  <b>{[11, 17].includes(procedimento.id_divergencia) ? 'NÃO' : 'SIM'}</b></li>
                            <li className="mail-li">Tipo de divergência:  <b>{procedimento.divergencia}</b></li>
                            <li className="mail-li">Justificativa:  <b>{[11, 17].includes(procedimento.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : procedimento.justificativa}</b></li>
                        </ul>
                    </div>
                }) : ''}
            </div>

            {resp.materiais.length ? <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">ANÁLISE DA OPERADORA | MATERIAIS</p>
            </div> : ''}
            {resp.materiais.length ?
                <div className="w-100 grid mail-visible-desktop">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '60%' }}>Descrição</th>
                                <th style={{ width: '10%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '10%' }}>Quantidade Autorizada</th>
                                <th style={{ width: '10%' }}>Possui divergência</th>
                                <th style={{ width: '10%' }}>Tipo de divergência</th>
                            </tr>
                        </tbody>
                        {resp.materiais.map(material => {
                            return <tbody key={material.id}>
                                <tr>
                                    <td>{material.descricao}</td>
                                    <td>{material.quantidade_solicitada}</td>
                                    <td>{material.quantidade_autorizada}</td>
                                    <td>{[21, 28].includes(material.id_divergencia) ? 'NÃO' : 'SIM'}</td>
                                    <td>{material.divergencia}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{[21, 28].includes(material.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : material.justificativa}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}

            <div className="mail-visible-mobile nao-imprimir">
                {(resp.materiais && !print) ? resp.materiais.map((materiais, i) => {
                    i++
                    return <div key={materiais.id}>
                        <ul className="mt-20">
                            <li className="mail-li">Item {i}:</li>
                            <li className="mail-li">Descrição:  <b>{materiais.descricao}</b></li>
                            <li className="mail-li">Quantidade Solicitada:  <b>{materiais.quantidade_solicitada}</b></li>
                            <li className="mail-li">Quantidade Autorizada:  <b>{materiais.quantidade_autorizada}</b></li>
                            <li className="mail-li">Possui divergência:  <b>{[21, 28].includes(materiais.id_divergencia) ? 'NÃO' : 'SIM'}</b></li>
                            <li className="mail-li">Tipo de divergência:  <b>{materiais.divergencia}</b></li>
                            <li className="mail-li">Justificativa:  <b>{[21, 28].includes(materiais.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : materiais.justificativa}</b></li>
                        </ul>
                    </div>
                }) : ''}
            </div>
            <br />
            <p>Você tem <b>até 2 dias úteis</b> para decidir como deseja prosseguir com este processo. Após clicar em um dos botões de escolha abaixo, não será possível selecionar outra opção.</p>
            <p>Caso você concorde com a indicação do(a) profissional auditor(a) e deseje acatar a análise realizada,selecione o botão abaixo e entraremos em contato para Consenso.</p>
            <br />
            {(parseInt(idDesempatador) === 0 || statusConsenso) && <>
                <button className={`btn btn-primary f700-16 w-100 ${statusConsenso ? 'selected' : ''}`} onClick={consenso} disabled={statusConsenso || statusAbstencao || disabled}>{(statusConsenso) && <i className="icon icon-check i-15 mr-5" />} QUERO ENTRAR EM CONSENSO</button>
            </>}
            <br />

            <p>Se você  entende que deve manter sua indicação, discordando da opinião do(a) profissional auditor(a), seguiremos com o processo de Junta Médica ou Odontológica para o desempate das divergências ocorridas.</p>
            <p>Para prosseguir com a Junta Médica ou Odontológica, solicitamos que você faça a escolha do profissional desempatador dentre as opções abaixo:</p>

            <br />
            {resp?.desempatadores?.map((desempatador, i) => {
                i++;
                let selected = (parseInt(idDesempatador) === parseInt(desempatador.id) && !statusAbstencao);
                return <div key={desempatador.id}>
                    <p className="mb-20"><b>{i}ª opção: Dr.(a) {desempatador.nome} – CRM/CRO: {desempatador.crm || desempatador.cro} – {desempatador.crm_uf || desempatador.cro_uf}</b></p>
                    {(desempatador.descricao) && <p className="mb-20">{desempatador.descricao}</p>}
                    <button className={`btn btn-primary f700-16 w-100 ${selected ? 'selected' : ''}`} disabled={parseInt(idDesempatador) > 0 || statusAbstencao || statusConsenso || disabled} onClick={(e) => escolher(e, desempatador)}>{(selected) && <i className="icon icon-check i-15 mr-5" />} Selecionar {i}ª opção: {desempatador.nome} – CRM/CRO: {desempatador.crm || desempatador.cro} – {desempatador.crm_uf || desempatador.cro_uf}</button>
                    <br /><br /></div>
            })}

            {(parseInt(idDesempatador) === 0 || statusAbstencao) && <>
                <p>Ou se preferir, você pode se abster dessa escolha ou solicitar consenso.</p>
                <button className={`btn btn-primary f700-16 w-100 ${statusAbstencao ? 'selected' : ''}`} onClick={abstencao} disabled={statusAbstencao || statusConsenso || disabled}>{(statusAbstencao) && <i className="icon icon-check i-15 mr-5" />} QUERO ME ABSTER DA ESCOLHA</button>
            </>}
            <br />

            <p>Se dentro do prazo não recebermos sua sinalização de escolha ou recusa  de uma das opções acima, nós decidiremos por um dos profissionais listados para dar andamento à análise do caso.</p>

            <p className="underline"><b>Observações importantes:</b></p>

            <p>- Informamos que o beneficiário ou o médico assistente deverão apresentar os documentos e exames que fundamentaram a solicitação do procedimento.</p>

            <p>- O processo de Junta Médica ou Odontológica será  iniciado na modalidade à distância. Se houver a necessidade de realização de uma Junta Médica ou Odontológica presencial, que será definida pelo(a) profissional desempatador(a), e o paciente se ausentar, sem comunicar a impossibilidade de participação com antecedência,  a {resp.operadora_nome} passa a ser desobrigada de cobrir as solicitações em questão. </p>

            <p>Em caso de dúvidas, retorne este e-mail ou as envie pelo WhatsApp (48) 99201-4736.</p>

            <br />
            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>Dr. (a) {resp.auditor_nome}, CRM (ou CRO): {resp.auditor_crmcro} - {resp.auditor_uf}.</p>
                <p>Dados para contato: {resp.operadora_telefone}</p>
            </div>
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de envio de comunicação ao profissional assistente via {notify?.canal}: {notify?.canalPA} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            <br />
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação por profissional assistente via {notify?.canal}: {notify?.canalPA} - {notify?.data_leitura} - IP: {notify?.ip} / {notify?.navegador} / {notify?.sistema_operacional} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}
