import moment from "moment/moment.js";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import config from "../../../config/config.js";
import tools from "../../../lib/tools.js";
import business from "../../../services/business.js";
import InputForm from "../../Form/InputForm.js";
import Loading from "../../Loading.js";
import { useCallback } from "react";
import Pusher from 'pusher-js';

export default function Chat({ id }) {

    const user = config.user;

    const [comunicacao, setComunicacao] = useState([]);
    const [comentario, setComentario] = useState({
        id: '', id_usuario: '', texto: '', usuario_nome: '', data_criacao: ''
    });
    const [loading, setLoading] = useState(false);

    const enviarMensagem = async (e) => {

        e.preventDefault();

        // disable send
        if (!comentario?.texto || !comentario?.texto.length) {
            return false;
        }

        setLoading(true);

        let data = {
            id: comunicacao.length + 1,
            id_usuario: parseInt(user.id),
            usuario_nome: user.nome,
            id_processo: id,
            texto: comentario.texto,
            data_criacao: tools.getDatetime()
        };

        comunicacao.push(data);

        setComunicacao(comunicacao);
        scrollToButtom();

        const { response } = await business.addComunicacao({
            id_processo: data.id_processo,
            texto: data.texto
        });

        setLoading(false);

        if (response?.id) {
            setModel('');
        }

    }

    const scrollToButtom = () => {
        setTimeout(() => {
            if (document.getElementsByClassName('aba-comunicacao').length)
                document.getElementsByClassName('aba-comunicacao')[0].scrollTo(0, document.getElementsByClassName('aba-comunicacao')[0].scrollHeight);
        }, 100);
    }

    const setModel = (name, value) => {
        setComentario({ ...comentario, ...{ texto: value } });
    }

    const getComunicacao = useCallback(async () => {
        setLoading(true);
        let data = await business.getComunicacaoPorProcesso(id);
        setComunicacao(data || [])
        setLoading(false);
        scrollToButtom();
    }, [id]);

    useEffect(() => {
        getComunicacao();
    }, [getComunicacao]);

    useEffect(() => {

        const pusher = new Pusher('cc3ec1a7305f21c3275e', {
            cluster: 'sa1',
            encrypted: true
        });

        console.log(`Push: assinando o canal "saa-processo-${id}"`)

        const channel_history = pusher.subscribe(`saa-processo-${id}`);

        channel_history.bind('chat', function (data) {
            const { id, id_processo, mensagem, id_usuario, data_criacao, data_atualizacao, nome_usuario } = JSON.parse(data.message);
            if (config.user?.id && parseInt(config.user.id) !== parseInt(id_usuario)) {
                console.log(`Push: notificando no evento "saa-processo-${id}:chat"`)
                setComunicacao(prevData => ([
                    ...prevData, {
                        id: id,
                        data_atualizacao: data_atualizacao,
                        data_criacao: data_criacao,
                        id_usuario: id_usuario,
                        id_processo: id_processo,
                        texto: mensagem,
                        usuario_nome: nome_usuario
                    }
                ]));
            }
        });
    }, [id]);

    return (
        <div className="overflow-y scrollfit h-100 aba-comunicacao">
            <h1 className="f600-18 ml-30 mt-20 mb-40">Chat</h1>
            <div className="list-message">
                <div className={(comunicacao && comunicacao?.length) ? "mh-30 grid col-3 w-100" : "mh-30 grid w-100"}>
                    {(comunicacao && comunicacao?.length) ? (comunicacao || []).map((comunicacao) => {
                        return <div key={comunicacao.id} className={`f400-16 mb-20 p-20 message ${parseInt(comunicacao.id_usuario) === parseInt(user.id) ? 'message-my' : 'message'}`}>
                            <div className="flex fd-fc">
                                <span className="f400-14 mb-10">{comunicacao.texto}</span>
                                <span className="f400-12 sender"> <b>{comunicacao.usuario_nome}</b> - {moment(comunicacao.data_criacao, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm')} hrs </span>
                            </div>
                        </div>
                    }) : 'Não há comentários no momento.'}
                </div>
            </div>
            <div className="form-message">
                <div className="mh-30 grid col-12 w-100">
                    <div className="span-11 send-message">
                        <div className="flex gap-20">
                            <div className="send-text">
                                <InputForm type="text" name="comentario" value={comentario.texto} disabled={loading} model={setModel} placeholder="Envie uma mensagem" onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        enviarMensagem(e);
                                    }
                                }} />
                            </div>
                            <div>
                                <button className="btn btn-primary f700-16 send-button" onClick={enviarMensagem} disabled={!comentario?.texto || loading} >
                                    {(loading) ? <Loading /> : <i className="icon icon-paper-plane blue i-20" />}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
