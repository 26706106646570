//import Cep from "cep-promise";
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import business from '../../services/business.js';
import InputForm from "../Form/InputForm.js";
import Loading from '../Loading.js';
import Modal from '../Modal';


export default function ModalResponsaveis({ hideModal, show, processo = {} }) {


    const [responsaveis, setResponsaveis] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async (loading = true) => {
            setLoading(loading);
            let response = await business.getResponsaveis(processo?.id);
            setResponsaveis(response)
            setLoading(false);
        })();
    }, [processo?.id]);

    return (
        <Modal show={show} handleClose={hideModal} style={{ maxWidth: '600px', maxHeight: '600px' }} modalClass="modal-cad-usuario">
            <div className="flex">
                <div className="form w-100 mb-50">
                    <h1 className="title mb-45">Responsáveis</h1>
                    {loading ? (<Loading />) : (<div>
                    <div className="group-input f400-18 pr-40">
                        <InputForm className="mb-30" value={responsaveis?.nome_assistente ? responsaveis?.nome_assistente : 'Não definido'} name="assistente" label="Assistente" disabled />
                    </div>
                    <div className="group-input f400-18 pr-40">
                        <InputForm className="mb-30" value={responsaveis?.nome_enfermeira ? responsaveis?.nome_enfermeira : 'Não definido'} name="enfermeira" label="Enfermeira" disabled />
                    </div>
                    <div className="group-input f400-18 pr-40">
                        <InputForm className="mb-30" value={responsaveis?.nome_desempatador ? responsaveis?.nome_desempatador : 'Não definido'} name="assistente" label="Especialista/Desempatador" disabled />
                    </div>
                    </div>)}
                    {/* <div className="form-rodape mt-30 ">
                        <button className="btn btn-outline-primary f700-16" onClick={hideModal} >
                            Cancelar
                        </button>
                        <button className="btn btn-primary f700-16 mr-40" >
                            Salvar
                        </button>
                    </div> */}
                </div>

            </div>
        </Modal>
    );
}
