import React from 'react';
export default function NaoAutorizado() {

    return (

        <div className="flex">
            <div className="form w-100 mb-50 text-center">
                <h1 className="title mb-45">Desculpe :(</h1>
                <div>Este processo já avançou, você não possui mais acesso a ele.</div>
                <div className="w-100 text-center pt-50">
                    <button className="btn btn-primary f700-16 w-20 nao-autorizado"><a href='/'>Voltar à página inicial</a></button>
                </div>
            </div>

        </div>
    );
}
