//import Cep from "cep-promise";
import React from 'react';
import Email from '../Email';

export default function ParOpiniaoEspecializada({ resp = {}, notify = false, canal, print = false }) {

    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
            <div className="flex">
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {resp.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {resp.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {resp.operadora_telefone}</p>
                </div>
                <img className="image-ops" src={resp?.operadora_imagem} alt='Operadora'></img>
            </div>
            <div className="w-100">
                <div className="text-right mb-10">{notify.data_format ? notify.data_format : resp.data_disparo}</div>
            </div>
            {/*    <div className="w-100">
                <p className="text-left mt-30 mb-30 fb-18">SOLICITAÇÃO DA OPERADORA:</p>
            </div>
            <hr style={{ border: '1px solid black' }} />
            <p className="mb-10">[solicitaçao da operadora]</p>
            <hr style={{ border: '1px solid black' }} />
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">PARECER OPINIÃO ESPECIALIZADA</p>
            </div>
            <p><b>1.	Histórico do beneficiário (a): </b></p>
            <p>[historico do beneficiário]</p>
            <br /> */}
            <p><b>2.	Análise dos Procedimentos e/ou exames: </b></p>
            <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">ANÁLISE DO PROFISSIONAL ESPECIALISTA</p>
            </div>
            {resp.procedimentos ?
                <div className="w-100 grid">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '15%' }}>Código</th>
                                <th style={{ width: '45%' }}>Descrição</th>
                                <th style={{ width: '20%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '20%' }}>Quantidade Pós Análise</th>
                            </tr>
                        </tbody>
                        {resp.procedimentos.map(procedimento => {
                            return <tbody key={procedimento.id}>
                                <tr>
                                    <td>{procedimento.codigo}</td>
                                    <td>{procedimento.descricao}</td>
                                    <td>{procedimento.quantidade_solicitada}</td>
                                    <td>{procedimento.quantidade_autorizada}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{procedimento.justificativa}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}
            <p className="mt-30"><b>3.	Análise dos Materiais e/ou medicamentos: </b></p>
            <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">ANÁLISE DO PROFISSIONAL ESPECIALISTA</p>
            </div>
            {resp.materiais ?
                <div className="w-100 grid">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '60%' }}>Descrição</th>
                                <th style={{ width: '20%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '20%' }}>Quantidade Pós Análise</th>
                            </tr>
                        </tbody>
                        {resp.materiais.map(material => {
                            return <tbody key={material.id}>
                                <tr>
                                    <td>{material.descricao}</td>
                                    <td>{material.quantidade_solicitada}</td>
                                    <td>{material.quantidade_autorizada}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{material.justificativa}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}
            <br />
            <br />
            <p><b>4.	Sugestão de outros tratamentos: </b></p>
            <p>[sugestao de tratamento]</p>
            <br />
            <p><b>5.	Conclusão:  </b></p>
            <p>{resp.desempatador_responsavel_laudo}</p>
            <br />
            <br />
            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>Dr. (a) {resp.desempatador_responsavel_nome}, CRM (ou CRO): {resp.desempatador_responsavel_crmcro} - {resp.desempatador_responsavel_uf}.</p>
                <p>Dados para contato: (48) 99201-4736</p>
            </div>
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de envio de comunicação a operadora de saúde via {notify?.canal}: {notify?.canalOPS} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            <br />
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação a operadora de saúde via {notify?.canal}: {notify?.canalOPS} - {notify?.data_leitura} - IP: {notify?.ip} / {notify?.navegador} / {notify?.sistema_operacional} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}
