//import Cep from "cep-promise";
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import business from '../../services/business';
import Loading from '../Loading';
import Modal from '../Modal';
import config from '../../config/config';

export default function ModalConvidados({ hideModal, show, processo = {} }) {

    const [convidados, setConvidados] = useState([]);
    const [loading, setLoading] = useState(false);
    const [change, setChange] = useState(false);

    useEffect(() => {
        (async (loading = true) => {
            setLoading(loading);
            let response = await business.getConvidados(processo?.id, {});
            setConvidados(response)
            setLoading(false);
        })();
    }, [processo?.id, change]);

    const aceiteManual = async (id, nome) => {
        if (!window.confirm(`Você deseja aceitar o convite de ${nome}?`)) {
            return false;
        }
        await business.aceitaConviteManual(id);
        setChange(!change);
    }

    const recusaManual = async (id, nome) => {
        if (!window.confirm(`Você deseja recusar o convite de ${nome}?`)) {
            return false;
        }
        await business.recusaConviteManual(id);
        setChange(!change);
    }

    return (
        <Modal show={show} handleClose={hideModal} style={{ maxWidth: '1200px', maxHeight: '600px' }} modalClass="modal-cad-usuario">
            <div className="flex">
                <div className="form w-100 mb-50">
                    <h1 className="title mb-45">Desempatadores convidados</h1>
                    {loading ? (<Loading />) : (<div>
                        {(!convidados ? 'Não há desempatadores convidados à participar deste processo.' :
                            <div className="grid col-12 fb-16 p-10">
                                <div className="span-4">Nome completo</div>
                                <div className="span-2">Canal</div>
                                <div className="span-4">Contato</div>
                                <div className="span-1">Status</div>
                                {(parseInt(config.user.tipo) === 1) && <div className="span-1 text-right">Ações</div>}
                            </div>)}
                        {(convidados || []).map((convidados, i) => {
                            return <div key={i}>
                                {convidados.id_notificacao_canal >= 1 &&
                                    <div className={`grid col-12 convidados ${convidados.id_notificacao_envio_status === '4' && 'aceito'} ${convidados.id_notificacao_envio_status === '5' && 'recusado'}`} style={{ borderBottom: '1px solid gray' }}>
                                        <div className="span-4">{convidados.nome_desempatador}</div>
                                        <div className="span-2">{parseInt(convidados.id_notificacao_canal) === 2 && 'E-mail'}{parseInt(convidados.id_notificacao_canal) === 3 && 'Whatsapp'}</div>
                                        <div className="span-4">{parseInt(convidados.id_notificacao_canal) === 2 && convidados.email_desempatador}{parseInt(convidados.id_notificacao_canal) === 3 && convidados.telefone_desempatador.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3")}</div>
                                        <div className="span-1">{parseInt(convidados.id_notificacao_envio_status) === 1 && 'Na fila'}
                                            {parseInt(convidados.id_notificacao_envio_status) === 2 && 'Enviado'}
                                            {parseInt(convidados.id_notificacao_envio_status) === 3 && 'Visualizado'}
                                            {parseInt(convidados.id_notificacao_envio_status) === 4 && 'Confirmado'}
                                            {parseInt(convidados.id_notificacao_envio_status) === 5 && 'Recusado'}
                                            {parseInt(convidados.id_notificacao_envio_status) === 6 && 'Falha'}
                                            {parseInt(convidados.id_notificacao_envio_status) === 11 && 'Cancelado'}
                                        </div>
                                        {(parseInt(config.user.tipo) === 1) && <div lassName="span-1 text-center" style={{ display: 'flex', textAlign: 'center', justifyContent: 'flex-end' }}>
                                            <i class="icon icon-check i-15 pointer ml-10 mr-10" title="Aceitar manualmente" onClick={() => aceiteManual(convidados.notificacao_envio_id, convidados.nome_desempatador)}></i>
                                            <i class="icon icon-cross-small i-15 pointer" title="Recusar manualmente" onClick={() => recusaManual(convidados.notificacao_envio_id, convidados.nome_desempatador)}></i>
                                        </div>}
                                    </div>}
                            </div>
                        })}</div>)}
                </div>

            </div>
        </Modal>
    );
}
