import React, { useState } from "react";
import Layout from "../../components/Layout.js";
import '../../assets/styles/pages/Ajuda/FAQ.scss'
import config from "../../config/config.js";
export default function FAQ() {
    document.title = 'säästo';
    const [itens] = useState([
        {
            id: 1,
            thumb: '0',
            url: 'https://saasto.s3.sa-east-1.amazonaws.com/videos/05.07+-+TUTORIAL+PLATAFORMA+-+V%C3%ADdeo+3.mp4',
            title: 'Tutorial de uso do plataforma',
            resum: 'Entregamos economia a operadoras de saúde e tratamentos adequados aos seus beneficiários, com imparcialidade e comprometimento.', onClick: 'openModal',
            operadora: false
        },
        {
            id: 2,
            thumb: '1',
            url: 'https://saasto.s3.sa-east-1.amazonaws.com/videos/X2Download.app-Consenso+ou+Intermedia%C3%A7%C3%A3o+com+o+Profissional+Assistente-(1080p).mp4',
            title: 'Consenso ou Intermediação com o Profissional Assistente',
            resum: 'Como ocorre a tentativa de consenso com o Profissional Assistente e quais os principais benefícios desse processo.',
            onClick: 'openModal',
            operadora: true
        },
        {
            id: 3,
            thumb: '2',
            url: 'https://saasto.s3.sa-east-1.amazonaws.com/videos/X2Download.app-Segunda+Opini%C3%A3o+ou+Opini%C3%A3o+Especializada-(1080p).mp4',
            title: 'Segunda Opinião ou Opinião Especializada',
            resum: 'Como funciona e qual a importância da segunda opinião médica ou odontológica.', onClick: 'openModal',
            operadora: true
        },
        {
            id: 4,
            thumb: '2',
            url: 'https://saasto.s3.sa-east-1.amazonaws.com/videos/X2Download.app-Servi%C3%A7os+_+Segunda+Opini%C3%A3o%2C+Consenso+e+Junta+M%C3%A9dica+ou+Odontol%C3%B3gica-(1080p).mp4',
            title: 'Segunda Opinião, Consenso e Junta Médica ou Odontológica',
            resum: 'Conheça as diferenças entre os serviços de segunda opinião, consenso e junta médica ou odontológica da säästö.', onClick: 'openModal',
            operadora: true
        },
        {
            id: 5,
            thumb: '1',
            url: 'https://saasto.s3.sa-east-1.amazonaws.com/videos/Introdu%C3%A7%C3%A3o+%C3%A0+RN+424_17.mp4',
            title: 'Introdução à RN 424/17',
            resum: 'Informações iniciais sobre o processo de Junta médica ou Odontológica.', onClick: 'openModal',
            operadora: false
        },
        {
            id: 6,
            thumb: '2',
            url: 'https://saasto.s3.sa-east-1.amazonaws.com/videos/Pontos+importantes+sobre+o+processo+de+JMO.mp4',
            title: 'Pontos importantes sobre o processo de JMO',
            resum: 'Mais informações sobre a Junta médica ou Odontológica: como acontece o processo.', onClick: 'openModal',
            operadora: false
        },
        {
            id: 7,
            thumb: '1',
            url: 'https://saasto.s3.sa-east-1.amazonaws.com/videos/Justificativas+nas+an%C3%A1lises+de+JMO.mp4',
            title: 'Justificativas nas análises de JMO',
            resum: 'Orientações sobre como devem ser as justificativas nas análises das divergências apresentadas.', onClick: 'openModal',
            operadora: false
        },
        /* {
            id: 5,
            thumb: '3',
            url: 'https://saasto.s3.sa-east-1.amazonaws.com/videos/X2Download.app-Dia+Internacional+da+Enfermagem-(1080p).mp4',
            title: 'Dia Internacional da Enfermagem',
            resum: 'Parabéns, profissionais de Enfermagem, vocês são um exemplo de carinho, entrega e dedicação! 💙', onClick: 'openModal'
        } */
    ]);

    const [itemSelecionado, setItemSelecionado] = useState(null);

    const handleClickItem = (itemId) => {
        const item = itens.find(item => item.id === itemId);
        setItemSelecionado(item);
    };

    return (
        <Layout className="page">
            <section>
                <div className="faq-main flex">
                    <div className="form w-100 mb-50">
                        <h1 className="title mb-45">FAQ - Perguntas frequentes</h1>
                        <div className="form-container grid col-12">
                            <div className="span-7 w-100">
                                {itemSelecionado ? (
                                    <div className="video">
                                        <video controls src={itemSelecionado.url} type="video/mp4">
                                        </video>
                                        <h1 className="fb-20 pt-20">{itemSelecionado.title}</h1>
                                        <p className="pt-10">{itemSelecionado.resum}</p>
                                    </div>) : ((config.user.tipo === '2' || config.user.tipo === '3') ?
                                        <div className="video">
                                            <video type="video/mp4" src={itens[1].url} controls></video>
                                            <h1 className="fb-20 pt-20">{itens[1].title}</h1>
                                            <p className="pt-10">{itens[1].resum}</p>
                                        </div> : <div className="video">
                                            <video type="video/mp4" src={itens[0].url} controls></video>
                                            <h1 className="fb-20 pt-20">{itens[0].title}</h1>
                                            <p className="pt-10">{itens[0].resum}</p>
                                        </div>)}
                                <hr className="divisor" />
                                <div className="relacionados">
                                    <h1 className="fb-24 pb-20">Manuais:</h1>
                                    <div className="grid colspan-2 flex sm-gap">
                                        {[1, 6].includes(parseInt(config.user.tipo)) &&
                                            <a href="/ajuda/desempatador-manual-tecnico" target="_blank"><div className="card pt-10 text-center" >
                                                <i className="icon icon-dossie blue i-30" />
                                                <span className="pt-5">Manual técnico <br /></span>
                                            </div></a>}
                                        {[1, 6].includes(parseInt(config.user.tipo)) &&
                                            <a href="/ajuda/desempatador-manual-utilizacao" target="_blank"><div className="card pt-10 text-center" >
                                                <i className="icon icon-dossie blue i-30" />
                                                <span className="pt-5">Manual de utilização da plataforma</span>
                                            </div></a>}
                                        {[2, 3].includes(parseInt(config.user.tipo)) &&
                                            <a href="/ajuda/operadora-manual-utilizacao" target="_blank"><div className="card pt-10 text-center" >
                                                <i className="icon icon-dossie blue i-30" />
                                                <span className="pt-5">Manual de utilização da plataforma</span>
                                            </div></a>}
                                        {[1, 6].includes(parseInt(config.user.tipo)) &&
                                            <a href="/ajuda/desempatador-manual-instrucoes" target="_blank"><div className="card pt-10 text-center" >
                                                <i className="icon icon-dossie blue i-30" />
                                                <span className="pt-5">Instruções de uso da plataforma</span>
                                            </div></a>
                                        }
                                        {[1, 4, 5, 7, 8].includes(parseInt(config.user.tipo)) &&
                                            <a href="/ajuda/documentos-operacionais" target="_blank"><div className="card pt-10 text-center" >
                                                <i className="icon icon-dossie blue i-30" />
                                                <span className="pt-5">Documentos operacionais</span>
                                            </div></a>}
                                        {[2, 3].includes(parseInt(config.user.tipo)) &&
                                            <a href="/ajuda/operadora-etapas-status" target="_blank"><div className="card pt-10 text-center" >
                                                <i className="icon icon-dossie blue i-30" />
                                                <span className="pt-5">Etapas e Status - Fluxo</span>
                                            </div></a>}
                                    </div>
                                </div>
                            </div>
                            <div className="span-5 w-100">
                                <div className="listagem">
                                    <h1 className="fb-24 pb-20">Trilhas</h1>
                                    <ul>
                                        {itens.map(item => (
                                            <div>{(config.user.tipo === '2' || config.user.tipo === '3') && !item.operadora ? '' :
                                                <li key={item.id} onClick={() => handleClickItem(item.id)} className={itemSelecionado && itemSelecionado.id === item.id ? 'selecionado flex' : 'flex'}>
                                                    <i className={`thumb id${item?.thumb}`} /> <span>{item.title}</span>
                                                </li>}
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

            </section>
        </Layout >);
}

