import React, {useState} from 'react';
import Header from "./Header/Header.js";
import MenuLateral from "./MenuLateral";
import '../assets/styles/components/Layout.scss'
import layout from '../lib/layout.js';

export default function Layout({children, id = '', className = '', style = {}}) {
  const [open, setOpen] = useState(layout.openMenu);

  return (
    <div id={id} className={`layout ${className}`} style={style}>
      <MenuLateral open={open} openMenu={setOpen} />
      <div className={`layout-body ${(open) ? 'menu-open' : ''}`}>
      {((window.location.href.match(/\/qas\./i)) || (window.location.href.match(/\/localhost[:]/i))) ? <div className="test-environment">Atenção: Você está em um ambiente de testes.</div>:''}
        <Header openMenu={() => setOpen(!open)} />
        <main id="container-main">
          {children}
        </main>
      </div>
    </div>
  );
};

