import { destroyCookie, parseCookies } from 'nookies';
import tools from '../lib/tools';

export const isAuthenticated = function () {
  let user = JSON.parse(localStorage.getItem('user') || '{}');
  return (parseCookies(null)?.token && user?.nome) ? true : false;
}

export const getToken = function () {
  return parseCookies()?.token || null;
}

export const logout = async () => {
  destroyCookie({}, 'token');
  localStorage.clear();
  return tools.openURL('/login');
};
