//import Cep from "cep-promise";
import React from 'react';
import Email from '../Email';

export default function ComEscolhaAbstencaoPA({ resp = {}, notify = false, print = false, viewHeader = false }) {

    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
            {viewHeader && <div className="w-100 mb-20">
                <p className="mb-0"><b>De:</b> {notify?.disparo}</p>
                <p className="mb-0"><b>Para:</b> {notify?.canalPA}</p>
                <p className="mb-0"><b>Assunto:</b> COMUNICADO ESCOLHA PROFISSIONAL DESEMPATADOR</p>
                <p className="mb-0"><b>Data:</b> {notify?.data_disparo}</p>
                <hr />
            </div>}
            <div className="flex">
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {resp.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {resp.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {resp.operadora_telefone}</p>
                </div>
                <img className="image-ops" src={resp?.operadora_imagem} alt='Operadora'></img>
            </div>
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">TERMO DE ABSTENÇÃO DE ESCOLHA</p>
            </div>
            {/* <div className="w-100">
                <div className="text-right mb-10">{resp.data_disparo}</div>
            </div> */}
            <br />
            <p>Eu Dr.(a) <b>{resp.profissional_nome}, CRM (ou CRO): {resp.profissional_crmcro} - {resp.profissional_uf}</b> informo que me abstenho da escolha para profissional desempatador(a) que decidirá sobre a(s) divergência(s) da solicitação de procedimentos, exames e/ou materiais, medicamentos feita em {resp.data_atendimento} para o(a) beneficiário(a)  <b>{resp.beneficiario_nome}</b>, guia <b>{resp.beneficiario_guia}</b>, ID <b>{resp.protocolo}</b>. </p>
            <br />

            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>{resp.profissional_nome}, CRM (ou CRO): {resp.profissional_crmcro} - {resp.profissional_uf}.</p>
            </div>
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de envio de comunicação ao profissional assistente via {notify?.canal}: {notify?.canalPA} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            <br/>
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação por profissional assistente via {notify?.canal}: {notify?.canalPA} - {notify?.data_leitura} - IP: {notify?.ip} / {notify?.navegador} / {notify?.sistema_operacional} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}
