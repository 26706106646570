//import Cep from "cep-promise";
import React from 'react';
import Email from '../Email';

export default function ComConsensoTecnicoOPS({ resp = {}, notify = false, print = false, viewHeader = false }) {

    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
            {viewHeader && <div className="w-100 mb-20">
                <p className="mb-0"><b>De:</b> {notify?.disparo}</p>
                <p className="mb-0"><b>Para:</b> {notify?.canalOPS}</p>
                <p className="mb-0"><b>Assunto:</b> COMUNICADO DE CONSENSO TÉCNICO</p>
                <p className="mb-0"><b>Data:</b> {notify?.data_disparo}</p>
                <hr />
            </div>}
            <div className="flex">
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {resp.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {resp.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {resp.operadora_telefone}</p>
                </div>
                <img className="image-ops" src={resp?.operadora_imagem} alt='Operadora'></img>
            </div>
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">COMUNICADO DE CONSENSO TÉCNICO</p>
            </div>
            <div className="w-100">
                <div className="text-right mb-10">{notify.data_format ? notify.data_format : resp.data_disparo}</div>
            </div>
            <br />
            <p>Informamos que, após análise da solicitação de procedimentos, exames, materiais e/ou medicamentos feita em {resp.data_atendimento} para o(a) beneficiário (a) <b>{resp.beneficiario_nome}</b>, guia <b>{resp.beneficiario_guia}</b>, ID <b>{resp.protocolo}</b>,
                houve consenso técnico com o(a) Dr.(a) <b>{resp.profissional_nome}</b>, CRM (ou CRO): <b>{resp.profissional_crmcro}</b> - <b>{resp.profissional_uf}</b> para as seguintes divergências:</p>

            <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">REGISTRO DE CONSENSO | PROCEDIMENTOS</p>
            </div>
            {resp.procedimentos ?
                <div className="w-100 grid mail-visible-desktop">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '15%' }}>Código</th>
                                <th style={{ width: '45%' }}>Descrição</th>
                                <th style={{ width: '20%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '20%' }}>Quantidade Pós Consenso</th>
                            </tr>
                        </tbody>
                        {resp.procedimentos.map(procedimento => {
                            return <tbody key={procedimento.id}>
                                <tr>
                                    <td>{procedimento.codigo}</td>
                                    <td>{procedimento.descricao}</td>
                                    <td>{procedimento.quantidade_solicitada}</td>
                                    <td>{(procedimento.quantidade_pos_analise === "0" || procedimento.quantidade_pos_analise === 0) ? procedimento.quantidade_autorizada : procedimento.quantidade_pos_analise}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{[11, 17].includes(procedimento.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : (procedimento.justificativa_pos_analise !== "-" ? procedimento.justificativa_pos_analise : procedimento.justificativa)}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}

            <div className="mail-visible-mobile nao-imprimir">
                {(resp.procedimentos && !print) ? resp.procedimentos.map((procedimento, i) => {
                    i++
                    return <div key={procedimento.id}>
                        <ul className="mt-20">
                            <li className="mail-li">Item {i}:</li>
                            <li className="mail-li">Código: <b>{procedimento.codigo}</b></li>
                            <li className="mail-li">Descrição:  <b>{procedimento.descricao}</b></li>
                            <li className="mail-li">Quantidade Solicitada:  <b>{procedimento.quantidade_solicitada}</b></li>
                            <li className="mail-li">Quantidade Pós Consenso:  <b>{(procedimento.quantidade_pos_analise === "0" || procedimento.quantidade_pos_analise === 0) ? procedimento.quantidade_autorizada : procedimento.quantidade_pos_analise}</b></li>
                            <li className="mail-li">Justificativa:  <b>{[11, 17].includes(procedimento.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : (procedimento.justificativa_pos_analise !== "-" ? procedimento.justificativa_pos_analise : procedimento.justificativa)}</b></li>
                        </ul>
                    </div>
                }) : ''}
            </div>

            {resp.materiais.length ? <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">REGISTRO DE CONSENSO | MATERIAIS</p>
            </div> : ''}
            {resp.materiais.length ?
                <div className="w-100 grid mail-visible-desktop">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '60%' }}>Descrição</th>
                                <th style={{ width: '20%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '20%' }}>Quantidade Pós Consenso</th>
                            </tr>
                        </tbody>
                        {resp.materiais.map(material => {
                            return <tbody key={material.id}>
                                <tr>
                                    <td>{material.descricao}</td>
                                    <td>{material.quantidade_solicitada}</td>
                                    <td>{(material.quantidade_pos_analise === "0" || material.quantidade_pos_analise === 0) ? material.quantidade_autorizada : material.quantidade_pos_analise}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{[21, 28].includes(material.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : (material.justificativa_pos_analise !== "-" ? material.justificativa_pos_analise : material.justificativa)}</td>
                                </tr>
                            </tbody>

                        })}
                    </table></div> : ''}

            <div className="mail-visible-mobile nao-imprimir">
                {(resp.materiais && !print) ? resp.materiais.map((materiais, i) => {
                    i++
                    return <div key={materiais.id}>
                        <ul className="mt-20">
                            <li className="mail-li">Item {i}:</li>
                            <li className="mail-li">Descrição:  <b>{materiais.descricao}</b></li>
                            <li className="mail-li">Quantidade Solicitada:  <b>{materiais.quantidade_solicitada}</b></li>
                            <li className="mail-li">Quantidade Pós Consenso:  <b>{(materiais.quantidade_pos_analise === "0" || materiais.quantidade_pos_analise === 0) ? materiais.quantidade_autorizada : materiais.quantidade_pos_analise}</b></li>
                            <li className="mail-li">Justificativa:  <b>{[21, 28].includes(materiais.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : (materiais.justificativa_pos_analise !== "-" ? materiais.justificativa_pos_analise : materiais.justificativa)}</b></li>
                        </ul>
                    </div>
                }) : ''}
            </div>
            <br />
            <p>Em caso de dúvidas, retorne este e-mail ou fale conosco pelo WhatsApp (48) 99201-4736.</p>
            <br />
            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>Dr. (a) {resp.auditor_nome}, CRM (ou CRO): {resp.auditor_crmcro} - {resp.auditor_uf}.</p>
                <p>Dados para contato: {resp.operadora_telefone}</p>
            </div>
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de envio da comunicação via {notify?.canal}: {notify?.canalOPS} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            <br />
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação via {notify?.canal}: {notify?.canalOPS} - {notify?.data_leitura} - IP: {notify?.ip} / {notify?.navegador} / {notify?.sistema_operacional} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}
