import React, { useRef, useState } from "react";
import Business from "../../../services/business.js";
import business from "../../../services/business.js";
import InputForm from "../../Form/InputForm.js";
import Cep from 'cep-promise'
import tools from "../../../lib/tools.js";
import config from "../../../config/config.js";
import Loading from "../../Loading.js";

export default function DadosGerais({ salvar, form, validacao = {}, setModel, options = {}, modoEdicao = true, permitirEdicao = false, startDate = null, expireDate = null, ansDate = null, className = "mt-60", updateStatus = false }) {

  const [exibicao, setExibicao] = useState(modoEdicao);
  const [loadId, setLoadId] = useState(null);

  const cancel = () => {
    setExibicao(!exibicao)
  }

  const searchOperadora = async (e) => {
    return await business.getOperadora(e.target.value);
  }

  const searchAreaAtuacao = async (e) => {
    return await business.getAreaAtuacao(e.target.value, 12, 1, form.id_especialidade);
  }

  const searchEspecialidades = async (e) => {
    return await business.getEspecialidades(e.target.value, 5, 1, form.id_area_atuacao);
  }

  const getCep = async (e) => {
    if (!form.beneficiario.cep) return
    Cep(form.beneficiario.cep)
      .then((cep) => {
        setModel('beneficiario', { ...form.beneficiario, ...{ cidade: cep.city, rua: cep.street, bairro: cep.neighborhood, uf: cep.state, cep: cep.cep } });
        let input = document.getElementsByName("beneficiario.numero")
        if (input.length) input[0].focus();
      })
  }
  const validCpf = async (key) => {
    let cpf = form.beneficiario.cpf;
    let { valido, texto } = tools.validarCPF(cpf);
    validacao['beneficiario.cpf'] = texto;
    setLoadId(tools.uuidv4());
    return valido;
  }

  const getCpf = async () => {
    if (validCpf() && !form.beneficiario?.nome) {
      const { response } = await Business.getCpf(form.beneficiario.cpf);
      if (response) {
        setModel('beneficiario', response);
      }
    }
  }

  const getCrm = async (e) => {
    let crm = e.target.value;
    let { valido, texto } = tools.validarTamanho(crm, 3);
    if (valido && !form.profissional?.nome) {
      const { response } = await Business.getCrm(crm, form.profissional.uf);
      if (response) {
        setModel('profissional', response);
      }
    }
    validacao['profissional.crm'] = texto;
  }

  const getCepProf = async (e) => {
    if (!form.profissional.cep) return
    Cep(form.profissional.cep)
      .then((cep) => {
        setModel('profissional', { ...form.profissional, ...{ cidade: cep.city, rua: cep.street, bairro: cep.neighborhood, endereco_uf: cep.state, cep: cep.cep } });
        let input = document.getElementsByName("profissional.numero")
        if (input.length) input[0].focus();
      })
  }

  const getCrmAuditor = async (e) => {
    let crm = e.target.value;
    let { valido, texto } = tools.validarTamanho(crm, 3);
    if (valido && !form.auditor?.nome) {
      const { response } = await Business.getCrm(crm, form.auditor.uf);
      if (response) {
        setModel('auditor', response);
      }
    }
    validacao['auditor.crm'] = texto;
  }

  const setSearch = (name, data = {}) => {
    let field = {
      [`id_${name}`]: data?.id || null,
      [`desc_${name}`]: data?.nome || data?.desc || ''
    };
    setModel('form', field);
  }

  const areaAtuacaoRef = useRef();

  return (<div className={className} id={loadId}>
    {permitirEdicao && <h2 className="f600-18 mb-30 flex jc-sb ai-fc">
      Dados Gerais
      {exibicao && <i className="icon icon-edit i-20 pointer" onClick={() => setExibicao(!exibicao)} />}
    </h2>}
    {!permitirEdicao && <h2 className="f600-22 mb-60">Dados Gerais</h2>}

    <div className={`form-container grid col-${modoEdicao ? 12 : 12} w-100`}>
      <InputForm label="Nome da operadora" type="search" required  {...((!config.isAdmin() && parseInt(config.user.tipo) !== 4 && parseInt(config.user.tipo) !== 5 && parseInt(config.user.tipo) !== 8) ? { disabled: true } : {})} className="span-2" onSearch={searchOperadora} name="id_operadora" model={(...params) => setSearch('operadora', ...params)}
        exibicao={exibicao} value={form.desc_operadora} error={validacao.id_operadora} />
      <InputForm label="Tipo de processo" required type="select" disabled={form?.id} options={options.tiposProcesso} className="span-3" value={form.id_processo_tipo} name="id_processo_tipo" model={setModel}
        exibicao={exibicao} error={validacao.id_processo_tipo} />
      <InputForm label="Tipo de atendimento" required type="select" disabled={form?.id} options={options.tiposAtendimento} className="span-4" value={form.id_processo_tipo_atendimento} name="id_processo_tipo_atendimento" model={setModel}
        exibicao={exibicao} error={validacao.id_processo_tipo_atendimento} />


      <InputForm label="Especialidade" required type="search" placeholder="Pesquisar..." className="span-3" onSearch={searchEspecialidades} name="id_especialidade" model={(...params) => setSearch('especialidade', ...params)}
        exibicao={exibicao} onResetSearch={() => areaAtuacaoRef.current.clearSearch()} value={form.desc_especialidade} error={validacao.id_especialidade} disabled={parseInt(form.id_processo_etapa) > 2 && form.id_processo_etapa !== '6' && form.id_processo_etapa !== '12'}
      />
      <InputForm label="Área de atuação" onChange={() => { }} ref={areaAtuacaoRef} required type="search" placeholder={(form.id_especialidade) ? "Pesquisar..." : "Selecione a especialidade"} className="span-4" onSearch={searchAreaAtuacao} name="id_area_atuacao" model={(...params) => setSearch('area_atuacao', ...params)}
        exibicao={exibicao} value={(form.id_especialidade) ? form.desc_area_atuacao : ''} error={validacao.id_area_atuacao} disabled={!form.id_especialidade || (parseInt(form.id_processo_etapa) > 2 && parseInt(form.id_processo_etapa) !== 6 && parseInt(form.id_processo_etapa) !== 12)} />

      <InputForm label="Prestador CNPJ" mask="##.###.###/####-##" className="span-3" value={form.prestador.cnpj} name="prestador.cnpj" model={setModel}
        exibicao={exibicao} error={validacao['prestador.cnpj']} maxlength={15} />
      <InputForm label="Prestador Nome" className="span-5" value={form.prestador.nome} name="prestador.nome" model={setModel}
        exibicao={exibicao} error={validacao['prestador.nome']} maxlength={40} />

      <InputForm label="Guia de atendimento" required className="span-2" value={form.guia} name="guia" model={setModel}
        exibicao={exibicao} error={validacao.guia} maxlength={30} />
      <InputForm label="Data de atendimento" required type="date" disabled={form?.id} className="span-2" value={tools.prepareDate(form.data_atendimento)} name="data_atendimento" model={setModel}
        exibicao={exibicao} error={validacao.data_atendimento} maxDate={tools.getDate()} />
      <InputForm label="Data ANS" type="text" className="span-2" value={(ansDate) ? tools.formatDate(ansDate) : '-'} disabled exibicao={exibicao} />
      <InputForm label="Data e hora de cadastro" type="text" className="span-2" value={(form.data_criacao) ? tools.formatDateHour(form.data_criacao_original) : '-'} disabled exibicao={exibicao} />
      <InputForm label="Data início do processo" type="text" className="span-2" value={tools.formatDate(startDate)} exibicao={exibicao} disabled="disabled" />
      <InputForm label="Data do prazo säästö" type="text" className="span-2" value={tools.formatDate(expireDate)} disabled="disabled" exibicao={exibicao} />
      <InputForm label="Formato de análise" type="select" options={options.tipoAnalise} className="span-4" value={form.jmo_video} name="jmo_video" model={setModel}
        exibicao={exibicao} error={validacao.jmo_video} />
      {parseInt(form.id_operadora) === 14 && <InputForm label="Plano" type="select" options={options.planoBeneficiario} className="span-4" value={form.id_plano} name="id_plano" model={setModel}
        exibicao={exibicao} error={validacao.planoBeneficiario} />}
    </div>

    <div>
      <h2 className="f600-18 mv-60">Dados do Beneficiário</h2>
      <div className={`form-container grid col-${modoEdicao ? 12 : 12} w-100`}>
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="CPF" required className="span-2" mask="###.###.###-##" value={form.beneficiario.cpf}
          onBlur={getCpf} onClick={validCpf} name="beneficiario.cpf" model={(name, value) => {
            validCpf();
            setModel(name, value);
          }}
          exibicao={exibicao} error={validacao['beneficiario.cpf']}>
          {!exibicao && <i className="icon icon-bolt blue i-15" style={{ position: 'absolute', right: '18px', top: '17px' }} />}
        </InputForm>}
        <InputForm label="Nome" required className="span-4" value={form.beneficiario.nome} name="beneficiario.nome" model={setModel}
          exibicao={exibicao} error={validacao['beneficiario.nome']} maxlength={64} />
        <InputForm label="Sexo" required className="span-2" value={form.beneficiario.sexo} name="beneficiario.sexo" model={setModel}
          exibicao={exibicao} error={validacao['beneficiario.sexo']}
          type="select" options={options.sexos} />
        <InputForm label="Data de Nascimento" required type="date" maxDate={tools.getDate()} className="span-2" value={form.beneficiario.nascimento} name="beneficiario.nascimento" model={setModel}
          exibicao={exibicao} error={validacao['beneficiario.nascimento']} />
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm mask="####################" label="Carteirinha" className="span-2" value={form.beneficiario.carteirinha} name="beneficiario.carteirinha" model={setModel}
          exibicao={exibicao} error={validacao['beneficiario.carteirinha']} maxlength={20} />}
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Telefone" required mask={"(##) #####-#####"} className="span-2" value={form.beneficiario.telefone} name="beneficiario.telefone" model={setModel}
          exibicao={exibicao} error={validacao["beneficiario.telefone"]} />}
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Telefone (Secundário)" mask={"(##) #####-#####"} className="span-2" value={form.beneficiario.telefone2} name="beneficiario.telefone2" model={setModel}
          exibicao={exibicao} error={validacao["beneficiario.telefone2"]} />}
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="E-mail" required type="email" className="span-4" value={form.beneficiario.email} name="beneficiario.email" model={setModel}
          exibicao={exibicao} error={validacao['beneficiario.email']} maxlength={64} />}
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="E-mail (Secundário)" type="email" className="span-4" value={form.beneficiario.email2} name="beneficiario.email2" model={setModel}
          exibicao={exibicao} error={validacao['beneficiario.email2']} maxlength={64} />}
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="CEP" required mask={"#####-###"} className="span-2" value={form.beneficiario?.cep} onBlur={getCep}
          name="beneficiario.cep" model={setModel}
          exibicao={exibicao} error={validacao['beneficiario.cep']} >
          {!exibicao && <i className="icon icon-bolt blue i-15" style={{ position: 'absolute', right: '18px', top: '17px' }} />}
        </InputForm>}
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Rua" required className="span-4" value={form.beneficiario.rua} name="beneficiario.rua" model={setModel}
          exibicao={exibicao} error={validacao['beneficiario.rua']} maxlength={64} />}
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Número" required className="span-2" value={form.beneficiario.numero} name="beneficiario.numero" model={setModel}
          exibicao={exibicao} error={validacao['beneficiario.numero']} />}
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Complemento" className="span-4" value={form.beneficiario.complemento} name="beneficiario.complemento" model={setModel}
          exibicao={exibicao} error={validacao['beneficiario.complemento']} maxlength={64} />}
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Cidade" required className="span-3" value={form.beneficiario.cidade} name="beneficiario.cidade" model={setModel}
          exibicao={exibicao} error={validacao['beneficiario.cidade']} maxlength={64} />}
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Bairro" required className="span-3" value={form.beneficiario.bairro} name="beneficiario.bairro" model={setModel}
          exibicao={exibicao} error={validacao['beneficiario.bairro']} maxlength={64} />}
        {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Estado/UF" required className="span-3" type="select" value={form.beneficiario.uf} name="beneficiario.uf" model={setModel}
          exibicao={exibicao} options={options.estados} error={validacao['beneficiario.uf']} />}
      </div>
    </div>
    {config.temPermissao('processo:dados_beneficiario:visualizar') && <h2 className="f600-18 mv-60">Dados do Profissional - Assistente</h2>}
    {config.temPermissao('processo:dados_beneficiario:visualizar') && <div className={`form-container grid col-${modoEdicao ? 12 : 12} w-100`}>
      <InputForm label="UF" required className="span-4" type="select" value={form.profissional.uf} name="profissional.uf" model={setModel}
        exibicao={exibicao} options={options.estados} error={validacao['profissional.uf']} />
      <InputForm label="CRM/CRO" required className="span-2" value={form.profissional.crm}
        onBlur={getCrm} name="profissional.crm" model={setModel} disabled={!form?.profissional?.uf}
        exibicao={exibicao} error={validacao['profissional.crm']}>
        {!exibicao && <i className={`icon icon-bolt i-15 ${(!form?.profissional?.uf) ? 'light-gray' : 'blue'}`} style={{ position: 'absolute', right: '18px', top: '17px' }} />}
      </InputForm>
      <InputForm label="Nome" required className="span-6" value={form.profissional.nome} name="profissional.nome" model={setModel}
        exibicao={exibicao} error={validacao['profissional.nome']} maxlength={64} />
      <InputForm label="Telefone" required mask={"(##) #####-####"} className="span-2" value={form.profissional.telefone} name="profissional.telefone" model={setModel}
        exibicao={exibicao} error={validacao['profissional.telefone']} />
      <InputForm label="Telefone (Secundário)" mask={"(##) #####-####"} className="span-2" value={form.profissional.telefone2} name="profissional.telefone2" model={setModel}
        exibicao={exibicao} />
      <InputForm label="E-mail" required type="email" className="span-4" value={form.profissional.email} name="profissional.email" model={setModel}
        exibicao={exibicao} error={validacao['profissional.email']} maxlength={64} />
      <InputForm label="E-mail (Secundário)" type="email" className="span-4" value={form.profissional.email2} name="profissional.email2" model={setModel}
        exibicao={exibicao} maxlength={64} />
      {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="CEP" required mask={"#####-###"} className="span-2" value={form.profissional?.cep} onBlur={getCepProf}
        name="profissional.cep" model={setModel}
        exibicao={exibicao} error={validacao['profissional.cep']} >
        {!exibicao && <i className="icon icon-bolt blue i-15" style={{ position: 'absolute', right: '18px', top: '17px' }} />}
      </InputForm>}
      {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Rua" required className="span-4" value={form.profissional.rua} name="profissional.rua" model={setModel}
        exibicao={exibicao} error={validacao['profissional.rua']} maxlength={64} />}
      {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Número" required className="span-2" value={form.profissional.numero} name="profissional.numero" model={setModel}
        exibicao={exibicao} error={validacao['profissional.numero']} />}
      {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Complemento" className="span-4" value={form.profissional.complemento} name="profissional.complemento" model={setModel}
        exibicao={exibicao} error={validacao['profissional.complemento']} maxlength={64} />}
      {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Cidade" required className="span-3" value={form.profissional.cidade} name="profissional.cidade" model={setModel}
        exibicao={exibicao} error={validacao['profissional.cidade']} maxlength={64} />}
      {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Bairro" required className="span-3" value={form.profissional.bairro} name="profissional.bairro" model={setModel}
        exibicao={exibicao} error={validacao['profissional.bairro']} maxlength={64} />}
      {config.temPermissao('processo:dados_beneficiario:visualizar') && <InputForm label="Estado/UF" required className="span-3" type="select" value={form.profissional.endereco_uf} name="profissional.endereco_uf" model={setModel}
        exibicao={exibicao} options={options.estados} error={validacao['profissional.endereco_uf']} />}
    </div>}
    {config.temPermissao('processo:dados_auditor:visualizar') &&
      <div>
        <h2 className="f600-18 mv-60">Dados do Profissional - Auditor</h2>
        <div className={`form-container grid col-${modoEdicao ? 12 : 12} w-100`}>
          <InputForm label="UF" required className="span-4" type="select" value={form?.auditor?.uf} name="auditor.uf" model={setModel}
            exibicao={exibicao} options={options.estados} error={validacao['auditor.uf']} />
          <InputForm label="CRM/CRO" required className="span-2" value={form?.auditor?.crm}
            onBlur={getCrmAuditor} name="auditor.crm" model={setModel} disabled={!form?.auditor?.uf}
            exibicao={exibicao} error={validacao['auditor.crm']}>
            {!exibicao && <i className={`icon icon-bolt i-15 ${(!form?.auditor?.uf) ? 'light-gray' : 'blue'}`} style={{ position: 'absolute', right: '18px', top: '17px' }} />}
          </InputForm>
          <InputForm label="Nome" required className="span-6" value={form?.auditor?.nome} name="auditor.nome" model={setModel}
            exibicao={exibicao} error={validacao['auditor.nome']} maxlength={64} />
        </div>
      </div>}
    {modoEdicao && !exibicao && <div className="form-rodape mt-70 mb-30">
      <button className="btn btn-outline-primary f700-16" onClick={cancel}>
        Cancelar
      </button>
      <button className="btn btn-primary f700-16" onClick={async (e) => {
        e.preventDefault();
        if (await salvar(e, 1)) {
          setExibicao(!exibicao);
        }
      }} disabled={updateStatus}>
        {updateStatus ? (<Loading />) : 'Salvar'}
      </button>
    </div>}
  </div>
  );
}

