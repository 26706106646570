import React from 'react';
import { useState } from 'react';
import InputForm from '../Form/InputForm';
import Modal from '../Modal';
import { useEffect } from 'react';
import business from '../../services/business';
import tools from '../../lib/tools';
import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import Loading from '../Loading';

export default function ModalDuplicar({ hideModal, data, onChange }) {

    const [form, setForm] = useState({});
    const [validacao, setValidacao] = useState({});
    const [permitirDuplicar, setPermitirDuplicar] = useState(false);
    const [changed, setChanged] = useState(false);
    const alert = useAlert();
    const [options, setOptions] = useState({
        tiposProcesso: [],
        tiposProcessoRef: []
    });

    const setModel = (name, value) => {
        setForm({ ...form, ...{ [name]: value } });
    }

    const checkIsValidate = useCallback(async () => {
        let validated = true;
        let validacao = {};

        const obrigatorios = [];

        document.querySelectorAll('.duplicar [name][required]').forEach((e) => {
            if (e.name) obrigatorios.push(e.name);
        });

        obrigatorios.forEach(campo => {
            if (typeof form[campo] === 'undefined' || tools.isEmptyNullOrUndefined(form[campo])) {
                validacao[campo] = 'Campo Obrigatório';
                validated = false;
            }
        })

        setValidacao(validacao);
        setPermitirDuplicar(validated);
    }, [form]);

    const salvar = async (e) => {

        e.preventDefault();

        checkIsValidate();

        if (!validacao || !permitirDuplicar) {
            return false;
        }

        setChanged(true);

        let res = await business.duplicarProcesso(form.id, form.id_processo_tipo);

        if (res?.errors?.length) {
            (res.errors).forEach(e => alert.error(e));
        } else {
            if (onChange && typeof onChange === 'function')
                onChange(e, res?.response?.slug, res?.response?.id);
            alert.success('O processo foi duplicado com sucesso!');
        }

        setChanged(false);
        handleClose(e);
    }

    const handleClose = (e) => {
        const body = document.body;
        body.classList.remove('overflow-hidden');
        hideModal(e);
    }

    const getOptions = useCallback(async () => {

        let tempOptions = {};

        const tipos_permitidos = {
            1: [1, 2, 3],
            2: [3],
            3: [3]
        };

        await business.getTiposProcesso().then(l => {
            tempOptions.tiposProcesso = l?.filter((item) => {
                return tipos_permitidos[parseInt(data.id_processo_tipo)]?.includes(parseInt(item.id));
            });
            tempOptions.tiposProcessoRef = l;
        });
        setOptions(prevState => {
            return { ...prevState, ...tempOptions };
        });
    }, [data.id_processo_tipo]);

    useEffect(() => {
        if (form?.id)
            getOptions();
        // eslint-disable-next-line
    }, [form]);

    useEffect(() => {
        checkIsValidate();
    }, [checkIsValidate]);

    useEffect(() => {
        setForm(prevState => {
            return {
                ...prevState, ...{
                    id: data.id,
                    id_protocolo: data.id_protocolo,
                    id_processo_tipo: ''
                }
            };
        });
    }, [data]);

    return (
        <Modal show={data?.show} handleClose={handleClose} style={{ maxWidth: '1000px', maxHeight: '600px' }} modalClass="modal-cad-usuario duplicar">
            <div className="flex">
                <div className="form w-100 mb-50">
                    <h1 className="title mb-45">Duplicar o processo {data?.id_protocolo}</h1>
                    <div className={`form-container grid col-12`}>
                        <div className="span-6">
                            <InputForm label="Tipo de Processo" required
                                type="select" disabled={!data?.id}
                                options={options.tiposProcesso}
                                className="span-12" value={form.id_processo_tipo}
                                name="id_processo_tipo" model={setModel}
                                error={validacao.id_processo_tipo} />
                        </div>
                        <div className="span-6">
                            <label className="f300-14 black-text mb-15">Fluxo (de/para)</label>
                            <div className="span-12 flex mb-10">
                                <span className="span-5 fn-16 m-auto">
                                    {options?.tiposProcessoRef?.find((item) => { return parseInt(item.id) === parseInt(data.id_processo_tipo); })?.nome || <Loading />}</span>
                                <span className="span-2 fn-20 m-auto">
                                    <i className="icon icon-arrow-right blue i-25 m-auto" />
                                </span>
                                <span className="span-5 fn-16 m-auto">
                                    {options?.tiposProcesso?.find((item) => { return parseInt(item.id) === parseInt(form.id_processo_tipo); })?.nome || <b>Selecione</b>}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="form-rodape mt-30 ">
                        <button className="btn btn-outline-primary f700-16" onClick={handleClose}>
                            Cancelar
                        </button>
                        <button className="btn btn-primary f700-16 mr-40" disabled={!permitirDuplicar || changed} onClick={salvar}  >
                            {(changed) ? <Loading /> : 'Duplicar'}
                        </button>
                    </div>
                </div>

            </div>
        </Modal >
    );
}
