const layout = {
  selected: null,
  menuOpen: false,
  menu: [
    {
      id: "/",
      label: "Início",
      icon: "icon-eye",
      permission: "",
      sub: [],
    },
    {
      id: "/novo-processo",
      label: "Novo Processo",
      icon: "icon-add-document",
      permission: "processo:novo_processo:cadastrar",
      sub: [],
    },
    {
      id: "/opiniao-especializada",
      label: "Segunda Opinião",
      icon: "icon-doctor",
      permission: "menu:segunda_opiniao:visualizar",
      sub: [],
    },
    {
      id: "/consenso-pre",
      label: "Consenso Pré",
      icon: "icon-handshake",
      permission: "menu:consenso_pre:visualizar",
      sub: [],
    },
    {
      id: "/junta-medica",
      label: "Junta Médica ou Odontológica",
      icon: "icon-users-alt",
      permission: "menu:junta_medica:visualizar",
      sub: [],
    },
    {
      id: "/relatorios",
      label: "Gestão de processos",
      icon: "icon-chart",
      permission: "bi:relatorios:visualizar",
      sub: [
        {
          id: "/relatorios/bi",
          label: "Business Intelligence",
          icon: "icon-power-bi",
          permission: "bi:powerbi:visualizar",
        },
        {
          id: "/relatorios/total-economizado",
          label: "Relatório Gerencial",
          icon: "icon-economy",
          permission: "bi:relatorio_economia:visualizar",
        },
        {
          id: "/relatorios/financeiro",
          label: "Relatório Financeiro",
          icon: "icon-chart",
          permission: "bi:relatorio_financeiro:visualizar",
          //Gabriela pode ver
          exceptionForUserId: 9,
        },
      ],
    },
    {
      id: "/configuracoes",
      label: "Administração",
      icon: "icon-settings",
      permission: "admin:usuarios:listar",
      sub: [
        {
          id: "/usuarios",
          label: "Usuários",
          icon: "icon-users",
          permission: "admin:usuarios:listar",
        },
        {
          id: "/operadoras",
          label: "Operadoras",
          icon: "icon-building",
          permission: "admin:operadoras:listar",
        },
      ],
    },
    {
      id: "/suporte",
      label: "Suporte",
      icon: "icon-comments-question",
      permission: "",
      sub: [],
    },
  ],
  setMenuOpen: function (open) {
    this.menuOpen = open;
  },
  openMenu: function (url, sub = false) {
    if (this?.selected || this?.selected === null) {
      this.selected = url;

      if (url === "/configuracoes") this.openConfiguracoes = sub;

      if (url === "/relatorios") this.openRelatorios = sub;
    }
  },
  getLabel: function (lista = [], id, identificador = "id", nome = "nome") {
    return (
      lista.find((l) => String(l?.[identificador]) === String(id))?.[nome] || id
    );
  },
  title: (name) => {
    switch (name) {
      case "junta-medica":
        return "Junta Médica ou Odontológica";
      case "consenso-pre":
        return "Consenso Pré";
      case "opiniao-especializada":
        return "Segunda Opinião";
      default:
        return "Título da página";
    }
  },
};

export default layout;
