import React, { useEffect, useState } from "react";
import Layout from "../components/Layout.js";
import MenuDots from "../components/MenuDots.js";
/* import Status from "../components/Status.js"; */
import config from "../config/config.js";
import { NumericFormat } from 'react-number-format';
import '../assets/styles/pages/Dashboard.scss'
import business from "../services/business.js";
import Loading from "../components/Loading.js";
import layout from "../lib/layout.js";
import tools from "../lib/tools.js";
//import DonutChart from "../components/Dashboard/DonutChart.js";
//import BarChart from "../components/Dashboard/BarCharts.js";
//import AreaChart from "../components/Dashboard/AreaChart.js";

export default function Dashboard() {
  layout.openMenu('/');

  let user = config.user;
  document.title = 'säästo';

  window.addEventListener('resize', (e) => {
    showCards();
  });

  const [quantidade, setQuantidade] = useState(4);
  const showCards = () => {
    let body = document.body;
    if (body.offsetWidth >= 1004) {
      setQuantidade(4);
    } else {
      setQuantidade(4);
    }
  }

  const [dashboard, setDashboard] = useState([]);
  const [dashboardTotais, setDashboardTotais] = useState([]);

  const getDias = async (inicio_processo) => {
    let total = await tools.diasUteisEntreDatas(inicio_processo, config.dataHoraAtual());
    return total;
  }

  useEffect(() => {
    (async () => {
      let response = await business.getDashboard();
      setDashboardTotais(response);
      let data = response || [];

      data = data.ultimos_processos.map(async (row) => {
        row.dias = await getDias(row.inicio_processo).then(value => { return value; });
        return row;
      });
      Promise.all(data).then((results) => {
        setDashboard(results);
      });
    })();
    showCards();
  }, [user]);

  //  Autoreload de mouse estiver parado ou janela inativa apenas
  const [mouseIdle, setMouseIdle] = useState(false);

  useEffect(() => {
    let idleTimer;

    const handleMouseMove = () => {
      clearTimeout(idleTimer);
      setMouseIdle(false);
      idleTimer = setTimeout(() => {
        setMouseIdle(true);
      }, 50000); // Tempo para considerar mouse inativo
    };

    const handleWindowFocus = () => {
      setMouseIdle(false);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('focus', handleWindowFocus);
      clearTimeout(idleTimer);
    };
  }, []);

  useEffect(() => {
    if (mouseIdle) {
      const interval = setTimeout(() => {
        window.location.reload();
      }, 5000);

      return () => clearTimeout(interval); // Limpa o timeout quando o componente é desmontado
    }
  }, [mouseIdle]);

  return (
    <Layout id="dashboard">
      <p className="page-title">Início</p>
      <h2 className="title black-text mb-40">Olá, {user.nome}.</h2>
      {config.temPermissao('dashboard:dashboard:visualizar') && <div className="cards-container mb-45">
        {<div className="card simple f600-18" style={{ '--colorCard': '#24BE34' }}>
          <span className="text-black flex">Saúde econômica <i className="ml-5 icon icon-question i-15 cursor-help" title="Valor total economizado ao utilizar a säästö." /></span>
          {(typeof dashboardTotais?.total_economizado === 'undefined') ? (
            <Loading />
          ) : <NumericFormat className="f600-24 mt-15" value={parseFloat(dashboardTotais.total_economizado) || 0} displayType={'text'} thousandSeparator={'.'} prefix="R$ "
            decimalScale={2} fixedDecimalScale decimalSeparator={','} />}
        </div>}
        {quantidade > 2 && <div className="card card-color f600-18" style={{ '--colorCard': '#003CFF' }}>
          <div className="color" />
          <div className="info">
            <span className="text-black flex">Em andamento <i className="ml-5 icon icon-question i-15 cursor-help" title="Total de processos em andamento." /></span>
            {(typeof dashboardTotais?.total_processo_andamento === 'undefined') ? (
              <Loading />
            ) : <NumericFormat className="f600-32 mt-15" value={dashboardTotais.total_processo_andamento} displayType={'text'} />}
          </div>
        </div>}
        <div className="card card-color f600-18" style={{ '--colorCard': '#FF7283' }}>
          <div className="color" />
          <div className="info">
            <span className="text-black flex">Pendentes <i className="ml-5 icon icon-question i-15 cursor-help" title="Total de processos aguardando sua interação." /></span>
            {(typeof dashboardTotais?.total_processo_pendentes === 'undefined') ? (
              <Loading />
            ) : <NumericFormat className="f600-32 mt-15" value={parseInt(dashboardTotais.total_processo_pendentes)} displayType={'text'} />}
          </div>
        </div>
        <div className="card card-color f600-18" style={{ '--colorCard': '#FF7283' }}>
          <div className="color" />
          <div className="info">
            <span className="text-black flex">Atrasados <i className="ml-5 icon icon-question i-15 cursor-help" title="Total de processos com prazo säästö atrasado." /></span>
            {(typeof dashboardTotais?.total_processo_atrasado === 'undefined') ? (
              <Loading />
            ) : <NumericFormat className="f600-32 mt-15" value={parseInt(dashboardTotais.total_processo_atrasado)} displayType={'text'} />}
          </div>
        </div>
        {quantidade > 3 && <div className="card card-color f600-18" style={{ '--colorCard': '#24BE34' }}>
          <div className="color" />
          <div className="info">
            <span className="text-black flex">Concluídos <i className="ml-5 icon icon-question i-15 cursor-help" title="Total de processos concluídos." /></span>
            {(typeof dashboardTotais?.total_processo_finalizado === 'undefined') ? (
              <Loading />
            ) : <NumericFormat className="f600-32 mt-15" value={parseInt(dashboardTotais.total_processo_finalizado)} displayType={'text'} />}
          </div>
        </div>}
        <br />


        {/*<div className="f600-18 pointer">
          Ver <br /> todos Indicadores
  </div>*/}
      </div>}
      {config.temPermissao('dashboard:dashboard_meus_processos:visualizar') && <h2 className="title black-text mb-40">Meus processos</h2>}
      {config.temPermissao('dashboard:dashboard_meus_processos:visualizar') && <div className="cards-container mb-45">
        {quantidade > 3 && <div className="card card-color f600-18" style={{ '--colorCard': '#003CFF' }}>
          <div className="color" />
          <div className="info">
            <span className="text-black flex">Em andamento <i className="ml-5 icon icon-question i-15 cursor-help" title="Total de processos cadastrados por você que estão em andamento." /></span>
            {(typeof dashboardTotais?.meus_processos === 'undefined') ? (
              <Loading />
            ) : <NumericFormat className="f600-32 mt-15" value={parseInt(dashboardTotais.meus_processos)} displayType={'text'} />}
          </div>
        </div>}
        <div className="card card-color f600-18" style={{ '--colorCard': '#FF7283' }}>
          <div className="color" />
          <div className="info">
            <span className="text-black flex">Pendentes <i className="ml-5 icon icon-question i-15 cursor-help" title="Total de processos cadastrados por você que estão aguardando sua interação." /></span>
            {(typeof dashboardTotais?.meus_processos_pendentes === 'undefined') ? (
              <Loading />
            ) : <NumericFormat className="f600-32 mt-15" value={parseInt(dashboardTotais.meus_processos_pendentes)} displayType={'text'} />}
          </div>
        </div>
        <div className="card card-color f600-18" style={{ '--colorCard': '#FF7283' }}>
          <div className="color" />
          <div className="info">
            <span className="text-black flex">Atrasados <i className="ml-5 icon icon-question i-15 cursor-help" title="Total de processos cadastrados por você com prazo säästö atrasado." /></span>
            {(typeof dashboardTotais?.meus_processos_atrasados === 'undefined') ? (
              <Loading />
            ) : <NumericFormat className="f600-32 mt-15" value={parseInt(dashboardTotais.meus_processos_atrasados)} displayType={'text'} />}
          </div>
        </div>
        {/* {quantidade > 3 && <div className="card card-color f600-18" style={{ '--colorCard': '#24BE34' }}>
          <div className="color" />
          <div className="info">
            <span className="text-black flex">Concluídos <i className="ml-5 icon icon-question i-15 cursor-help" title="Total de processos concluídos." /></span>
            {(typeof dashboardTotais?.meus_processos_finalizados === 'undefined') ? (
              <Loading />
            ) : <NumericFormat className="f600-32 mt-15" value={parseInt(dashboardTotais.meus_processos_finalizados)} displayType={'text'} />}
          </div>
        </div>} */}
      </div>}
      {/* <div className="row w-100 pb-50 md-gap jc-fc">
        <BarChart data={''} />
        <AreaChart data={''} />
        <DonutChart data={[parseInt(dashboardTotais.total_finalizado_segunda_opiniao), parseInt(dashboardTotais.total_finalizado_consenso_pre), parseInt(dashboardTotais.total_finalizado_junta_medica)]} />
      </div> */}

      <h2 className="title mb-30">Últimos processos</h2>
      <div className="sub-title mb-40 gap-20">
        <p className="suva-grey-text">Listagem dos últimos processos recebidos.</p>
        {/*  <Status /> */}
      </div>

      <div className="table-mobile">
        {(dashboard || []).map((p, i) => {
          return <div className="item pointer" key={`mobile-${i}`} onClick={() => tools.openURL(`/junta-medica/${p.id}`)}>
            <div>
              <div className="fn-16 linha flex jc-sb">
                ID
                <span>
                  <strong> {p.id_protocolo} </strong>
                </span>
              </div>
              <div className="fn-16 linha flex jc-sb">
                Operadora
                <span>
                  {(user.tipo !== '2' && user.tipo !== '3') && p.desc_operadora}
                </span>
              </div>
              <div className="fn-16 linha flex jc-sb">
                Beneficiário
                <span>
                  {p.nome_beneficiario}
                </span>
              </div>
              <div className="fn-16 linha flex jc-sb">
                Tipo
                <span>
                  {p.desc_processo_tipo}
                </span>
              </div>
              <div className="fn-16 linha flex jc-sb">
                Etapa/Status
                <span>{p.desc_processo_etapa} - {p.desc_processo_etapa_status}</span>
              </div>
              <div className="fn-16 linha flex jc-sb">
                Situação
                <span className={`badge badge-${(p.id_processo_etapa === "1" || p.id_processo_etapa === "12" || p.id_processo_etapa === "4" || p.id_processo_etapa === "5") ? p.id_processo_etapa : (p.expiracao_processo <= tools.getDate() ? 'atrasado' : ((p.id_processo_etapa_status === "3" || p.id_processo_etapa_status === "4" || p.id_processo_etapa_status === "19" || p.id_processo_etapa_status === "24" || p.id_processo_etapa_status === "26") ? 'pausado' : p.id_processo_etapa))}`}>

                  {p.id_processo_etapa === "12" || p.id_processo_etapa === "1" || p.id_processo_etapa === "4" || p.id_processo_etapa === "5" ? <span className="display-none">{p.id_processo_etapa}</span> :
                    ((p.id_processo_etapa_status === "3" || p.id_processo_etapa_status === "4" || p.id_processo_etapa_status === "19" || p.id_processo_etapa_status === "24" || p.id_processo_etapa_status === "26") ? 'Pausado' :
                      (p.expiracao_processo <= tools.getDate() ? 'Atrasado' : (((tools.formatDateSQL(p.inicio_processo) === tools.getDate()) || (p.dias - p.pausa_dias) < 0) ? 0 : (p.dias - p.pausa_dias - 1)) + " / " + (p?.id_processo_tipo === '1' ? 2 : (p?.id_processo_tipo === '2' ? 1 : 7))))}
                </span>
              </div>
            </div>
            <div className="w-100 flex jc-fc">
              <MenuDots row={p} menuPositionTop={true} menuItens={[
                {
                  label: 'Visualizar',
                  icon: 'icon-eye blue i-25',
                  className: 'menu-icon',
                  onClick: () => tools.openURL(p.id_processo_tipo === '3' ? `/junta-medica/${p.id}` : p.id_processo_tipo === '2' ? `/consenso-pre/${p.id}` : `/opiniao-especializada/${p.id}`)
                },
                /* {
                    label: 'Duplicar',
                    icon: 'icon-duplicate blue i-25',
                    className: 'menu-icon',
                    onClick: (e) => {
                      e.stopPropagation()
                      functions.duplicarProcesso()
                    }
                  }, */
                /* {
                  label: 'Dossiê',
                  icon: 'icon-dossie blue i-25',
                  className: 'menu-icon',
                  onClick: (e) => {
                    tools.openURL('dossie')
                  }
                }, */
                /* {
                  label: 'Cancelar',
                  icon: 'icon-cross-circle blue i-25',
                  className: 'menu-icon',
                  onClick: (e) => {
                    e.stopPropagation()
                    functions.cancelarProcesso()
                  }
                } */
              ]} />
            </div>
          </div>
        })}
        {(!dashboard || !dashboard?.length) ? <div key={0} className="item pointer">Nenhum processo foi encontrado</div> : ''}
      </div>


      <table className="w-100 table-desktop">
        <thead className="f300-16">
          <tr>
            <th className="text-center bold">ID</th>
            {(user.tipo !== '2' && user.tipo !== '3') && <th className="text-left bold">Operadora</th>}
            <th className="text-left bold">Beneficiário</th>
            <th className="text-center bold">Tipo</th>
            <th className="text-center bold">Etapa/Status</th>
            <th className="text-center bold">Situação</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(dashboard || []).map((p, i) => {
            return <tr key={`desktop-${i}`} className={`hover-highlight ${((p.id_processo_etapa_status === "3" || p.id_processo_etapa_status === "4" || p.id_processo_etapa_status === "19" || p.id_processo_etapa_status === "24" || p.id_processo_etapa_status === "26") && (user.tipo === '2' || user.tipo === '3')) ? 'bold-red' : ''}`} onClick={() => tools.openURL(p.id_processo_tipo === '3' ? `/junta-medica/${p.id}` : p.id_processo_tipo === '2' ? `/consenso-pre/${p.id}` : `/opiniao-especializada/${p.id}`)}>
              <td className="fn-16 text-center" style={{ minWidth: '100px' }}>
                <span className={`badge badge-${(p.id_processo_etapa === "1" || p.id_processo_etapa === "12" || p.id_processo_etapa === "4" || p.id_processo_etapa === "5") ? p.id_processo_etapa : (p.expiracao_processo <= tools.getDate() ? 'atrasado' : ((p.id_processo_etapa_status === "3" || p.id_processo_etapa_status === "4" || p.id_processo_etapa_status === "19" || p.id_processo_etapa_status === "24" || p.id_processo_etapa_status === "26") ? 'pausado' : p.id_processo_etapa))} badge-no-after`}>
                  {p.id_protocolo}
                </span>
              </td>
              {(user.tipo !== '2' && user.tipo !== '3') && <td className="fn-16 text-left">{p.desc_operadora}</td>}
              <td className="fn-16 text-left">{p.nome_beneficiario}</td>
              <td className="fn-16 text-center">{p.desc_processo_tipo}</td>
              <td className="fn-16 text-center" style={{ minWidth: '180px' }}>
                <div className="flex ai-fc jc-fc fn-14 gap-5">
                  <span className="fn-14">{p.desc_processo_etapa}:<br /> {p.desc_processo_etapa_status}</span>
                </div>
              </td>
              <td className="fn-16 text-center">
                <span className={`badge badge-${(p.id_processo_etapa === "1" || p.id_processo_etapa === "12" || p.id_processo_etapa === "4" || p.id_processo_etapa === "5") ? p.id_processo_etapa : (p.expiracao_processo <= tools.getDate() ? 'atrasado' : ((p.id_processo_etapa_status === "3" || p.id_processo_etapa_status === "4" || p.id_processo_etapa_status === "19" || p.id_processo_etapa_status === "24" || p.id_processo_etapa_status === "26") ? 'pausado' : p.id_processo_etapa))}`}>

                  {p.id_processo_etapa === "12" || p.id_processo_etapa === "1" || p.id_processo_etapa === "4" || p.id_processo_etapa === "5" ? <span className="display-none">{p.id_processo_etapa}</span> :
                    ((p.id_processo_etapa_status === "3" || p.id_processo_etapa_status === "4" || p.id_processo_etapa_status === "19" || p.id_processo_etapa_status === "24" || p.id_processo_etapa_status === "26") ? 'Pausado' :
                      (p.expiracao_processo <= tools.getDate() ? 'Atrasado' : (((tools.formatDateSQL(p.inicio_processo) === tools.getDate()) || (p.dias - p.pausa_dias) < 0) ? 0 : (p.dias - p.pausa_dias - 1)) + " / " + (p?.id_processo_tipo === '1' ? 2 : (p?.id_processo_tipo === '2' ? 1 : 7))))}
                </span>
              </td>
              <td style={{ minWidth: '8%' }} />
              <td className="text-center" style={{ width: '35px' }}>
                <MenuDots row={p} menuPositionTop={(dashboard?.ultimos_processos || []).length - i <= 2} menuItens={[
                  {
                    label: 'Visualizar',
                    icon: 'icon-eye blue i-25',
                    className: 'menu-icon',
                    onClick: () => tools.openURL(p.id_processo_tipo === '3' ? `/junta-medica/${p.id}` : p.id_processo_tipo === '2' ? `/consenso-pre/${p.id}` : `/opiniao-especializada/${p.id}`)
                  },
                  /* {
                      label: 'Duplicar',
                      icon: 'icon-duplicate blue i-25',
                      className: 'menu-icon',
                      onClick: (e) => {
                        e.stopPropagation()
                        functions.duplicarProcesso()
                      }
                    }, */
                  /* {
                    label: 'Dossiê',
                    icon: 'icon-dossie blue i-25',
                    className: 'menu-icon',
                    onClick: (e) => {
                      tools.openURL('dossie')
                    }
                  }, */
                  /* {
                    label: 'Cancelar',
                    icon: 'icon-cross-circle blue i-25',
                    className: 'menu-icon',
                    onClick: (e) => {
                      e.stopPropagation()
                      functions.cancelarProcesso()
                    }
                  } */
                ]} />
              </td>
            </tr>
          })}

          {(!dashboard || !dashboard?.length) ? <tr>
            <td colSpan={8}>Nenhum processo foi encontrado</td>
          </tr> : ''}
        </tbody>
      </table>

    </Layout>
  );
}

