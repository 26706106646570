import React, { useState } from "react";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import { logout } from "../../services/auth.js";
import tools from "../../lib/tools.js";
import Modalpassword from "../Modais/ModalPassword.js";
import ModalConfig from "../Modais/ModalConfig.js";

export default function Perfil({ user }) {
  const { buttonProps, isOpen } = useDropdownMenu(1);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfig, setShowModalConfig] = useState(false);

  const deslogar = () => {
    return logout();
  }

  const [imgError, setImgError] = useState(false);
  return (
    <div className="relative header-perfil">
      <Modalpassword show={showModal} hideModal={async (e) => {
        e?.preventDefault();
        setShowModal(false);
      }} />
      <ModalConfig show={showModalConfig} hideModal={async (e) => {
        e?.preventDefault();
        setShowModalConfig(false);
      }} />
      <label className={`user pointer ${isOpen && 'active'}`} onClick={(e) => e.stopPropagation()}  {...buttonProps} >
        <div title={user.tipo_desc}>
          {imgError || !user?.foto ?
            <div className="foto-padrao flex ai-fc jc-fc"><i className="icon icon-user i-20 blue" /></div>
            : <img alt={user.nome} src={user?.foto || ''}
              onError={({ currentTarget }) => {
                setImgError(true)
              }} />}
          {user.nome}
        </div>
        <i className="icon icon-angle-small-down i-20" />
      </label>
      <div className={`dropdown-submenu ${isOpen && 'visible'} flex fd-fc`} role='menu'>
        {/*<span className={`relative menu-icon`}>*/}
        {/*  <i className={`icon icon-user blue i-25 mr-10`} />*/}
        {/*  <div className="flex fd-fc">*/}
        {/*    <span className="f400-16">Minha Conta</span>*/}
        {/*  </div>*/}
        {/*</span>*/}
        {user.tipo === '1' && <span className={`relative menu-icon`} onClick={() => {
          setShowModalConfig(true);
        }}>
          <i className={`icon icon-settings blue i-25 mr-10`} />
          <div className="flex fd-fc">
            <span className="f400-16">Configuração</span>
          </div>
        </span>}
        <span className={`relative menu-icon`} onClick={() => {
          setShowModal(true);
        }}>
          <i className={`icon icon-lock2 blue i-25 mr-10`} />
          <div className="flex fd-fc">
            <span className="f400-16">Alterar senha</span>
          </div>
        </span>
        <span className={`relative menu-icon`} onClick={() => {
          tools.openURL(`/ajuda/faq`)
        }}>
          <i className={`icon icon-comments-question blue i-25 mr-10`} />
          <div className="flex fd-fc">
            <span className="f400-16">Material de apoio</span>
          </div>
        </span>
        <span className={`relative menu-icon`} onClick={deslogar}>
          <i className={`icon icon-exit blue i-25 mr-10`} />
          <div className="flex fd-fc">
            <span className="f400-16">Sair</span>
          </div>
        </span>
      </div>
    </div>
  );
}
