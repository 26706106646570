import React from "react";
import { useDropzone } from "react-dropzone";

export default function Dropzone({ open, onDrop, description }) {
  //https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  const accept = {
    'image/jpeg': [],
    'application/pdf': [],
    'image/png': [],
    'audio/mpeg': [],
    'audio/ogg': []
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept });
  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} accept=".jpeg,.jpg,.png,.pdf,.mp3,.ogg" />
      <p className="f600-22">Arraste o arquivo ou clique aqui.</p>
      <p className="f600-16">{description}</p>
    </div>
  );
}

