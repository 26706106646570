import React from 'react';
import { useState, useEffect } from 'react';
import tools from '../../lib/tools';
import business from '../../services/business';
import InputForm from '../Form/InputForm';
import Loading from '../Loading';
import Modal from '../Modal';

export default function ModalAutenticar({ hideModal, show, cancel, submit, data, submitted = false, render }) {

    const [form, setForm] = useState({
        uf: 'AC',
        crm: '',
        cpf: '',
        cnpj: ''
    })
    const [validacao, setValidacao] = useState([]);
    const [campoChave, setCampoChave] = useState(false);
    const [options] = useState({
        estados: business.getEstadosUF()
    });

    const setModel = (name, value) => {
        setForm({ ...form, ...{ [name]: value } });
    }

    const validCpf = async (key) => {
        let cpf = form.cpf;
        let { valido, texto } = await tools.validarCPF(cpf);
        validacao['cpf'] = texto;
        setValidacao(validacao);
        return valido;
    }

    useEffect(() => {
        if (['cpf', 'crm', 'cnpj'].includes(data?.metodo_autenticacao))
            setCampoChave(data?.metodo_autenticacao);
    }, [data, validacao, submitted, render]);

    return (
        <Modal show={show && campoChave} handleClose={(e) => {
            if (hideModal) {
                hideModal(e);
            }
        }} style={{ maxWidth: '400px' }} modalClass="modal-cad-usuario">
            <div className="flex">
                <div className="form w-100 mb-50">
                    <h1 className="title mb-45">Acesso restrito</h1>
                    <div className={`form-container`}>
                        <div className="span-12 lh-20 mb-30">
                            Digite a informação necessária para continuar:
                        </div>
                        {campoChave === 'cpf' && <div className="span-12 mb-20">
                            <InputForm label="CPF do beneficiário" required className="span-12" mask="###.###.###-##" value={form.cpf}
                                onClick={validCpf} name="cpf" model={(name, value) => {
                                    validCpf();
                                    setModel(name, value);
                                }}
                                error={validacao['cpf']}>
                                {<i className="icon icon-lock blue i-15" style={{ position: 'absolute', right: '18px', top: '17px' }} />}
                            </InputForm>
                        </div>}
                        {campoChave === 'crm' && <div className="grid col-12 sm-gap">
                            <InputForm label="UF" required className="span-4" type="select" value={form.uf} name="uf" model={setModel}
                                options={options.estados} error={validacao['uf']} />
                            <InputForm label="CRM/CRO" required className="span-8" mask="############" value={form.crm}
                                name="crm" model={setModel}
                                error={validacao['crm']}>
                                {<i className="icon icon-lock blue i-15" style={{ position: 'absolute', right: '18px', top: '17px' }} />}
                            </InputForm>
                        </div>}
                        {campoChave === 'cnpj' && <div className="flex sm-gap">
                            <InputForm label="CNPJ" required className="span-12" mask="##.###.###/####-##" value={form.cnpj}
                                name="cnpj" model={setModel}
                                error={validacao['cnpj']}>
                                {<i className="icon icon-lock blue i-15" style={{ position: 'absolute', right: '18px', top: '17px' }} />}
                            </InputForm>
                        </div>}
                    </div>
                    <div className="form-rodape mt-30 ">
                        <button className="btn btn-outline-primary f700-16" onClick={cancel}>
                            Cancelar
                        </button>
                        <button className="btn btn-primary f700-16" disabled={submitted} onClick={(e) => submit(e, form, setValidacao)}>
                            {submitted ? <Loading /> : 'Acessar'}
                        </button>
                    </div>
                </div>

            </div>
        </Modal>
    );
}
