import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { PatternFormat } from "react-number-format";
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async';
import '../../assets/styles/components/Form/InputForm.scss'
import layout from "../../lib/layout";
import tools from "../../lib/tools";
import Loading from "../Loading.js";
import CurrencyInput from 'react-currency-input-field';

function InputForm({ className = '', label, type = 'text', mask, model, onBlur, searchCodigo = 'id', searchLabel = 'nome', searchListRender,
  value = '', name, options = [], placeholder, onSearch, isMulti = true, disabled, onKeyPress,
  children, error, height = '60px', optionsAsync, defaultOptions,
  exibicao = false, maxlength = '', onChange, onResetSearch, listaSearch, required = undefined, limpar = false, autoComplete = false, onSelect, maxLength, maxDate, viewMore, childToParent }, ref) {
  const [inputError, setInputError] = useState(error);
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const id = (name) ? name : tools.uuidv4();
  const requiredField = (required !== undefined) ? "required" : false;
  useImperativeHandle(ref, () => ({
    clearSearch, setSearch
  }));
  const renderOutput = () => {
    value = (value !== null && value !== undefined) ? value : '';
    if (mask) {
      return value ? <PatternFormat displayType="text" value={value} format={mask} valueIsNumericString={true} /> : '-';
    } else if (type === 'select') {
      return layout.getLabel(options, value) || '-';
    } else if (type === 'date') {
      return value ? tools.formatDate(value) : '-';
    } else {
      return (value) ? value : '-';
    }
  }

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Nenhuma opção disponível</span>
      </components.NoOptionsMessage>
    );
  };

  const searchListRenderDefault = (item, key, label) => {
    return <div className="flex">
      {item[key]}
      <div className="f400-16 lines-3" style={{ color: '#ADADAD' }}>
        {item[label] || item?.nome || 'Vazio'}
      </div>
    </div>
  }

  useEffect(() => {
    setInputError(error);
  }, [id, name, type, error, value]);

  const renderInput = () => {
    value = (value === null) ? '' : value;
    if (exibicao) {
      return renderOutput();
    }
    if (mask) {
      return <PatternFormat format={mask} value={value} name={name} required={requiredField} valueIsNumericString={true}
        autoComplete="off"
        onValueChange={values => {
          if (model) model(name, values.value);
        }} onBlur={onBlur} onKeyUp={onKeyPress} />
    } else if (type === 'decimal') {
      return <CurrencyInput
        autoComplete="off"
        id={id}
        prefix={'R$ '}
        name={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        allowNegativeValue={false}
        allowDecimals={true}
        decimalsLimit={2}
        decimalScale={2}
        required={required}
        onValueChange={(value, name, e) => {
          let element = {
            target: {
              type: 'input',
              value: e.value
            }
          }
          handleInputChange(element);
        }}
      />;
    } else if (type === 'select') {
      return (<div>
        <select value={value} className="pointer" required={requiredField} disabled={disabled} name={name} onChange={handleInputChange} autoComplete="off">
          {!value && <option value="">{placeholder || `Selecione`}</option>}
          {(options || []).map((o, i) => {
            return <option value={o.id} key={'name-' + o.id + '-' + i} disabled={o?.active === false}>{o?.nome || o?.desc}</option>
          })}
        </select>
        {(!disabled && <i className="icon icon-down blue i-15" style={{ position: 'absolute', right: '18px', top: '17px', pointerEvents: "none" }} />)
          || (disabled && <i className="icon icon-lock light-gray i-15" style={{ position: 'absolute', right: '18px', top: '17px', pointerEvents: "none" }} />)}
      </div>
      )
    } else if (type === 'select-search') {
      return <Select required={requiredField} options={options} name={name} isClearable isSearchable isMulti={isMulti}
        components={{ NoOptionsMessage }}
        className="basic-single"
        classNamePrefix="select" placeholder={placeholder}
        autoComplete="off"
        onChange={handleSelectChange} defaultValue={value} />
    } else if (type === 'select-search-async') {
      return <AsyncSelect
        isDisabled={disabled}
        required={requiredField}
        name={name}
        isClearable
        isSearchable
        isMulti={isMulti}
        components={{ NoOptionsMessage }}
        className="basic-single"
        classNamePrefix="select"
        placeholder={placeholder}
        cacheOptions
        value={value}
        defaultOptions={defaultOptions}
        loadOptions={optionsAsync}
        loadingMessage={() => 'Buscando...'}
        autoComplete="off"
        onChange={handleSelectChange}
        defaultValue={value} />
    } else if (type === 'search') {
      return <div className="input-search relative">
        <div className="relative">
          <input disabled={disabled} placeholder={placeholder || ''} required={requiredField} id={id} type="text" name={name} defaultValue={value === undefined ? '' : value}
            onKeyUp={search} className={(lista.length) ? "search-open" : ""} onBlur={onBlur} onClick={search} autoComplete="off" onChangeCapture={onChange} />
          {(!loading && !value) && <i className={(disabled) ? "icon icon-search i-15 light-gray" : "icon icon-search i-15 blue pointer"} onClick={(disabled) ? () => {
            return false;
          } : clearList} />}
          {(!loading && !disabled && value && value?.trim()) && <i className="icon icon-cross-small i-15 blue pointer" onClick={clearSearch} />}
          {((value && disabled) && <i className="icon icon-lock light-gray i-15" style={{ position: 'absolute', right: '18px', top: '17px', pointerEvents: "none" }} />)}
          {loading && <Loading />}
        </div>
        <ul className="list-search" key={`list-search-${id}`}>
          {lista.map((l, i) => {
            return listaSearch ? listaSearch(l, i, setSearch) : (<li key={`${id}-${l.id}`} className="f600-18" onClick={() => setSearch(l)}>
              {(searchListRender && typeof searchListRender === 'function') ? searchListRender(l, searchCodigo, searchLabel) : searchListRenderDefault(l, searchCodigo, searchLabel)}
            </li>)
          })}
          {(lista?.length > 0 && viewMore) && <li key={`list-seemore-${id}`} className="list-seemore"><p className="text-center p-10 pointer" onClick={() => childToParent()}>Ver mais opções</p></li>}
        </ul>
      </div>
    } else if (type === 'textarea') {
      return <textarea required={requiredField} maxLength={maxLength} value={value} name={name} placeholder={placeholder} disabled={disabled} onChange={handleInputChange} style={{ height }} />
    } else {
      return <div><input required={requiredField} type={type} value={value} name={name} placeholder={placeholder}
        onChange={handleInputChange}
        disabled={disabled}
        maxLength={type === 'date' ? 6 : maxlength}
        max={maxDate}
        onBlur={onBlur}
        onFocus={(e) => {
          if (!autoComplete)
            e.target.removeAttribute('readonly');
        }}
        onKeyUp={onKeyPress}
        autoComplete="off"
        readOnly={!autoComplete} />
        {disabled && (<i className="icon icon-lock light-gray i-15" style={{ position: 'absolute', right: '18px', top: '17px', pointerEvents: "none" }} />)}
      </div>
    }
  }

  const search = async (e) => {
    if (searchLoad) {
      return;
    }
    setSearchLoad(true);
    setLoading(true)
    setTimeout(() => setSearchLoad(false), 100);
    if (typeof onSearch === 'function') {
      let lista = await onSearch(e);
      setLista(lista || []);
    }
    setLoading(false);
  }

  const setSearch = (l) => {
    setSearchLoad(true);
    setLista([]);
    model(l);
    let input = document.getElementById(id);
    input.value = limpar ? '' : (l[searchLabel] || l?.nome || l?.desc || '');
    setSearchLoad(false);
    if (onChange) {
      onChange(input.value);
    }
  }

  const clearSearch = () => {
    setSearchLoad(false);
    setInputError(null);
    setLoading(false);
    setLista([]);
    let input = document.getElementById(id);
    input.value = '';
    value = '';
    model(name, {});
    if (onResetSearch) {
      onResetSearch(input);
    }
  }

  const clearList = () => {
    setLista([]);
  }

  const validarData = (data) => {
    const dataFornecida = new Date(data);

    const dia = dataFornecida.getDate();
    const mes = dataFornecida.getMonth() + 1;
    const ano = dataFornecida.getFullYear();

    if (dia !== dataFornecida.getDate() ||
      mes !== dataFornecida.getMonth() + 1 ||
      ano !== dataFornecida.getFullYear()) {
      return false;
    }

    if (dataFornecida.getFullYear() > 2100) {
      return false;
    }

    return true;
  }

  const handleInputChange = ({ target }) => {
    let value = target.type === 'checkbox' ? target.checked : target.value;

    if (type === 'date') {

      if (value.trim().length > 10) {
        error = 'Digite uma data válida';
        value = value.substr(-10);
      } else if (!validarData(value)) {
        error = 'Digite uma data válida';
      } else if (value === "Invalid date") {
        error = 'Digite uma data válida';
        value = '';
      }

      setInputError(error);
    }

    if (model) {
      model(name, value);
    }
  }

  const handleSelectChange = (value) => {
    if (model) {
      model(name, value);
    }
    if (onSelect && typeof onSelect === 'function') {
      onSelect(name, value);
    }
  }

  return (
    <div className={`group-input f400-18 ${className} ${inputError ? 'is-error' : ''}`} id={`input-form-${id}`} key={`input-form-${id}`} autoComplete="off">
      {label && <label className="f300-14 black-text">
        {label}
      </label>}
      <div className="relative">
        {renderInput()}
        {children}
      </div>
      <div className="error fn-12">
        {inputError}
      </div>
    </div>
  );
}

export default forwardRef(InputForm);