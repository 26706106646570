import { setCookie } from "nookies";
import React, { useState } from 'react';
import '../../assets/styles/components/ToggleSwitch.scss'
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { MAX_AGE } from "../../pages/Login.js";
import business, { api } from "../../services/business.js";
import InputForm from "../Form/InputForm.js";
import Loading from "../Loading";
import { useEffect } from "react";
import tools from "../../lib/tools";
import { useMemo } from "react";

export default function LoginComponent({ isLogin }) {
  const navigate = useNavigate();
  const alert = useAlert();
  const [form, setForm] = useState({});
  const [validation, setValidation] = useState({});
  const [loginStatus, setLoginStatus] = useState(false);

  const urlParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);

  const setModel = (name, value) => {
    setForm({ ...form, ...{ [name]: value } });
  }

  useEffect(() => {
    const error = urlParams.get('error');

    if (parseInt(error) === 1) {
      alert.error('Você não tem as permissões necessárias para acessar este conteúdo.');
    }
  }, [alert, urlParams])

  const logar = async (e) => {
    setValidation({});
    e.preventDefault();
    let validated = true;
    let required = ['usuario', 'senha'], validacao = {};
    required.forEach(r => {
      if ([null, undefined, ''].includes(form[r])) {
        validacao[r] = 'Campo Obrigátorio';
        validated = false;
      }
    })
    setValidation(validacao);

    if (validated) {
      setLoginStatus(true);
      const { response, errors } = await business.auth(form);
      let token = response?.token;
      if (token && !errors.length) {
        setCookie(undefined, 'token', token, { maxAge: MAX_AGE });
        api.defaults.headers['Authorization'] = `Bearer ${token}`;
        localStorage.setItem('user', JSON.stringify({
          id: response.id,
          nome: response.nome,
          tipo: response?.tipo ?? null,
          tipo_desc: response.tipo_desc ?? null,
          id_operadora: response.id_operadora ?? null,
          desc_operadora: response.desc_operadora ?? null,
          email: response.email,
          foto: ""
        }))
        localStorage.setItem('permissoes', JSON.stringify(response?.permissoes || []));
        localStorage.setItem('config', JSON.stringify(response?.config || []));
        document.body.classList.remove('no-croll');
        const error = urlParams.get('error');
        const redirect = urlParams.get('redirect');
        if (redirect && !error) {
          tools.openURL(redirect);
        } else {
          navigate("/");
        }

      } else {
        setLoginStatus(false);
        (errors || ["Ocorreu um erro ao tentar realizar o login"]).forEach(e => alert.error(e));
      }
    }
  }
  return (
    <div className="form-login">
      <h1 className="f800-40 mb-15">Login</h1>
      <p className="f400-16">Bem-vindos. Welcome. Tervetuloa.</p>
      <div className="form-container grid col-1 w-100 mt-75">
        <InputForm label="Usuário" className="span-1" placeholder="Seu email" autoComplete={true}
          value={form.usuario} error={validation.usuario} name="usuario" model={setModel} onKeyPress={e => {
            if (e.key === 'Enter') {
              logar(e);
            }
          }} />
        <div className="span-1 flex fd-fc gap-15 ai-fe">
          <InputForm label="Senha" type="password" placeholder="Sua senha" autoComplete={true}
            value={form.senha} error={validation.senha} name="senha" model={setModel} onClick={logar} onKeyPress={e => {
              if (e.key === 'Enter') {
                logar(e);
              }
            }} />
          <span className="blue-text f400-14 pointer" onClick={() => isLogin(false)}>Esqueci a senha</span>
        </div>
      </div>
      <div className="flex ai-fc jc-fe mt-100 gap-15 btn-login">
        {loginStatus && <Loading />}
        <button className="btn btn-primary f700-16 jc-sb"
          onClick={logar} style={{ width: '150px' }} disabled={loginStatus}>
          Entrar <i className="icon icon-arrow-left rotate-180 i-20" />
        </button>
      </div>
    </div>
  );
};

