import React from 'react';
import "../../assets/styles/pages/Ajuda/Docs.scss";
import config from '../../config/config';
import UnauthorizedPage from '../401';

export default function DocsOperacionais() {
    document.title = 'säästo';
    return (<div className="page-iframe">
        {[1, 4, 5, 7, 8].includes(parseInt(config.user.tipo)) ? <div className="iframe-wrapper">
            <iframe src="https://sites.google.com/saasto.com.br/documentos-operacionais" title="Documentos Operacionais"></iframe>
        </div> : <UnauthorizedPage />}
    </div>);
}