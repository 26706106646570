import React, { useEffect } from 'react';
import { useState } from 'react';
import config from '../../config/config';
import InputForm from '../Form/InputForm';
import Modal from '../Modal';
import ToggleSwitch from '../ToggleSwitch';

export default function ModalConfig({ hideModal, show }) {


    const [form, setForm] = useState([])

    const setModel = (name, value) => {
        setForm({ ...form, ...{ [name]: value } });
    }

    const setModelCheck = (name, checked) => {
        setForm({ ...form, ...{ [name]: (checked) ? 'on' : 'off' } });
    }

    const salvar = (e) => {
        const body = document.body;
        if (config.salvarConfiguracao(form)) {
            body.classList.remove('overflow-hidden');
            hideModal(e);
        }
    }

    const handleClose = (e) => {
        const body = document.body;
        body.classList.remove('overflow-hidden');
        hideModal(e);
    }

    useEffect(() => {
        (() => {
            setForm(config.config)
        })();
        // eslint-disable-next-line
    }, [config?.config]);

    return (
        <Modal show={show} handleClose={handleClose} style={{ maxWidth: '1000px', maxHeight: '600px' }} modalClass="modal-cad-usuario">
            <div className="flex">
                <div className="form w-100 mb-50">
                    <h1 className="title mb-45">Configuração</h1>
                    <div className={`form-container grid col-12`}>
                        <div className="span-12">
                            <h1 className="subtitle">Simulador</h1>
                        </div>
                        <div className="span-4">
                            <InputForm label="Data atual" type="date" className="span-3"
                                value={form['simulador:data_atual']}
                                name="simulador:data_atual"
                                model={setModel}
                                exibicao={false}
                                {...(form['simulador:data_atual:status'] !== 'on' ? { disabled: true } : {})}
                            />
                        </div>
                        <div className="span-4">
                            <InputForm label="Hora atual" type="time" className="span-3"
                                value={form['simulador:hora_atual']}
                                name="simulador:hora_atual"
                                model={setModel}
                                exibicao={false}
                                {...(form['simulador:data_atual:status'] !== 'on' ? { disabled: true } : {})}
                            />
                        </div>
                        <div className="span-4 mt-30">
                            <div className="fn-16 linha flex jc-sb">
                                <span>
                                    <ToggleSwitch type="checkbox" id="data_atual" name="simulador:data_atual:status" value={(form['simulador:data_atual:status'] === 'on') ? true : false} texto={['Ativo', 'Inativo']} style={{ '--width': '110px' }}
                                        model={setModelCheck} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="form-rodape mt-30 ">
                        <button className="btn btn-outline-primary f700-16" onClick={handleClose}>
                            Cancelar
                        </button>
                        <button className="btn btn-primary f700-16 mr-40" onClick={salvar}  >
                            Salvar
                        </button>
                    </div>
                </div>

            </div>
        </Modal>
    );
}
