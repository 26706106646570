import React from 'react';
import { useState } from 'react';
import '../assets/styles/components/ToggleSwitch.scss'
import Loading from './Loading';

export default function ToggleSwitch({ id, value, disabled = false, style, texto = [], model, name }) {

  const [loader, setLoader] = useState(false);
  //const [inputValue, setInputValue] = useState(value);

  const handleInputChange = async (event) => {
    if (loader) {
      return false;
    }
    const target = event.target;
    const value_temp = target.type === 'checkbox' ? target.checked : target.value;
    setLoader(true);
    if (model) await model(name, value_temp);
    //setInputValue(value_temp);
    setLoader(false);
  }

  return (
    <label className="toggle" style={style}>
      <input type="checkbox" id={id} checked={value} disabled={disabled || loader} onChange={handleInputChange} />
      <span className="slider"></span>
      <span className="labels" data-on={texto[0]} data-off={texto[1]}></span>
      {((loader) ? <Loading /> : '')}
    </label>
  );
};

