import React, { useEffect, useState } from "react";
import moment from "moment";
import tools from "../../lib/tools";
import { useParams } from "react-router-dom";
import business from "../../services/business";
import Loading from "../../components/Loading";

export default function DocConsenso() {

    let { id_processo } = useParams();
    document.title = 'säästo';
    const [processo, setProcesso] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        async function getProcess() {
            setProcesso(await business.getProcesso(id_processo))
            setLoading(false);
        }
        getProcess();
    }, [id_processo]);

    const data = {
        protocolo: processo?.id_protocolo,
        operadora_nome: processo?.desc_operadora,
        operadora_endereco: processo?.endereco_operadora?.logradouro + ', ' + processo?.endereco_operadora?.numero + ', ' + processo?.endereco_operadora?.bairro + ', ' + processo?.endereco_operadora?.cidade + ' - ' + processo?.endereco_operadora?.uf,
        operadora_telefone: processo?.endereco_operadora?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3") || 'Telefone indisponível',
        operadora_imagem: processo?.endereco_operadora?.imagem,
        data_disparo: moment(processo?.data_validacao).format('LL'),
        profissional_nome: processo?.profissional?.nome,
        profissional_crmcro: processo?.profissional?.crm,
        profissional_uf: processo?.profissional?.uf,
        profissional_telefone: processo?.profissional?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3"),
        data_atendimento: moment(processo?.data_atendimento).format('DD/MM/YYYY'),
        beneficiario_nome: processo?.beneficiario?.nome,
        beneficiario_cpf: processo?.beneficiario?.cpf,
        beneficiario_sexo: processo?.beneficiario?.sexo === 'M' ? 'masculino' : 'feminino',
        beneficiario_guia: processo?.guia,
        beneficiario_nascimento: processo?.beneficiario?.nascimento,
        beneficiario_idade: tools.calcularIdade(processo?.beneficiario?.nascimento),
        auditor_nome: processo?.auditor?.nome,
        auditor_crmcro: processo?.auditor?.crm,
        auditor_uf: processo?.auditor?.uf,
        assistente_responsavel_nome: processo?.responsaveis?.nome_assistente,
        enfermeira_responsavel_nome: processo?.responsaveis?.nome_enfermeira,
        desempatador_responsavel_nome: processo?.responsaveis?.nome_desempatador,
        desempatador_responsavel_crmcro: processo?.responsaveis?.crm_desempatador || processo?.responsaveis?.cro_desempatador,
        desempatador_responsavel_uf: processo?.responsaveis?.crm_uf_desempatador || processo?.responsaveis?.cro_uf_desempatador,
        desempatador_responsavel_laudo: processo?.texto_parecer,
        data_consenso: moment(tools.getDatetime()).format('LL'),
        data_consenso_min: moment(tools.getDatetime()).format('DD/MM/YYYY'),
        procedimentos: (processo?.procedimentos || []).map((p) => {
            return {
                id: p.id_procedimento,
                codigo: p?.codigo || '-',
                descricao: p.desc_procedimento,
                quantidade_solicitada: p.quantidade_solicitada || 0,
                quantidade_autorizada: p.quantidade_aprovada || 0,
                quantidade_pos_analise: p.quantidade_pos_analise || 0,
                divergencia: p?.desc_processo_motivo_divergencia || '-',
                justificativa: p.observacao || '-',
                justificativa_pos_analise: p.justificativa_analise || '-'
            };
        }),
        materiais: (processo?.materiais || []).map((m) => {
            return {
                id: m.id_material,
                descricao: m.desc_material,
                quantidade_solicitada: m.quantidade_solicitada || 0,
                quantidade_autorizada: m.quantidade_aprovada || 0,
                quantidade_pos_analise: m.quantidade_pos_analise || 0,
                divergencia: m?.desc_processo_motivo_divergencia || '-',
                justificativa: m.observacao || '-',
                justificativa_pos_analise: m.justificativa_analise || '-'
            };
        }),
        desempatadores: processo?.desempatadores,
        id_desempatador: processo?.responsaveis?.id_desempatador || 0,
        notificacao_envio: processo?.notificacao_envio || {},
        exames_complementares: [],
    }

    return (
        <div> {loading ? (<div className="p-30 flex"><Loading /> <span className="pl-10">Aguarde, carregando comunicado de consenso...</span></div>) :
            <div className="print-email p-40">
                <div className="float-right" id="no-print">
                    <button className="btn btn-primary f700-16 jc-sb" onClick={() => (window.print())}>
                        Imprimir <i className="icon icon-print rotate-180 i-20 ml-10" />
                    </button>
                </div>
                <br />
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {data.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {data.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {data.operadora_telefone}</p>
                </div>
                <div className="w-100">
                    <p className="text-center mt-30 mb-30 fb-18">COMUNICADO DE CONSENSO TÉCNICO</p>
                </div>
                <div className="w-100">
                    <div className="text-right mb-10">{data.data_parecer}</div>
                </div>
                <br />

                <p>Informamos que, após análise da solicitação de procedimentos, exames, materiais e/ou medicamentos feita em {data.data_atendimento} para o(a) beneficiário (a) <b>{data.beneficiario_nome}</b>, guia <b>{data.beneficiario_guia}</b>, ID <b>{data.protocolo}</b>,
                    houve consenso técnico com o(a) Dr.(a) <b>{data.profissional_nome}</b>, CRM (ou CRO): <b>{data.profissional_crmcro}</b> - <b>{data.profissional_uf}</b> para as seguintes divergências:</p>

                <div className="w-100">
                    <p className="text-center mt-30 mb-20 fb-16">REGISTRO DE CONSENSO | PROCEDIMENTOS</p>
                </div>
                {data.procedimentos ?
                    <div className="w-100 grid">
                        <table>
                            <tbody style={{ width: '100%' }}>
                                <tr style={{ width: '100%' }}>
                                    <th style={{ width: '15%' }}>Código</th>
                                    <th style={{ width: '45%' }}>Descrição</th>
                                    <th style={{ width: '20%' }}>Quantidade Solicitada</th>
                                    <th style={{ width: '20%' }}>Quantidade Pós Consenso</th>
                                </tr>
                            </tbody>
                            {data.procedimentos.map(procedimento => {
                                return <tbody key={procedimento.id}>
                                    <tr>
                                        <td>{procedimento.codigo}</td>
                                        <td>{procedimento.descricao}</td>
                                        <td>{procedimento.quantidade_solicitada}</td>
                                        <td>{(procedimento.quantidade_pos_analise === "0" || procedimento.quantidade_pos_analise === 0) ? procedimento.quantidade_autorizada : procedimento.quantidade_pos_analise}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" className="text-justify"><b>Justificativa: </b>{[11, 17].includes(procedimento.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : (procedimento.justificativa_pos_analise !== "-" ? procedimento.justificativa_pos_analise : procedimento.justificativa)}</td>
                                    </tr>
                                </tbody>
                            })}
                        </table></div> : ''}
                {data.materiais.length ? <div className="w-100">
                    <p className="text-center mt-30 mb-20 fb-16">REGISTRO DE CONSENSO | MATERIAIS</p>
                </div> : ''}
                {data.materiais.length ?
                    <div className="w-100 grid">
                        <table>
                            <tbody style={{ width: '100%' }}>
                                <tr style={{ width: '100%' }}>
                                    <th style={{ width: '60%' }}>Descrição</th>
                                    <th style={{ width: '20%' }}>Quantidade Solicitada</th>
                                    <th style={{ width: '20%' }}>Quantidade Pós Consenso</th>
                                </tr>
                            </tbody>
                            {data.materiais.map(material => {
                                return <tbody key={material.id}>
                                    <tr>
                                        <td>{material.descricao}</td>
                                        <td>{material.quantidade_solicitada}</td>
                                        <td>{(material.quantidade_pos_analise === "0" || material.quantidade_pos_analise === 0) ? material.quantidade_autorizada : material.quantidade_pos_analise}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" className="text-justify"><b>Justificativa: </b>{[21, 28].includes(material.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : (material.justificativa_pos_analise !== "-" ? material.justificativa_pos_analise : material.justificativa)}</td>
                                    </tr>
                                </tbody>
                            })}
                        </table></div> : ''}
                <br />
                <p>Em caso de dúvidas, retorne este e-mail ou fale conosco pelo WhatsApp (48) 99201-4736.</p>
                <br />
                <div className="w-100 text-justify bold pb-50">
                    <p>Atenciosamente,</p>
                    <p>Dr. (a) {data.auditor_nome}, CRM (ou CRO): {data.auditor_crmcro} - {data.auditor_uf}.</p>
                    <p>Dados para contato: {data.operadora_telefone}</p>

                </div>
            </div>}
        </div>
    );
}
