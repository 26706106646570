import React, { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../Loading';
import Modal from '../Modal';

export default function ModalAlert({ title, text, onClose, onAccept, show = false, buttonCancel = true, setModel = false }) {

    const [accepted, setAccepted] = useState(false);

    const accept = async (e) => {
        setAccepted(true);
        if (onAccept)
            await onAccept(e);
        if (setModel && typeof setModel === 'function')
            await setModel({
                show: false
            });
        setAccepted(false);
    }

    const cancel = async (e) => {
        if (onClose && typeof onClose === 'function')
            await onClose(e);
        if (setModel && typeof setModel === 'function')
            await setModel({
                show: false
            });
        setAccepted(false);
    }

    useEffect(() => { }, [title, text, show])

    return (
        <Modal show={show} handleClose={cancel} style={{ maxWidth: '700px', maxHeight: '600px' }} modalClass="modal-cad-usuario">
            <div className="flex">
                <div className="form w-100 mb-40">
                    <h1 className="title mb-30">{title || 'Atenção'}</h1>
                    <div className={`form-container flex`}>
                        <p className="f300-20">{text || 'Deseja prosseguir com esta ação?'}</p>
                    </div>
                    <div className="form-rodape mt-30 ">
                        {buttonCancel ? <button className="btn btn-outline-primary f700-16" onClick={cancel}>
                            Cancelar
                        </button> : <div />}
                        <button className="btn btn-primary f700-16 mr-40" onClick={accept} disabled={accepted} >
                            {(accepted) ? <Loading /> : 'OK'}
                        </button>
                    </div>
                </div>

            </div>
        </Modal>
    );
}
