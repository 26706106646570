//import Cep from "cep-promise";
import React from 'react';
import Email from '../Email';

export default function SolExamesComplementaresOPS({ resp = {}, notify = false, print = false, viewHeader = false }) {

    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
            {viewHeader && <div className="w-100 mb-20">
                <p className="mb-0"><b>De:</b> {notify?.disparo}</p>
                <p className="mb-0"><b>Para:</b> {notify?.canalOPS}</p>
                <p className="mb-0"><b>Assunto:</b> SOLICITAÇÃO DE EXAMES COMPLEMENTARES</p>
                <p className="mb-0"><b>Data:</b> {notify?.data_disparo}</p>
                <hr />
            </div>}
            <div className="flex">
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {resp.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {resp.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {resp.operadora_telefone}</p>
                </div>
                <img className="image-ops" src={resp?.operadora_imagem} alt='Operadora'></img>
            </div>
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">SOLICITAÇÃO DE AGENDAMENTO DE EXAMES COMPLEMENTARES</p>
            </div>
            <div className="w-100">
                <div className="text-right mb-10">{notify.data_format ? notify.data_format : resp.data_disparo}</div>
            </div>
            <p className="mb-10">
                <br />
            </p>
            <p>Informamos que o profissional desempatador, Dr.(a) <b>{resp.desempatador_responsavel_nome}</b>, CRM (ou CRO): <b>{resp.desempatador_responsavel_crmcro} - {resp.desempatador_responsavel_uf}</b> que decidirá sobre a(s) divergência(s) do processo de Junta Médica ou Odontológica, solicita a realização dos seguintes exames complementares:</p>

            <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16"></p>
            </div>
            {resp.materiais ?
                <div className="w-100 grid">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '80%' }}>Exames solicitados</th>
                                <th style={{ width: '20%' }}>Quantidade</th>
                            </tr>
                        </tbody>
                        {resp.exames_complementares.map(exame => {
                            return <tbody key={exame.id}>
                                <tr>
                                    <td>{exame.descricao}</td>
                                    <td>{exame.quantidade}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}
            <br />

            <p>Solicitamos que após a realização do agendamento no Prestador, nos seja informado o local, a data e a hora para realização dos exames. Reiteramos que, conforme estabelece a RN 424/2017 - Art.15º parágrafo 4, os exames solicitados pelo profissional desempatador deverão ser cobertos pela operadora sem a necessidade de autorização prévia, observada a segmentação contratada.</p>

            <p>Lembramos ainda que nesses casos de solicitação de exames complementares, os prazos da garantia de atendimento (RN 566/2022) ficam suspensos por apenas 3 (três) dias úteis, conforme RN 424/17 - Art.4º parágrafo 2. Por isso, sugerimos que o agendamento dos mesmos seja feito com celeridade.</p>

            <p>Após a realização dos exames, aguardamos o recebimento, via e-mail <b>jmo@saasto.com.br</b>, dos resultados e laudos para darmos continuidade ao processo. </p>

            <br />

            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>Equipe säästö.</p>
            </div>
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de envio de comunicação a operadora de saúde via {notify?.canal}: {notify?.canalOPS} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            <br />
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação por operadora de saúde via {notify?.canal}: {notify?.canalOPS} - {notify?.data_leitura} - IP: {notify?.ip} / {notify?.navegador} / {notify?.sistema_operacional} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}
