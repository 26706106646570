import React from 'react';
import "../../assets/styles/pages/Ajuda/Docs.scss";
import img1 from "../../assets/images/faq-docs/desempatador-manual-tecnico/desempatador-manual-tecnico_page-0001.jpg"
import img2 from "../../assets/images/faq-docs/desempatador-manual-tecnico/desempatador-manual-tecnico_page-0002.jpg"
import img3 from "../../assets/images/faq-docs/desempatador-manual-tecnico/desempatador-manual-tecnico_page-0003.jpg"
import img4 from "../../assets/images/faq-docs/desempatador-manual-tecnico/desempatador-manual-tecnico_page-0004.jpg"
import img5 from "../../assets/images/faq-docs/desempatador-manual-tecnico/desempatador-manual-tecnico_page-0005.jpg"
import img6 from "../../assets/images/faq-docs/desempatador-manual-tecnico/desempatador-manual-tecnico_page-0006.jpg"
import img7 from "../../assets/images/faq-docs/desempatador-manual-tecnico/desempatador-manual-tecnico_page-0007.jpg"
import img8 from "../../assets/images/faq-docs/desempatador-manual-tecnico/desempatador-manual-tecnico_page-0008.jpg"
import UnauthorizedPage from '../401';
import config from '../../config/config';

export default function DesempatadorManualTecnico() {
    document.title = 'säästo';
    return (<div className="page-docs">
        {[1, 6].includes(parseInt(config.user.tipo)) ? <div className="doc-wrapper">
            <img src={img1} alt="Página 1" />
            <img src={img2} alt="Página 2" />
            <img src={img3} alt="Página 3" />
            <img src={img4} alt="Página 4" />
            <img src={img5} alt="Página 5" />
            <img src={img6} alt="Página 6" />
            <img src={img7} alt="Página 7" />
            <img src={img8} alt="Página 8" />
        </div> : <UnauthorizedPage />}
    </div>);
}