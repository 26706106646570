import React, { useEffect } from "react";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import Pie from "../../Pie.js";
import '../../../assets/styles/components/Processo/Status.scss'
import tools from "../../../lib/tools.js";

export default function Etapa({ item }) {

  const id = tools.uuidv4();
  const { buttonProps/*, isOpen*/ } = useDropdownMenu(1);
  const onclick = buttonProps.onClick;

  buttonProps.onClick = (e) => {
    position();
    onclick(e);
  }

  const position = () => {
    let container = document.getElementById(`container-status`);
    let body = document.scrollingElement;
    let element = document.getElementById(`${id}-detalhes`);
    let status = document.getElementById(`${id}-status`);
    let offset = tools.getOffset(status);
    if (element) {
      element.style.top = (offset.top + 85 - body.scrollTop) + 'px';
      element.style.left = (offset.left - container.scrollLeft) + 'px';
    }
  }

  window.addEventListener('scroll', (e) => {
    position();
  });

  useEffect(() => { }, [item]);

  return (
    <div className="junta-status">
      <div id={`${id}-status`} className={`status flex ai-fc gap-20`} {...buttonProps}>
        <div className="progress-status">
          <Pie percentage={item.porcentagem} colour={item.porcentagem === 100 ? "#17E92C" : "#5B8FF9"}
            background="#E4E4E4" strokeWidth={4} size={38} text={{ label: ' ', className: 'f800-18' }} />
          {item.porcentagem === 100 && <div className="pie-cicle">
            <i className="icon icon-check i-15 white" />
          </div>}
        </div>
        <div className="flex fd-fc f400-16">
          {item.nome}
          <span className="f400-12">{item.descricao}</span>
        </div>
      </div>
      {/*<div id={`${id}-detalhes`} className={`dropdown-submenu ${isOpen && 'visible'} flex fd-fc ai-fc`} role='menu'>

        <Pie percentage={0} colour="#5B8FF9" background="#F7F7F7" strokeWidth={6} size={70} text={{ className: 'f400-16 text-blue', label: '48h' }} />

        <div className="pv-25 f400-14 flex ai-fc gap-20">
          <div className="circle" />
          Aguardando preenchimento de dados
        </div>

        <p className="f400-12 mt-15">Duis ac ligula tellus. Donec sed ultrices arcu, a tempor nunc. In vehicula efficitur euismod. Nullam eget
          dolor volutpat, iaculis mauris in, varius est.</p>
  </div>*/}
    </div>
  );
}

