import React from 'react';
import "../../assets/styles/pages/Ajuda/Docs.scss";
import img1 from "../../assets/images/faq-docs/operadora-etapas-status/operadora-etapas-status_page-0001.jpg"
import img2 from "../../assets/images/faq-docs/operadora-etapas-status/operadora-etapas-status_page-0002.jpg"
import img3 from "../../assets/images/faq-docs/operadora-etapas-status/operadora-etapas-status_page-0003.jpg"
import img4 from "../../assets/images/faq-docs/operadora-etapas-status/operadora-etapas-status_page-0004.jpg"
import UnauthorizedPage from '../401';
import config from '../../config/config';

export default function OperadoraEtapasStatus() {
    document.title = 'säästo';
    return (<div className="page-docs">
        {[2, 3].includes(parseInt(config.user.tipo)) ? <div className="doc-wrapper">
            <img src={img1} alt="Página 1" />
            <img src={img2} alt="Página 2" />
            <img src={img3} alt="Página 3" />
            <img src={img4} alt="Página 4" />
        </div> : <UnauthorizedPage />}
    </div>);
}