//import Cep from "cep-promise";
import React from 'react';
import Email from '../Email';

export default function ComAberturaJuntaBNF({ resp = {}, notify = false, print = false, viewHeader = false }) {

    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
            {viewHeader && <div className="w-100 mb-20">
                <p className="mb-0"><b>De:</b> {notify?.disparo}</p>
                <p className="mb-0"><b>Para:</b> {notify?.canalBNF}</p>
                <p className="mb-0"><b>Assunto:</b> COMUNICADO DE ABERTURA DE JUNTA MÉDICA OU ODONTOLÓGICA</p>
                <p className="mb-0"><b>Data:</b> {notify?.data_disparo}</p>
                <hr />
            </div>}
            <div className="flex">
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {resp.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {resp.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {resp.operadora_telefone}</p>
                </div>
                <img className="image-ops" src={resp?.operadora_imagem} alt='Operadora'></img>
            </div>
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">COMUNICADO DE ABERTURA DE JUNTA MÉDICA OU ODONTOLÓGICA</p>
            </div>
            <div className="w-100">
                <div className="text-right mb-10">{notify.data_format ? notify.data_format : resp.data_disparo}</div>
            </div>
            <p className="mb-10">
                Olá, <b>{resp.beneficiario_nome}</b>, CPF <b>{resp.beneficiario_cpf}</b>.
            </p>

            <p>Recebemos uma solicitação para autorização de procedimentos, exames, materiais e/ou medicamentos em <b>{resp.data_atendimento}</b>, realizada pelo(a) profissional assistente Dr.(a) <b>{resp.profissional_nome}</b>, CRM (ou CRO): <b>{resp.profissional_crmcro}</b> - <b>{resp.profissional_uf}</b>, referente guia <b>{resp.beneficiario_guia}</b>, ID <b>{resp.protocolo}</b> e informamos que houve divergência entre a solicitação.</p>

            <p>A abertura de Junta Médica ou Odontológica ocorre sempre que há divergências entre as opiniões do profissional auditor e do profissional de saúde que assiste o(a) beneficiário(a) quanto à indicação dos itens solicitados.</p>

            <p>Nesses casos é garantido ao(à) paciente o direito de ter seu caso analisado por um profissional desempatador, que decide sobre a(s) divergência(s).</p>

            <p>Após análise da {resp.operadora_nome}, representada pelo Dr.(a) <b>{resp.auditor_nome}</b>, CRM (ou CRO): <b>{resp.auditor_crmcro}</b>-<b>{resp.auditor_uf}</b> informamos as seguintes divergências nas indicações:</p>


            <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">ANÁLISE DA OPERADORA | PROCEDIMENTOS/EXAMES</p>
            </div>
            {resp.procedimentos ?
                <div className="w-100 grid mail-visible-desktop">
                    <table style={{ verticalAlign: 'middle' }}>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '15%' }}>Código</th>
                                <th style={{ width: '45%' }}>Descrição</th>
                                <th style={{ width: '10%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '10%' }}>Quantidade Autorizada</th>
                                <th style={{ width: '10%' }}>Possui divergência</th>
                                <th style={{ width: '10%' }}>Tipo de divergência</th>
                            </tr>
                        </tbody>
                        {(resp.procedimentos || []).map(procedimento => {
                            return <tbody key={procedimento.id}>
                                <tr>
                                    <td>{procedimento.codigo}</td>
                                    <td>{procedimento.descricao}</td>
                                    <td>{procedimento.quantidade_solicitada}</td>
                                    <td>{procedimento.quantidade_autorizada}</td>
                                    <td>{[11, 17].includes(procedimento.id_divergencia) ? 'NÃO' : 'SIM'}</td>
                                    <td>{procedimento.divergencia}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{[11, 17].includes(procedimento.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : procedimento.justificativa}</td>
                                </tr>
                            </tbody>
                        })}

                    </table></div> : ''}

            <div className="mail-visible-mobile nao-imprimir">
                {(resp.procedimentos && !print) ? resp.procedimentos.map((procedimento, i) => {
                    i++
                    return <div key={procedimento.id}>
                        <ul className="mt-20">
                            <li className="mail-li">Item {i}:</li>
                            <li className="mail-li">Código: <b>{procedimento.codigo}</b></li>
                            <li className="mail-li">Descrição:  <b>{procedimento.descricao}</b></li>
                            <li className="mail-li">Quantidade Solicitada:  <b>{procedimento.quantidade_solicitada}</b></li>
                            <li className="mail-li">Quantidade Autorizada:  <b>{procedimento.quantidade_autorizada}</b></li>
                            <li className="mail-li">Possui divergência:  <b>{[11, 17].includes(procedimento.id_divergencia) ? 'NÃO' : 'SIM'}</b></li>
                            <li className="mail-li">Tipo de divergência:  <b>{procedimento.divergencia}</b></li>
                            <li className="mail-li">Justificativa:  <b>{[11, 17].includes(procedimento.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : procedimento.justificativa}</b></li>
                        </ul>
                    </div>
                }) : ''}
            </div>

            {resp.materiais.length ? <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">ANÁLISE DA OPERADORA | MATERIAIS</p>
            </div> : ''}
            {resp.materiais.length ?
                <div className="w-100 grid mail-visible-desktop">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '60%' }}>Descrição</th>
                                <th style={{ width: '10%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '10%' }}>Quantidade Autorizada</th>
                                <th style={{ width: '10%' }}>Possui divergência</th>
                                <th style={{ width: '10%' }}>Tipo de divergência</th>
                            </tr>
                        </tbody>
                        {(resp.materiais || []).map(material => {
                            return <tbody key={material.id}>
                                <tr>
                                    <td>{material.descricao}</td>
                                    <td>{material.quantidade_solicitada}</td>
                                    <td>{material.quantidade_autorizada}</td>
                                    <td>{[21, 28].includes(material.id_divergencia) ? 'NÃO' : 'SIM'}</td>
                                    <td>{material.divergencia}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" className="text-justify"><b>Justificativa: </b>{[21, 28].includes(material.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : material.justificativa}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}

            <div className="mail-visible-mobile nao-imprimir">
                {(resp.materiais && !print) ? resp.materiais.map((materiais, i) => {
                    i++
                    return <div key={materiais.id}>
                        <ul className="mt-20">
                            <li className="mail-li">Item {i}:</li>
                            <li className="mail-li">Descrição:  <b>{materiais.descricao}</b></li>
                            <li className="mail-li">Quantidade Solicitada:  <b>{materiais.quantidade_solicitada}</b></li>
                            <li className="mail-li">Quantidade Autorizada:  <b>{materiais.quantidade_autorizada}</b></li>
                            <li className="mail-li">Possui divergência:  <b>{[21, 28].includes(materiais.id_divergencia) ? 'NÃO' : 'SIM'}</b></li>
                            <li className="mail-li">Tipo de divergência:  <b>{materiais.divergencia}</b></li>
                            <li className="mail-li">Justificativa:  <b>{[21, 28].includes(materiais.id_divergencia) ? 'Autorizado previamente pela operadora de saúde. Sem divergência.' : materiais.justificativa}</b></li>
                        </ul>
                    </div>
                }) : ''}
            </div>

            <br />

            <p>O profissional assistente do seu caso, que fez a solicitação dos itens, já foi comunicado sobre a(s) divergência(s) e poderá acatar a análise da {resp.operadora_nome}, finalizando este processo como Consenso.</p>
            <p>Caso ele não concorde, você tem o direito de ter seu caso analisado por um profissional desempatador, que será escolhido pelo seu profissional assistente para decidir sobre a(s) divergência(s) em uma Junta Médica ou Odontológica.</p>
            <p>O profissional assistente tem <b>até 2 dias úteis</b>, a contar da ciência dele sobre o início deste processo, para a escolha do desempatador dentre um dos profissionais listados abaixo. Se dentro desse prazo ele não sinalizar opção por nenhum dos profissionais sugeridos ou abster-se, a {resp.operadora_nome} fará a escolha: </p>
            <br />
            {(resp?.desempatadores || []).map((desempatador, i) => {
                i++;
                return <div key={desempatador.id}>
                    <p><b>{i}ª opção: Dr.(a) {desempatador.nome}  – CRM/CRO: {desempatador.crm || desempatador.cro} – {desempatador.crm_uf || desempatador.cro_uf}:</b><br /> {desempatador.descricao}</p><br />
                    <br /><br /></div>
            })}

            <p className="underline"><b>Observações importantes:</b></p>

            <p>- Informamos que o beneficiário ou o médico assistente deverão apresentar os documentos e exames que fundamentaram a solicitação do procedimento.</p>

            <p>- Salientamos que se houver a necessidade de realização de uma Junta Médica ou Odontológica presencial, que será definida pelo o profissional desempatador, e você se ausentar, sem comunicar sua impossibilidade de participação com antecedência, a {resp.operadora_nome} passa a ser desobrigada de cobrir as solicitações em questão.</p>

            <p>Em caso de dúvidas, entre em contato com seu o profissional assistente ou, se preferir, retorne este e-mail ou fale conosco pelo Whastapp (48) 99201-4736.</p>

            <br />
            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>{resp.auditor_nome}, CRM (ou CRO): {resp.auditor_crmcro} - {resp.auditor_uf}.</p>
                <p>Dados para contato: {resp.operadora_telefone}</p>
            </div>
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de envio de comunicação ao beneficiário via {notify?.canal}: {notify?.canalBNF} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            <br />
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação pelo beneficiário via {notify?.canal}: {notify?.canalBNF} - {notify?.data_leitura} - IP: {notify?.ip} / {notify?.navegador} / {notify?.sistema_operacional} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}
