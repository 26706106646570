import React from "react";

export default function ProgressBarList({ className, slim, list = [], initlabel, label }) {

  const containerStyles = {
    height: slim || 20,
    width: '100%',
    backgroundColor: "#F7F7F7",
    borderRadius: 8,
    margin: 0,
    position: 'relative',
    cursor: 'help'
  }

  const fillerStyles = {
    height: '100%',
    borderRadius: 'inherit',
    textAlign: 'left',
    position: 'absolute',
    top: 0
  }

  return (<div className={`progress-bar ${className}`}>
    <div className="fb-14 text-right" style={{ width: '100px' }}>{initlabel}</div>
    <div style={containerStyles}>
      {list.map((p, i) => {
        return <div key={i} title={`${p.title}`} style={{ ...fillerStyles, ...{ width: `${p.completed}%`, backgroundColor: p.bgcolor, borderRadius: p.borderRadius, left: p.left } }} />
      })}
    </div>
    <div className="fb-14 text-center">{label}</div>
  </div>
  );

}
