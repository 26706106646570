import React from 'react';
import { useEffect } from 'react';
import config from './config/config';
import Routes from './config/routes';

function App() {

  useEffect(() => {
    // auto ajust pixel ratio (windows 11)
    if (window?.devicePixelRatio && Math.round(window.devicePixelRatio * 100) === 125) {
      document.body.style.zoom = 0.98
    }
    
    config.load();
  }, []);

  return (
    <div className="app">
      <Routes />
    </div>
  );
}

export default App;
