//import Cep from "cep-promise";
import React from 'react';
import Email from '../Email';

export default function SolExamesComplementaresBNF({ resp = {}, notify = false, print = false, viewHeader = false }) {

    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
            {viewHeader && <div className="w-100 mb-20">
                <p className="mb-0"><b>De:</b> {notify?.disparo}</p>
                <p className="mb-0"><b>Para:</b> {notify?.canalBNF}</p>
                <p className="mb-0"><b>Assunto:</b> SOLICITAÇÃO DE EXAMES COMPLEMENTARES</p>
                <p className="mb-0"><b>Data:</b> {notify?.data_disparo}</p>
                <hr />
            </div>}
            <div className="flex">
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {resp.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {resp.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {resp.operadora_telefone}</p>
                </div>
                <img className="image-ops" src={resp?.operadora_imagem} alt='Operadora'></img>
            </div>
            <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">SOLICITAÇÃO DE EXAMES COMPLEMENTARES</p>
            </div>
            <div className="w-100">
                <div className="text-right mb-10">{notify.data_format ? notify.data_format : resp.data_disparo}</div>
            </div>
            <p className="mb-10">
                Olá, <b>{resp.beneficiario_nome}</b>, CPF <b>{resp.beneficiario_cpf}</b>.
            </p>
            <p>Informamos que o profissional desempatador, Dr.(a) <b>{resp.desempatador_responsavel_nome}</b>, CRM (ou CRO): <b>{resp.desempatador_responsavel_crmcro} - {resp.desempatador_responsavel_uf}</b> que decidirá sobre a(s) divergência(s) da solicitação de procedimentos, exames, materiais e/ou medicamentos feita em {resp.data_atendimento}, para a guia <b>{resp.beneficiario_guia}</b>, solicita a realização dos seguintes exames complementares:</p>

            <div className="w-100">
                <p className="text-center mt-30 mb-20 fb-16">SOLICITAÇÃO DE EXAMES COMPLEMENTARES</p>
            </div>
            {resp.materiais ?
                <div className="w-100 grid">
                    <table>
                        <tbody style={{ width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th style={{ width: '15%' }}>Código</th>
                                <th style={{ width: '45%' }}>Descrição</th>
                                <th style={{ width: '13%' }}>Quantidade Solicitada</th>
                                <th style={{ width: '13%' }}>Local</th>
                                <th style={{ width: '13%' }}>Data e hora</th>
                            </tr>
                        </tbody>
                        {resp.exames_complementares.map(exame => {
                            return <tbody key={exame.id}>
                                <tr>
                                    <td>{exame.codigo}</td>
                                    <td>{exame.descricao}</td>
                                    <td>{exame.quantidade}</td>
                                    <td>{exame.local}</td>
                                    <td>{exame.data}{exame.hora}</td>
                                </tr>
                            </tbody>
                        })}
                    </table></div> : ''}
            <br />

            <p>Salientamos que todos os exames solicitados são assegurados pela {resp.operadora_nome}, sem necessidade de autorização prévia. </p>

            <p>Caso você deixe de realizá-lo(s), prevalecerá a indicação clínica feita pelo profissional auditor da operadora, sendo-lhe facultada a possibilidade de reiniciar o procedimento de autorização, solicitando-o novamente.</p>

            <p>Em caso de dúvidas, entre em contato com seu o profissional assistente ou, se preferir, retorne este e-mail ou as envie pelo WhatsApp (48) 99201-4736.</p>

            <br />

            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>{resp.auditor_nome}, CRM (ou CRO): {resp.auditor_crmcro} - {resp.auditor_uf}.</p>
                <p>Dados para contato: {resp.operadora_telefone}</p>
            </div>
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de envio de comunicação ao beneficiário via {notify?.canal}: {notify?.canalBNF} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            <br />
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação pelo beneficiário via {notify?.canal}: {notify?.canalBNF} - {notify?.data_leitura} - IP: {notify?.ip} / {notify?.navegador} / {notify?.sistema_operacional} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}
