import React from 'react';
import "../../assets/styles/pages/Ajuda/Docs.scss";
import img1 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0001.jpg"
import img2 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0002.jpg"
import img3 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0003.jpg"
import img4 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0004.jpg"
import img5 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0005.jpg"
import img6 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0006.jpg"
import img7 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0007.jpg"
import img8 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0008.jpg"
import img9 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0009.jpg"
import img10 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0010.jpg"
import img11 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0011.jpg"
import img12 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0012.jpg"
import img13 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0013.jpg"
import img14 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0014.jpg"
import img15 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0015.jpg"
import img16 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0016.jpg"
import img17 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0017.jpg"
import img18 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0018.jpg"
import img19 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0019.jpg"
import img20 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0020.jpg"
import img21 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0021.jpg"
import img22 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0022.jpg"
import img23 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0023.jpg"
import img24 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0024.jpg"
import img25 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0025.jpg"
import img26 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0026.jpg"
import img27 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0027.jpg"
import img28 from "../../assets/images/faq-docs/operadora-manual-de-utilizacao/operadora-manual-de-utilizacao_page-0028.jpg"

import UnauthorizedPage from '../401';
import config from '../../config/config';

export default function OperadoraManualUtilizacao() {
    document.title = 'säästo';
    return (<div className="page-docs">
        {[2, 3].includes(parseInt(config.user.tipo)) ? <div className="doc-wrapper">
            <img src={img1} alt="Página 1" />
            <img src={img2} alt="Página 2" />
            <img src={img3} alt="Página 3" />
            <img src={img4} alt="Página 4" />
            <img src={img5} alt="Página 5" />
            <img src={img6} alt="Página 6" />
            <img src={img7} alt="Página 7" />
            <img src={img8} alt="Página 8" />
            <img src={img9} alt="Página 9" />
            <img src={img10} alt="Página 10" />
            <img src={img11} alt="Página 11" />
            <img src={img12} alt="Página 12" />
            <img src={img13} alt="Página 13" />
            <img src={img14} alt="Página 14" />
            <img src={img15} alt="Página 15" />
            <img src={img16} alt="Página 16" />
            <img src={img17} alt="Página 17" />
            <img src={img18} alt="Página 18" />
            <img src={img19} alt="Página 19" />
            <img src={img20} alt="Página 20" />
            <img src={img21} alt="Página 21" />
            <img src={img22} alt="Página 22" />
            <img src={img23} alt="Página 23" />
            <img src={img24} alt="Página 24" />
            <img src={img25} alt="Página 25" />
            <img src={img26} alt="Página 26" />
            <img src={img27} alt="Página 27" />
            <img src={img28} alt="Página 28" />
        </div> : <UnauthorizedPage />}
    </div>);
}