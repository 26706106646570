//import Cep from "cep-promise";
import React from 'react';
import Email from '../Email';

export default function ComEscolhaAbstencaoBNF({ resp = {}, notify = false, print = false, viewHeader = false }) {

    return (
        <Email emailClass={`${print ? 'print-email' : 'email'}`}>
            {viewHeader && <div className="w-100 mb-20">
                <p className="mb-0"><b>De:</b> {notify?.disparo}</p>
                <p className="mb-0"><b>Para:</b> {notify?.canalBNF}</p>
                <p className="mb-0"><b>Assunto:</b> COMUNICADO ESCOLHA PROFISSIONAL DESEMPATADOR</p>
                <p className="mb-0"><b>Data:</b> {notify?.data_disparo}</p>
                <hr />
            </div>}
            <div className="flex">
                <div className="w-100 mb-20">
                    <p className="mb-0"><b>Operadora:</b> {resp.operadora_nome}</p>
                    <p className="mb-0"><b>Endereço:</b> {resp.operadora_endereco}</p>
                    <p className="mb-0"><b>Telefone:</b> {resp.operadora_telefone}</p>
                </div>
                <img className="image-ops" src={resp?.operadora_imagem} alt='Operadora'></img>
            </div>
            {/*  <div className="w-100">
                <p className="text-center mt-30 mb-30 fb-18">COMUNICADO ESCOLHA PROFISSIONAL DESEMPATADOR</p>
            </div> */}
            {/*  <div className="w-100">
                <div className="text-right mb-10">{resp.data_disparo}</div>
            </div> */}
            <p className="mb-10">
                Olá, <b>{resp.beneficiario_nome}</b>.
            </p>
            <p>Informamos que já recebemos a manifestação do profissional assistente, Dr.(a) <b>{resp.profissional_nome}</b>, CRM (ou CRO): <b>{resp.profissional_crmcro} - {resp.profissional_uf}</b> acerca do seu processo de autorização e ele segue para as próximas etapas de Junta Médica ou Odontológica.</p>

            <p>Em caso de dúvidas, entre em contato com seu o profissional assistente ou, se preferir, retorne este e-mail ou fale conosco pelo WhatsApp (48) 99201-4736.</p>

            <br />

            <div className="w-100 text-justify bold pb-50">
                <p>Atenciosamente,</p>
                <p>{resp.auditor_nome}, CRM (ou CRO): {resp.auditor_crmcro} - {resp.auditor_uf}.</p>
                <p>Dados para contato: {resp.operadora_telefone}</p>
            </div>
            {notify.data_disparo && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de envio de comunicação ao beneficiário via {notify?.canal}: {notify?.canalBNF} - {notify?.data_disparo} - Cód.{notify?.codigo}</p>
            </div>}
            <br />
            {notify.data_leitura && <div className="w-100 alert-label">
                <p className="text-center pt-10 bold">Registro de leitura de comunicação pelo beneficiário via {notify?.canal}: {notify?.canalBNF} - {notify?.data_leitura} - IP: {notify?.ip} / {notify?.navegador} / {notify?.sistema_operacional} - Cód.{notify?.codigo}</p>
            </div>}
        </Email>
    );
}
