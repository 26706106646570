import React from 'react';
import '../assets/styles/components/Email.scss';

export default function Email({emailClass, children }) {
  
  return (
    
      <div className={`${emailClass}`}>
        {children}
      </div>
    

  );
}
