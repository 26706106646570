import axios from "axios";
import { parseCookies } from "nookies";

const forceQA = false;

export function getAPIClient() {
  const { token } = parseCookies();

  const api = axios.create({
    baseURL:
      window.location.href.match(/\/qas\./i) || forceQA
        ? "https://qas-api.saasto.com.br/"
        : window.location.href.match(/\/localhost[:]/i)
          ? "http://localhost/saasto-api/"
          : "https://api.saasto.com.br/",
    withCredentials: false,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });

  api.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  api.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      return Promise.reject(error);
    }
  );

  if (token) {
    api.defaults.headers["Authorization"] = `Bearer ${token}`;
  }

  api.getToken = () => {
    return parseCookies()?.token || null;
  };

  return api;
}
