import React, { useEffect } from 'react';
import '../../assets/styles/components/Header.scss'
import logo from "../../assets/images/logo-azul.png";
import config from "../../config/config.js";
import Notificacao from "./Notificacao.js";
import Perfil from "./Perfil.js";
import InputForm from '../Form/InputForm';
import { useState } from 'react';
import business from '../../services/business';
//import { useNavigate } from 'react-router-dom';
import tools from '../../lib/tools';

export default function Header({ openMenu }) {

  //const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const header = document.querySelector('header');
    window.addEventListener('scroll', () => {
      if (window.scrollY > 5) {
        header.classList.add('shadow');
      } else {
        header.classList.remove('shadow');
      }
    });
  }, [])

  let user = config.user;

  const search = async (e) => {
    return await business.buscaProcesso(1, 3, e.target.value);
  }

  const setSearch = (item) => {
    if (item && item?.slug_processo_tipo && item?.id) {
      setSearchValue(item.id_protocolo);
      return tools.openURL('/' + item.slug_processo_tipo + '/' + item.id);
    } else {
      setSearchValue('');
    }
  }

  const searchListRender = (item) => {
    return <div className="grid">
      <div className="span-6 fb-16">{item.id_protocolo} - {item.nome_beneficiario}</div>
      <div className="span-6 f400-14 text-left">
        Etapa: <b>{item.desc_processo_etapa}</b> <br /> Status: <b>{item.desc_processo_etapa_status}</b>
      </div>
      <div className="span-6 f400-14 text-left"><span className="marker" />{item.nome}</div>
    </div>
  }

  return (
    <header className="header">
      <img src={logo} className="logo-img pointer" alt="logo" onClick={() => tools.openURL("/")} />
      <div className="flex ai-fc container-options">
        {/*<Notificacao className="mobile" />*/}
        <i className="icon icon-menu-burger blue i-30 pointer" onClick={openMenu} />
        {([1, 4, 5, 7, 8].includes(parseInt(config.user.tipo))) && <div className="search busca-site">
          <span className="group-input">
            <InputForm placeholder="Buscar" id="search-header" name="search-header" searchCodigo="id_protocolo" searchLabel="id_protocolo" searchListRender={searchListRender} exibicao={false} value={searchValue || ''} type="search" onSearch={(e) => {
              if (e?.target?.value) {
                return search(e);
              }
            }} model={(...params) => setSearch(...params)} />
          </span>
        </div>}
      </div>

      <div className="flex gap-35 desktop">
        <Notificacao />
        <Perfil user={user} />
      </div>
    </header>
  );
};

